module.exports=[
    {
    	"name": "Pacific/Midway",
    	"offset": "UTC-11:00",
    	"location": "Midway Island, Samoa"
    },
    {
		"name": "America/Adak",
		"offset": "UTC-10:00",
		"location": "Hawaii-Aleutian"
	},
    {
		"name": "Etc/GMT+10",
		"offset": "UTC-10:00",
		"location": "Hawaii"
	},
    {
		"name": "Pacific/Marquesas",
		"offset": "UTC-09:30",
		"location": "Marquesas Islands"
	},
    {
		"name": "Pacific/Gambier",
		"offset": "UTC-09:00",
		"location": "Gambier Islands"
	},
    {
		"name": "America/Anchorage",
		"offset": "UTC-09:00",
		"location": "Alaska"
	},
    {
		"name": "'America/Ensenada'",
		"offset": "UTC-08:00 -",
		"location": "Tijuana, Baja California"
	},
    {
		"name": "Etc/GMT+8",
		"offset": "UTC-08:00",
		"location": "Pitcairn Islands"
	},
    {
		"name": "America/Los_Angeles",
		"offset": "UTC-08:00",
		"location": "Pacific Time, US & Canada"
	},
    {
		"name": "America/Denver",
		"offset": "UTC-07:00",
		"location": "Mountain Time, US & Canada"
	},
    {
		"name": "America/Chihuahua",
		"offset": "UTC-07:00",
		"location": "Chihuahua, La Paz, Mazatlan"
	},
    {
		"name": "America/Dawson_Creek",
		"offset": "UTC-07:00",
		"location": "Arizona"
	},
    {
		"name": "America/Belize",
		"offset": "UTC-06:00",
		"location": "Saskatchewan, Central America"
	},
    {
		"name": "America/Cancun",
		"offset": "UTC-06:00",
		"location": "Guadalajara, Mexico City, Monterrey"
	},
    {
		"name": "Chile/EasterIsland",
		"offset": "UTC-06:00",
		"location": "Easter Island"
	},
    {
		"name": "America/Chicago",
		"offset": "UTC-06:00",
		"location": "Central Time, US & Canada"
	},
    {
		"name": "America/New_York",
		"offset": "UTC-05:00",
		"location": "Eastern Time, US & Canada"
	},
    {
		"name": "America/Havana",
		"offset": "UTC-05:00",
		"location": "Cuba"
	},
    {
		"name": "America/Bogota",
		"offset": "UTC-05:00",
		"location": "Bogota, Lima, Quito, Rio Branco"
	},
    {
		"name": "America/Caracas",
		"offset": "UTC-04:30",
		"location": "Caracas"
	},
    {
		"name": "America/Santiago",
		"offset": "UTC-04:00",
		"location": "Santiago"
	},
    {
		"name": "America/La_Paz",
		"offset": "UTC-04:00",
		"location": "La Paz"
	},
    {
		"name": "Atlantic/Stanley",
		"offset": "UTC-04:00",
		"location": "Faukland Islands"
	},
    {
		"name": "America/Campo_Grande",
		"offset": "UTC-04:00",
		"location": "Brazil"
	},
    {
		"name": "America/Goose_Bay",
		"offset": "UTC-04:00",
		"location": "Atlantic Time, Goose Bay"
	},
    {
		"name": "America/Glace_Bay",
		"offset": "UTC-04:00",
		"location": "Atlantic Time, Canada"
	},
    {
		"name": "America/St_Johns",
		"offset": "UTC-03:30",
		"location": "Newfoundland"
	},
    {
		"name": "America/Araguaina",
		"offset": "UTC-03:00",
		"location": "UTC-3"
	},
    {
		"name": "America/Montevideo",
		"offset": "UTC-03:00",
		"location": "Montevideo"
	},
    {
		"name": "America/Miquelon",
		"offset": "UTC-03:00",
		"location": "Miquelon, St. Pierre"
	},
    {
		"name": "America/Godthab",
		"offset": "UTC-03:00",
		"location": "Greenland"
	},
    {
		"name": "America/Argentina/Buenos_Aires",
		"offset": "UTC-03:00",
		"location": "Buenos Aires"
	},
    {
		"name": "America/Sao_Paulo",
		"offset": "UTC-03:00",
		"location": "Brasilia"
	},
    {
		"name": "America/Noronha",
		"offset": "UTC-02:00",
		"location": "Mid-Atlantic"
	},
    {
		"name": "Atlantic/Cape_Verde",
		"offset": "UTC-01:00",
		"location": "Cape Verde Is."
	},
    {
		"name": "Atlantic/Azores",
		"offset": "UTC-01:00",
		"location": "Azores"
	},
    {
		"name": "Europe/Belfast",
		"offset": "UTC",
		"location": "Greenwich Mean Time, Belfast"
	},
    {
		"name": "Europe/Dublin",
		"offset": "UTC",
		"location": "Greenwich Mean Time, Dublin"
	},
    {
		"name": "Europe/Lisbon",
		"offset": "UTC",
		"location": "Greenwich Mean Time, Lisbon"
	},
    {
		"name": "Europe/London",
		"offset": "UTC",
		"location": "Greenwich Mean Time, London"
	},
    {
		"name": "Africa/Abidjan",
		"offset": "UTC",
		"location": "Monrovia, Reykjavik"
	},
    {
		"name": "Europe/Amsterdam",
		"offset": "UTC+01:00",
		"location": "Amsterdam, Berlin, Rome, Stockholm, Vienna"
	},
    {
		"name": "Europe/Belgrade",
		"offset": "UTC+01:00",
		"location": "Belgrade, Bratislava, Budapest, Prague"
	},
    {
		"name": "Europe/Brussels",
		"offset": "UTC+01:00",
		"location": "Brussels, Copenhagen, Madrid, Paris"
	},
    {
		"name": "Africa/Algiers",
		"offset": "UTC+01:00",
		"location": "West Central Africa"
	},
    {
		"name": "Africa/Windhoek",
		"offset": "UTC+01:00",
		"location": "Windhoek"
	},
    {
		"name": "Asia/Beirut",
		"offset": "UTC+02:00",
		"location": "Beirut"
	},
    {
		"name": "Africa/Cairo",
		"offset": "UTC+02:00",
		"location": "Cairo"
	},
    {
		"name": "Asia/Gaza",
		"offset": "UTC+02:00",
		"location": "Gaza"
	},
    {
		"name": "Africa/Johannesburg",
		"offset": "UTC+02:00",
		"location": "Harare, Pretoria"
	},
    {
		"name": "Asia/Jerusalem",
		"offset": "UTC+02:00",
		"location": "Jerusalem"
	},
    {
		"name": "Europe/Minsk",
		"offset": "UTC+02:00",
		"location": "Minsk"
	},
    {
		"name": "Asia/Damascus",
		"offset": "UTC+02:00",
		"location": "Syria"
	},
    {
		"name": "Europe/Moscow",
		"offset": "UTC+03:00",
		"location": "Moscow, St. Petersburg, Volgograd"
	},
    {
		"name": "Africa/Addis_Ababa",
		"offset": "UTC+03:00",
		"location": "Nairobi"
	},
    {
		"name": "Asia/Tehran",
		"offset": "UTC+03:30",
		"location": "Tehran"
	},
    {
		"name": "Asia/Dubai",
		"offset": "UTC+04:00",
		"location": "Abu Dhabi, Muscat"
	},
    {
		"name": "Asia/Yerevan",
		"offset": "UTC+04:00",
		"location": "Yerevan"
	},
    {
		"name": "Asia/Kabul",
		"offset": "UTC+04:30",
		"location": "Kabul"
	},
    {
		"name": "Asia/Yekaterinburg",
		"offset": "UTC+05:00",
		"location": "Ekaterinburg"
	},
    {
		"name": "Asia/Tashkent",
		"offset": "UTC+05:00",
		"location": "Tashkent"
	},
    {
		"name": "Asia/Kolkata",
		"offset": "UTC+05:30",
		"location": "Chennai, Kolkata, Mumbai, New Delhi"
	},
    {
		"name": "Asia/Katmandu",
		"offset": "UTC+05:45",
		"location": "Kathmandu"
	},
    {
		"name": "Asia/Dhaka",
		"offset": "UTC+06:00",
		"location": "Astana, Dhaka"
	},
    {
		"name": "Asia/Novosibirsk",
		"offset": "UTC+06:00",
		"location": "Novosibirsk"
	},
    {
		"name": "Asia/Rangoon",
		"offset": "UTC+06:30",
		"location": "Yangon Rangoon"
	},
    {
		"name": "Asia/Bangkok",
		"offset": "UTC+07:00",
		"location": "Bangkok, Hanoi, Jakarta"
	},
    {
		"name": "Asia/Krasnoyarsk",
		"offset": "UTC+07:00",
		"location": "Krasnoyarsk"
	},
    {
		"name": "Asia/Hong_Kong",
		"offset": "UTC+08:00",
		"location": "Beijing, Chongqing, Hong Kong, Urumqi"
	},
    {
		"name": "Asia/Irkutsk",
		"offset": "UTC+08:00",
		"location": "Irkutsk, Ulaan Bataar"
	},
    {
		"name": "Australia/Perth",
		"offset": "UTC+08:00",
		"location": "Perth"
	},
    {
		"name": "Australia/Eucla",
		"offset": "UTC+08:45",
		"location": "Eucla"
	},
    {
		"name": "Asia/Tokyo",
		"offset": "UTC+09:00",
		"location": "Osaka, Sapporo, Tokyo"
	},
    {
		"name": "Asia/Seoul",
		"offset": "UTC+09:00",
		"location": "Seoul"
	},
    {
		"name": "Asia/Yakutsk",
		"offset": "UTC+09:00",
		"location": "Yakutsk"
	},
    {
		"name": "Australia/Adelaide",
		"offset": "UTC+09:30",
		"location": "Adelaide"
	},
    {
		"name": "Australia/Darwin",
		"offset": "UTC+09:30",
		"location": "Darwin"
	},
    {
		"name": "Australia/Brisbane",
		"offset": "UTC+10:00",
		"location": "Brisbane"
	},
    {
		"name": "Australia/Hobart",
		"offset": "UTC+10:00",
		"location": "Hobart"
	},
    {
		"name": "Asia/Vladivostok",
		"offset": "UTC+10:00",
		"location": "Vladivostok"
	},
    {
		"name": "Australia/Lord_Howe",
		"offset": "UTC+10:30",
		"location": "Lord Howe Island"
	},
    {
		"name": "Etc/GMT-11",
		"offset": "UTC+11:00",
		"location": "Solomon Is., New Caledonia"
	},
    {
		"name": "Asia/Magadan",
		"offset": "UTC+11:00",
		"location": "Magadan"
	},
    {
		"name": "Pacific/Norfolk",
		"offset": "UTC+11:30",
		"location": "Norfolk Island"
	},
    {
		"name": "Asia/Anadyr",
		"offset": "UTC+12:00",
		"location": "Anadyr, Kamchatka"
	},
    {
		"name": "Pacific/Auckland",
		"offset": "UTC+12:00",
		"location": "Auckland, Wellington"
	},
    {
		"name": "Etc/GMT-12",
		"offset": "UTC+12:00",
		"location": "Fiji, Kamchatka, Marshall Is."
	},
    {
		"name": "Pacific/Chatham",
		"offset": "UTC+12:45",
		"location": "Chatham Islands"
	},
    {
		"name": "Pacific/Tongatapu",
		"offset": "UTC+13:00",
		"location": "Nuku\"alofa"
	},
    {
		"name": "Pacific/Kiritimati",
		"offset": "UTC+14:00",
		"location": "Kiritimati"
	}
]