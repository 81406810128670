var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var cartTotal_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t";
if(runtime.contextOrFrameLookup(context, frame, "hasItems")) {
output += "\n\t\t<div class=\"amount blue-text\"><strong>Subtotal: ";
output += runtime.suppressValue(((runtime.contextOrFrameLookup(context, frame, "total") > 0)?(runtime.contextOrFrameLookup(context, frame, "symbol") + runtime.contextOrFrameLookup(context, frame, "total")):"Free"), env.opts.autoescape);
output += "</strong></div>\n\n\t\t<label id=\"refundArea\">\n\t\t\t<input type=\"checkbox\" name=\"refund\" class=\"refund\"/> I hereby confirm that I have selected the correct products, as some items may not be eligible for a refund.\n\t\t</label>\n\n\t\t<div id=\"consent\"></div>\n\n\t\t<div class=\"buttons\">\n\t\t\t<button class=\"button small continue\"><i class=\"fa fa-arrow-left fa-fw\"></i> Continue Shopping</button>\n\t\t\t<div class=\"total\">\n\t\t\t\t<button type=\"button\" class=\"button small success\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "invalid")?"disabled":""), env.opts.autoescape);
output += " id=\"checkoutBtn\">";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "invalid")?"Error(s) preventing checkout":"<span class=\"btn-text\">Checkout</span>"), env.opts.autoescape);
output += "</button>\n\t\t\t</div>\n\t\t</div>\n\t";
;
}
else {
output += "\n\t\tNothing added to your cart yet.\n\t";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var cartTotal_oldRoot = cartTotal_obj.root;
cartTotal_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	cartTotal_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var dependentWarning_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div id=\"dependentWarning\">\n\t\t<p>Please note that you are currently purchasing e-books for your own personal use.</p>\n\t\t<p>Do you or your child attend one of the following educational institutions?</p>\n\n\t\t<label>School/Institution</label>\n\t\t<select class=\"chosen schoolSelector\" name=\"schools\" data-placeholder=\"Choose a School/Institution...\">\n\t\t<option></option>\n\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "schools");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("school", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"school_id"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"fullname"), env.opts.autoescape);
output += "</option>\n\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t</select>\n\n\t\t<p><br />If not, or you can't find your institution, please proceed to <span id=\"checkout\">Checkout</span>.</p>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var dependentWarning_oldRoot = dependentWarning_obj.root;
dependentWarning_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	dependentWarning_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"cartTotal": new nunjucks.Template( {obj: cartTotal_obj, type: "code"}, env),	"dependentWarning": new nunjucks.Template( {obj: dependentWarning_obj, type: "code"}, env),};
