var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"container\">\n<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "region"), env.opts.autoescape);
output += "/book/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "product_id"), env.opts.autoescape);
output += "\" data-replace>\n\t\t<img class=\"bookImg bookTooltip\n\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 2)) {
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "resourceType"), env.opts.autoescape);
output += "Img";
;
}
output += "\n\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 3)) {
output += "serviceImg";
;
}
output += "\"\n\t\t\tstyle=\"background-image:url('../images/no_cover.png');\"\n\t\t\ttitle=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "\"\n\t\t\tdata-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "product_id"), env.opts.autoescape);
output += "\"\n\t\t\tdata-title=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "\"\n\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "coverImgMedium"), env.opts.autoescape);
output += "\"\n\t\t\tonerror=\"this.src='../images/no_cover.png'\" />\n\t</a>\n\t<div class=\"info\">\n\t\t<div class=\"titleContainer\">\n\t\t\t<h5 class=\"title bookTooltip\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "\" data-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "product_id"), env.opts.autoescape);
output += "\" data-title=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "</h5>\n\t\t</div>\n\t\t<div class=\"infoContainer\">\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 1)) {
output += "<h6><b>eISBN:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "identifier")), env.opts.autoescape);
output += "</h6>";
;
}
output += "\n\t\t\t<h6><b>Publisher:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "publisherName")), env.opts.autoescape);
output += "</h6>\n\t\t\t";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "grades")),"length") > 1 || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "grades")),0) != "ALL")) {
output += "\n\t\t\t\t<h6><b>Study year:</b> ";
output += runtime.suppressValue(env.getFilter("gradeMap").call(context, runtime.contextOrFrameLookup(context, frame, "grades"),true), env.opts.autoescape);
output += "</h6>\n\t\t\t";
;
}
output += "\n\t\t\t<h6><b>By:</b>\n\t\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "authors") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "authors")),"length") > 0)) {
output += "\n\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "authors"),", ")), env.opts.autoescape);
output += "\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\tN/A\n\t\t\t\t";
;
}
output += "\n\t\t\t</h6>\n\t\t</div>\n\t</div>\n\t<div class=\"cartdetail\">\n\n\t\t<span class=\"price ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discountPrice")?"discounted":""), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discountPrice")?"Was&nbsp;&nbsp;&nbsp;":""), env.opts.autoescape);
output += runtime.suppressValue(((runtime.contextOrFrameLookup(context, frame, "price") > 0)?(runtime.contextOrFrameLookup(context, frame, "symbol") + runtime.contextOrFrameLookup(context, frame, "price")):"Free"), env.opts.autoescape);
output += "</span>\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "discountPrice")) {
output += "\n\t\t\t<span class=\"price ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discountPrice")?"discountedPrice":""), env.opts.autoescape);
output += "\">Now ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "symbol") + runtime.contextOrFrameLookup(context, frame, "discountPrice"), env.opts.autoescape);
output += "</span>\n\t\t";
;
}
output += "\n\n\t\t";
if((lineno = 38, colno = 11, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "owned"), "owned", context, [])) && runtime.contextOrFrameLookup(context, frame, "productTypeId") == 1) {
output += "\n\t\t\t<button class=\"button small success owned\"><span class=\"fa fa-check\"/> <span class=\"btn-text\">Owned</span></button>\n\t\t";
;
}
else {
output += "\n\t\t\t";
if((lineno = 41, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "inCart"), "inCart", context, []))) {
output += "\n\t\t\t\t<button class=\"button small alert remove\"><i class=\"fa fa-times fa-fw\"></i> <span class=\"btn-text\">Remove</span></button>\n\t\t\t";
;
}
else {
output += "\n\t\t\t\t<button class=\"button small primary add\" data-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "product_id"), env.opts.autoescape);
output += "\" data-title=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "\"><i class=\"fa fa-shopping-cart fa-fw\"></i> <span class=\"btn-text\">Add to Cart</span></button>\n\t\t\t";
;
}
output += "\n\t\t";
;
}
output += "\n\n\t\t";
if(!(lineno = 48, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "inCart"), "inCart", context, [])) && !(lineno = 48, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "owned"), "owned", context, [])) && runtime.contextOrFrameLookup(context, frame, "authenticated") && runtime.contextOrFrameLookup(context, frame, "showAddWishList") && (lineno = 48, colno = 92, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isOnlyPerpetual"), "isOnlyPerpetual", context, []))) {
output += "\n\t\t\t<div class=\"book-subactions\">\n\t\t\t\t";
if((lineno = 50, colno = 18, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "inWishList"), "inWishList", context, []))) {
output += "\n\t\t\t\t\t<a class=\"removeWishList\" ";
if((lineno = 51, colno = 50, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isOnlyPerpetual"), "isOnlyPerpetual", context, []))) {
output += " data-license=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"license_id"), env.opts.autoescape);
output += "\" ";
;
}
output += "><i class=\"fa fa-heart fa-fw in\"></i> Remove from wish list</a>\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t<a class=\"addWishList\" ";
if((lineno = 53, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isOnlyPerpetual"), "isOnlyPerpetual", context, []))) {
output += " data-license=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"license_id"), env.opts.autoescape);
output += "\" ";
;
}
output += "><i class=\"fa fa-heart-o fa-fw out\"></i> Add to wish list</a>\n\t\t\t\t";
;
}
output += "\n\t\t\t</div>\n\t\t";
;
}
output += "\n\t</div>\n</div>\n<div class=\"bookflag\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 2)) {
output += "resourceflag";
;
}
output += "\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 3)) {
output += "serviceflag";
;
}
output += "\">\n\t<div class=\"flag\"></div>\n\t<span class=\"text\">\n\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 1)) {
output += runtime.suppressValue(env.getFilter("upper").call(context, env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "format"))), env.opts.autoescape);
;
}
output += "\n\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 2)) {
output += runtime.suppressValue(env.getFilter("upper").call(context, env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "resourceType"))), env.opts.autoescape);
;
}
output += "\n\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 3)) {
output += "ALT";
;
}
output += "\n\t</span>\n</div>\n\n";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "grades");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("item", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t";
if(t_4 == "TE") {
output += "\n\t\t<div class=\"ribbon\">\n\t\t\t<div class=\"txt\">\n\t\t\t\tTeacher's Guide\n\t\t\t</div>\n\t\t</div>\n\t";
;
}
output += "\n";
;
}
}
frame = frame.pop();
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
