var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"heading\">\n\t";
if(runtime.contextOrFrameLookup(context, frame, "fetched")) {
output += "\n\t<h3><b><i class=\"fa fa-shopping-cart fa-fw\"></i> My Cart</b> <span>(";
output += runtime.suppressValue((lineno = 2, colno = 75, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "count"), "count", context, [])), env.opts.autoescape);
output += " item";
output += runtime.suppressValue(((lineno = 2, colno = 95, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "count"), "count", context, [])) == 1?"":"s"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((((lineno = 2, colno = 142, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "total"), "total", context, [])) > 0)?((lineno = 2, colno = 119, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "symbol"), "symbol", context, [])) + (lineno = 2, colno = 129, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "total"), "total", context, []))):"Free"), env.opts.autoescape);
output += ")</span></h3>\n\t";
;
}
else {
output += "\n\t<div><span class=\"spinner-icon spinner-small\"/> Loading cart...</div>\n\t";
;
}
output += "\n</div>\n\n<div class=\"cart-items\"></div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
