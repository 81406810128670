var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var product_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\n<script type=\"application/ld+json\">\n{\n  \"@context\": \"http://schema.org\",\n  \"@type\": \"Book\",\n  \"@id\": \"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "href"), env.opts.autoescape);
output += "\",\n  \"name\" : \"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "\",\n  \"author\": {\n    \"@type\":\"Person\",\n    ";
if((runtime.contextOrFrameLookup(context, frame, "authors") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "authors")),"length") > 0)) {
output += "\n    \t\"name\":\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "authors"),", ")), env.opts.autoescape);
output += "\"\n    ";
;
}
else {
output += "\n\t\t\"name\":\"N/A\"\n\t";
;
}
output += "\n  },\n  \"url\" : \"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "href"), env.opts.autoescape);
output += "\",\n    \"workExample\" : [{\n    \"@type\": \"Book\",\n    \"isbn\": \"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "identifier")), env.opts.autoescape);
output += "\",\n    \"bookEdition\": \"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "version"), env.opts.autoescape);
output += "\",\n    \"bookFormat\": \"http://schema.org/EBook\",\n    \"potentialAction\":{\n    \"@type\":\"ReadAction\",\n    \"target\":\n      {\n        \"@type\":\"EntryPoint\",\n        \"urlTemplate\":\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "href"), env.opts.autoescape);
output += "\",\n        \"actionPlatform\":[\n          \"http://schema.org/DesktopWebPlatform\",\n          \"http://schema.org/IOSPlatform\",\n          \"http://schema.org/AndroidPlatform\"\n        ]\n      },\n      \"expectsAcceptanceOf\":{\n        \"@type\":\"Offer\",\n        \"Price\": ";
if(runtime.contextOrFrameLookup(context, frame, "thePrice")) {
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "thePrice"), env.opts.autoescape);
;
}
else {
output += "0";
;
}
output += ",\n        \"priceCurrency\":\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "symbol"), env.opts.autoescape);
output += "\",\n        \"eligibleRegion\" : {\n          \"@type\":\"Country\",\n          \"name\":\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "region"), env.opts.autoescape);
output += "\"\n        },\n        \"availability\": \"http://schema.org/InStock\"\n      }\n    }\n  }]\n}\n</script>\n\n<button class=\"button small primary backButton\"><i class=\"fa fa-fw fa-arrow-left\" aria-hidden=\"true\"></i> Continue Browsing</button>\n\n<!== E-Books ==>\n";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 1)) {
output += "\n<div class=\"viewBook\">\n\t<div class=\"container\">\n\t<img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "coverImgMedium"), env.opts.autoescape);
output += "\" class=\"bookImg\" title=\"";
output += runtime.suppressValue(env.getFilter("truncate").call(context, env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")),30), env.opts.autoescape);
output += "\" />\n\t</div>\n\t<div class=\"bookflag\">\n\t\t<div class=\"flag\"></div>\n\t\t<span class=\"text\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "format")), env.opts.autoescape);
output += "</span>\n\t</div>\n\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "grades");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("grade", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t";
if((t_4 == "TE")) {
output += "\n\t\t<div class=\"teacher-ribbon\">\n\t\t\t<div class=\"txt\">\n\t\t\t\tTeacher's Guide\n\t\t\t</div>\n\t\t</div>\n\t\t";
;
}
output += "\n\t";
;
}
}
frame = frame.pop();
output += "\n\n\t";
if((runtime.contextOrFrameLookup(context, frame, "content_rating"))) {
output += "\n\t\t";
if((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),"value") != "00") && (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),1) != "0")) {
output += "\n\t\t\t<div class=\"rated-ribbon\">\n\t\t\t\t<div class=\"txt\">\n\t\t\t\t\tRated Content\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\t";
;
}
output += "\n\n\t<!--";
if((runtime.contextOrFrameLookup(context, frame, "discontinued"))) {
output += "\n\t\t<div class=\"discontinued-ribbon\">\n\t\t\t<div class=\"txt\">\n\t\t\t\tDiscontinued\n\t\t\t</div>\n\t\t</div>\n\t";
;
}
output += "-->\n</div>\n";
;
}
output += "\n\n<!== Resources ==>\n";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 2)) {
output += "\n\n\t<div class=\"viewBook viewResource\">\n\t\t<div class=\"container resourceContainer\">\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "resourceTypeId") == 1)) {
output += "\n\t\t\t\t<img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "coverImgMedium"), env.opts.autoescape);
output += "\" class=\"bookImg htmlImg\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "\" />\n\t\t\t";
;
}
output += "\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "resourceTypeId") == 2)) {
output += "\n\t\t\t\t<img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "coverImgMedium"), env.opts.autoescape);
output += "\" class=\"bookImg pdfImg\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "\" />\n\t\t\t";
;
}
output += "\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "resourceTypeId") == 3)) {
output += "\n\t\t\t\t<video width=\"400\" controls>\n\t\t\t\t  <source src=\"../../images/mov_bbb.mp4\" id=\"video_here\"> Your browser does not support HTML5 video.\n\t\t\t\t</video>\n\t\t\t";
;
}
output += "\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "resourceTypeId") == 4)) {
output += "\n\t\t\t\t<img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "coverImgMedium"), env.opts.autoescape);
output += "\" class=\"bookImg audioImg\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "\" />\n\t\t\t\t<audio controls=\"controls\">\n\t\t\t\t\tYour browser does not support the <code>audio</code> element.\n\t\t\t\t\t<source src=\"http://developer.mozilla.org/@api/deki/files/2926/=AudioTest_(1).ogg\" type=\"audio/wav\">\n\t\t\t\t</audio>\n\t\t\t";
;
}
output += "\n\n\t\t</div>\n\t\t<div class=\"bookflag\">\n\t\t\t<div class=\"flag\"></div>\n\t\t\t<span class=\"text\">";
output += runtime.suppressValue(env.getFilter("upper").call(context, env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "resourceType"))), env.opts.autoescape);
output += "</span>\n\t\t</div>\n\n\t\t";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "grades");
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("grade", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t";
if((t_8 == "TE")) {
output += "\n\t\t\t<div class=\"teacher-ribbon\">\n\t\t\t\t<div class=\"txt\">\n\t\t\t\t\tTeacher's Guide\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t";
if((runtime.contextOrFrameLookup(context, frame, "content_rating"))) {
output += "\n\t\t\t";
if((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),"value") != "00") && (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),1) != "0")) {
output += "\n\t\t\t\t<div class=\"rated-ribbon\">\n\t\t\t\t\t<div class=\"txt\">\n\t\t\t\t\t\tRated Content\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t";
;
}
output += "\n\n\t\t<!--";
if((runtime.contextOrFrameLookup(context, frame, "discontinued"))) {
output += "\n\t\t\t<div class=\"discontinued-ribbon\">\n\t\t\t\t<div class=\"txt\">\n\t\t\t\t\tDiscontinued\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t";
;
}
output += "-->\n\t</div>\n\n";
;
}
output += "\n\n<!== Services ==>\n";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 3)) {
output += "\n<div class=\"viewBook viewService\">\n\t<div class=\"container\">\n\t<img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "coverImgMedium"), env.opts.autoescape);
output += "\" class=\"bookImg serviceImg\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "\" />\n\t</div>\n\t<div class=\"bookflag\">\n\t\t<div class=\"flag\"></div>\n\t\t<span class=\"text\">OTHER</span>\n\t</div>\n\n\t";
frame = frame.push();
var t_11 = runtime.contextOrFrameLookup(context, frame, "grades");
if(t_11) {var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("grade", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n\t\t";
if((t_12 == "TE")) {
output += "\n\t\t<div class=\"teacher-ribbon\">\n\t\t\t<div class=\"txt\">\n\t\t\t\tTeacher's Guide\n\t\t\t</div>\n\t\t</div>\n\t\t";
;
}
output += "\n\t";
;
}
}
frame = frame.pop();
output += "\n\n\t";
if((runtime.contextOrFrameLookup(context, frame, "content_rating"))) {
output += "\n\t\t";
if((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),"value") != "00") && (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),1) != "0")) {
output += "\n\t\t\t<div class=\"rated-ribbon\">\n\t\t\t\t<div class=\"txt\">\n\t\t\t\t\tRated Content\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\t";
;
}
output += "\n\n\t<!--";
if((runtime.contextOrFrameLookup(context, frame, "discontinued"))) {
output += "\n\t\t<div class=\"discontinued-ribbon\">\n\t\t\t<div class=\"txt\">\n\t\t\t\tDiscontinued\n\t\t\t</div>\n\t\t</div>\n\t";
;
}
output += "-->\n\n</div>\n";
;
}
output += "\n\n<div class=\"info\">\n\t<div class=\"titleContainer\"><h5 class=\"title bookTooltip\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "</h5></div>\n\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 1)) {
output += "<h6><b>eISBN:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "identifier")), env.opts.autoescape);
output += "</h6>";
;
}
output += "\n\t<h6><b>Publisher:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "publisherName")), env.opts.autoescape);
output += "</h6>\n\t";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "grades")),"length") > 1 || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "grades")),0) != "ALL")) {
output += "\n\t\t<h6><b>Study year:</b> ";
output += runtime.suppressValue(env.getFilter("gradeMap").call(context, runtime.contextOrFrameLookup(context, frame, "grades"),true), env.opts.autoescape);
output += "</h6>\n\t";
;
}
output += "\n\t<h6><b>Genre:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, (lineno = 201, colno = 30, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "subjectMap"), "subjectMap", context, []))), env.opts.autoescape);
output += "</h6>\n\n\t<!-- Content Ratings -->\n\t";
if((runtime.contextOrFrameLookup(context, frame, "content_rating"))) {
output += "\n\t\t";
if((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),"value") != "00") && (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),1) != "0")) {
output += "\n\t\t<p class=\"ratingContainer\">\n\t\t<span class=\"tooltips contentTooltip\" data-tooltip-content=\"#tooltip_content\">\n\t\t\t<svg height=\"52\" width=\"52\" class=\"contentSVG\">\n\t  \t\t\t<polygon points=\"26,4 51,48 1,48\" class=\"contentTriangle\"/>\n\n\t  \t\t\t<text x=\"26\" y=\"40\" class=\"triangleText\"  >\n\t  \t\t\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ratings"), env.opts.autoescape);
output += "\n\t  \t\t\t</text>\n\n\t  \t\t\tSorry, your browser does not support inline SVG.\n\t\t\t</svg>\n\t\t\t</span>\n\t\t\t<br />\n\t\t\t<span class=\"contentWarning\"><span class=\"warningMessage\">Content Warning!</span>\n\n\t\t\t";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),"length") > 3)) {
output += "\n\n\t\t\t <a href=\"#\" class=\"tooltips\" data-tooltip-content=\"#tooltip_content\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),"length") - 3, env.opts.autoescape);
output += " more</a></span>\n\n\t\t\t";
;
}
output += "\n\n\t\t\t<div class=\"tooltip_templates\">\n\t   \t\t\t<span id=\"tooltip_content\">\n\t   \t\t\t\t<ul class=\"tooltipList\">\n\n\t   \t\t\t\t";
var t_13;
t_13 = 0;
frame.set("count", t_13, true);
if(frame.topLevel) {
context.setVariable("count", t_13);
}
if(frame.topLevel) {
context.addExport("count", t_13);
}
output += "\n\n\t  \t\t\t\t";
frame = frame.push();
var t_16 = runtime.contextOrFrameLookup(context, frame, "detail_ratings");
if(t_16) {var t_15 = t_16.length;
for(var t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14];
frame.set("item", t_17);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
output += "\n\n\t  \t\t\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "count") >= 3)) {
output += "\n\t  \t\t\t\t\t\t<li><b>";
output += runtime.suppressValue(runtime.memberLookup((t_17),"symbol"), env.opts.autoescape);
output += " = ";
output += runtime.suppressValue(runtime.memberLookup((t_17),"shortDescription"), env.opts.autoescape);
output += " </b></li>\n\t  \t\t\t\t\t";
;
}
else {
output += "\n\t  \t\t\t\t\t\t<li>";
output += runtime.suppressValue(runtime.memberLookup((t_17),"symbol"), env.opts.autoescape);
output += " = ";
output += runtime.suppressValue(runtime.memberLookup((t_17),"shortDescription"), env.opts.autoescape);
output += " </li>\n\t  \t\t\t\t\t";
;
}
output += "\n\n\t  \t\t\t\t\t";
var t_18;
t_18 = runtime.contextOrFrameLookup(context, frame, "count") + 1;
frame.set("count", t_18, true);
if(frame.topLevel) {
context.setVariable("count", t_18);
}
if(frame.topLevel) {
context.addExport("count", t_18);
}
output += "\n\n\t       \t\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t       \t\t\t</ul>\n\t    \t\t</span>\n\t\t\t</div>\n\n\t\t</p>\n\t\t";
;
}
output += "\n\t";
;
}
output += "\n\t<!-- /Content Ratings -->\n\n\t<!== E-Books ==>\n\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 1)) {
output += "\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "allow_preview")) {
output += "\n\t\t\t<div class=\"preview\"><i class=\"fa fa-search\" aria-hidden=\"true\"></i> Preview</div>\n\t\t";
;
}
output += "\n\t";
;
}
output += "\n\n\t<!== Services ==>\n\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 3)) {
output += "\n\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "servicePreviewLink")),"length") > 0) {
output += "\n\t\t<div style=\"height:20px;\"></div>\n\t\t<a class=\"servciePreview\" href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "servicePreviewLink"), env.opts.autoescape);
output += "\" target=\"_blank\"><i class=\"fa fa-search\" aria-hidden=\"true\"></i> Preview</a>\n\t";
;
}
output += "\n\t";
;
}
output += "\n\n\t<div class=\"share\">\n\t\t<h6><b>Share</b></h6>\n\t\t<span class=\"fbShare fa fa-facebook-square\"></span>\n\t\t<span class=\"twShare fa fa-twitter-square\" aria-hidden=\"true\"></span>\n\t\t<span class=\"pintShare fa fa-pinterest-square\" aria-hidden=\"true\"></span>\n\t\t<span class=\"linkedinShare fa fa-linkedin-square\" aria-hidden=\"true\"></span>\n\t\t<span class=\"whatsappShare fa fa-whatsapp\" aria-hidden=\"true\"></span>\n\t</div>\n</div>\n\n<div class=\"cartwrapper\">\n\t<div class=\"licenses\"></div>\n\n\t<div class=\"cartdetail\"></div>\n</div>\n\n<div class=\"about\">\n\t<h6>About:</h6>\n\t<div id=\"aboutPar\">\n\t\t<a class=\"more hide\" id=\"showMore\">More...</a>\n\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "description"), env.opts.autoescape);
output += "\n\t</div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var product_oldRoot = product_obj.root;
product_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	product_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var price_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<td class=\"addProduct gracePeriodTooltip\" title=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "perpetual")?"Lasts forever, never expires":("Plus " + runtime.contextOrFrameLookup(context, frame, "grace_period_parsed") + " grace period")), env.opts.autoescape);
output += "\">\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "owned")) {
output += "\n\t\t\t<i class=\"fa fa-check fa-fw\"></i>\n\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "inCart")) {
output += "\n\t\t\t<i class=\"fa fa-shopping-cart fa-fw\"></i>\n\t\t";
;
}
else {
output += "\n\t\t\t<i class=\"fa fa-cart-plus fa-fw\"></i>\n\t\t";
;
}
;
}
output += "\n\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
output += "\n\t</td>\n\t<td class=\"addProduct\">\n\t\t<span ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discountPrice")?"class='discounted'":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discountPrice")?"Was ":""), env.opts.autoescape);
output += runtime.suppressValue(((runtime.contextOrFrameLookup(context, frame, "price") > 0)?(runtime.contextOrFrameLookup(context, frame, "symbol") + runtime.contextOrFrameLookup(context, frame, "price")):"Free"), env.opts.autoescape);
output += "</span>\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "discountPrice")) {
output += "<br /><span class=\"currentPrice\">Now ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "symbol") + runtime.contextOrFrameLookup(context, frame, "discountPrice"), env.opts.autoescape);
output += "</span>";
;
}
output += "\n\t</td>\n\n\t<td>\n\t\t";
if(!runtime.contextOrFrameLookup(context, frame, "inCart") && !runtime.contextOrFrameLookup(context, frame, "owned") && runtime.contextOrFrameLookup(context, frame, "authenticated") && runtime.contextOrFrameLookup(context, frame, "showAddWishList")) {
output += "\n\t\t\t<div class=\"book-subactions\">\n\t\t\t\t";
if((lineno = 19, colno = 18, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "inWishList"), "inWishList", context, []))) {
output += "\n\t\t\t\t\t<a class=\"removeWishListLicense\" title=\"Remove from wish list\"><i class=\"fa fa-heart fa-fw in\"></i></a>\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t<a class=\"addWishListLicense\" title=\"Add to wish list\"><i class=\"fa fa-heart-o fa-fw out\"></i></a>\n\t\t\t\t";
;
}
output += "\n\t\t\t</div>\n\t\t";
;
}
output += "\n\t</td>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var price_oldRoot = price_obj.root;
price_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	price_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var cartArea_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"price\">\n\t\t";
if((lineno = 2, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isOnlyPerpetual"), "isOnlyPerpetual", context, []))) {
output += "\n\t\t\t";
var t_1;
t_1 = (lineno = 3, colno = 42, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "getPerpetualPrice"), "getPerpetualPrice", context, []));
frame.set("perpetualPrice", t_1, true);
if(frame.topLevel) {
context.setVariable("perpetualPrice", t_1);
}
if(frame.topLevel) {
context.addExport("perpetualPrice", t_1);
}
output += "\n\t\t\t<span ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"discountPrice")?"class='discounted'":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"discountPrice")?"Was ":""), env.opts.autoescape);
output += runtime.suppressValue(((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"price") > 0)?(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"symbol") + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"price")):"Free"), env.opts.autoescape);
output += "</span>\n\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"discountPrice")) {
output += "<br /><span class=\"currentPrice\">Now ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"symbol") + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"discountPrice"), env.opts.autoescape);
output += "</span>";
;
}
output += "\n\n\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "selectedLicense")) {
output += "\n\t\t\t";
var t_2;
t_2 = (lineno = 8, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "getSelectedLicense"), "getSelectedLicense", context, []));
frame.set("license", t_2, true);
if(frame.topLevel) {
context.setVariable("license", t_2);
}
if(frame.topLevel) {
context.addExport("license", t_2);
}
output += "\n\t\t\t<span ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "license")),"discountPrice")?"class='discounted'":""), env.opts.autoescape);
output += ">\n\t\t\t\t";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "license")),"discountPrice")?"Was ":""), env.opts.autoescape);
output += runtime.suppressValue(((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "license")),"price") > 0)?(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "license")),"symbol") + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "license")),"price")):"Free"), env.opts.autoescape);
output += "\n\t\t\t</span>\n\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "license")),"discountPrice")) {
output += "\n\t\t\t\t<br />\n\t\t\t\t<span class=\"currentPrice\">\n\t\t\t\t\tNow ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "license")),"symbol") + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "license")),"discountPrice"), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n\t\t\t";
;
}
output += "\n\t\t\t<div class='duration gracePeriodTooltip' title=\"Plus ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "grace_period_parsed"), env.opts.autoescape);
output += " grace period\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "duration"), env.opts.autoescape);
output += "</div>\n\t\t";
;
}
;
}
output += "\n\t</div>\n\n\t";
if((lineno = 22, colno = 10, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "owned"), "owned", context, []))) {
output += "\n\t\t<button class=\"button small success owned\"><span class=\"fa fa-check fa-fw\"/> <span class=\"btn-text\">Owned</span></button>\n\t";
;
}
else {
output += "\n\t\t";
if((lineno = 25, colno = 12, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "inCart"), "inCart", context, []))) {
output += "\n\t\t\t<button class=\"button small success checkout\" style=\"font-size:11px\">Proceed to Checkout</button>\n\t\t\t<button class=\"button small alert remove\" ";
if((lineno = 27, colno = 64, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isOnlyPerpetual"), "isOnlyPerpetual", context, []))) {
output += " data-license=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"license_id"), env.opts.autoescape);
output += "\" ";
;
}
output += "><i class=\"fa fa-times fa-fw\"></i> <span class=\"btn-text\">Remove</span></button>\n\t\t";
;
}
else {
output += "\n\t\t\t<button class=\"button small primary add ";
if(!runtime.contextOrFrameLookup(context, frame, "selectedLicense") && !(lineno = 29, colno = 90, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isOnlyPerpetual"), "isOnlyPerpetual", context, []))) {
output += "disabled";
;
}
output += "\" ";
if((lineno = 29, colno = 129, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isOnlyPerpetual"), "isOnlyPerpetual", context, []))) {
output += " data-license=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"license_id"), env.opts.autoescape);
output += "\" ";
;
}
else {
output += " data-toggle=\"license-dropdown\"";
;
}
output += "><i class=\"fa fa-cart-plus fa-fw\"></i> <span class=\"btn-text\">Add to Cart</span></button>\n\t\t";
;
}
output += "\n\t";
;
}
output += "\n\n\t";
if(!(lineno = 33, colno = 15, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "inCart"), "inCart", context, [])) && !(lineno = 33, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "owned"), "owned", context, [])) && runtime.contextOrFrameLookup(context, frame, "authenticated") && runtime.contextOrFrameLookup(context, frame, "showAddWishList") && (lineno = 33, colno = 91, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isOnlyPerpetual"), "isOnlyPerpetual", context, []))) {
output += "\n\t\t<div class=\"book-subactions\">\n\t\t\t";
if((lineno = 35, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "inWishList"), "inWishList", context, []))) {
output += "\n\t\t\t\t<a class=\"removeWishList\" ";
if((lineno = 36, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isOnlyPerpetual"), "isOnlyPerpetual", context, []))) {
output += " data-license=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"license_id"), env.opts.autoescape);
output += "\" ";
;
}
output += "><i class=\"fa fa-heart fa-fw in\"></i> Remove from wish list</a>\n\t\t\t";
;
}
else {
output += "\n\t\t\t\t<a class=\"addWishList\" ";
if((lineno = 38, colno = 46, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isOnlyPerpetual"), "isOnlyPerpetual", context, []))) {
output += " data-license=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "perpetualPrice")),"license_id"), env.opts.autoescape);
output += "\" ";
;
}
output += "><i class=\"fa fa-heart-o fa-fw out\"></i> Add to wish list</a>\n\t\t\t";
;
}
output += "\n\t\t</div>\n\t";
;
}
output += "\n\n\t";
if((runtime.contextOrFrameLookup(context, frame, "content_rating"))) {
output += "\n\t\t";
if((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),"value") != "00") && (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),1) != "0")) {
output += "\n\t\t\t<div class=\"reveal reveal-model\" id=\"ratedModal\" data-reveal data-reset-on-close=\"true\">\n\t\t\t\t<h4 class=\"heading\">Content Warning!</h4>\n\n\t\t\t\t<div class=\"body\">\n\t\t\t\t\t<!-- Content Ratings -->\n\t\t\t\t\t\t<p class=\"ratingContainer\">\n\t\t\t\t\t\t<span class=\"tooltips contentTooltip\" data-tooltip-content=\"#tooltip_content\">\n\t\t\t\t\t\t\t<svg height=\"52\" width=\"52\" class=\"contentSVG\">\n\t\t\t\t\t\t\t\t<polygon points=\"26,4 51,48 1,48\" class=\"contentTriangle\"/>\n\n\t\t\t\t\t\t\t\t<text x=\"26\" y=\"40\" class=\"triangleText\"  >\n\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ratings"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t</text>\n\n\t\t\t\t\t\t\t\tSorry, your browser does not support inline SVG.\n\t\t\t\t\t\t\t</svg>\n\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t<br />\n\t\t\t\t\t\t\t<span class=\"contentWarning\"><span class=\"warningMessage\">Content Warning!</span>\n\t\t\t\t\t\t</p>\n\t\t\t\t\t<!-- /Content Ratings -->\n\t\t\t\t\t<p>\n\t\t\t\t\t\tThis book contains rated content. Please confirm that you are aware of the\n\t\t\t\t\t\trating and would like to proceed with your purchase.\n\t\t\t\t\t</p>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"buttons\">\n\t\t\t\t\t<button class=\"button small secondary\" data-close> Decline</button>\n\t\t\t\t\t<button class=\"button small success commitToCartBtn\" data-close> Confirm</button>\n\t\t\t\t</div>\n\n\t\t\t\t<button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n\t\t\t\t\t<span aria-hidden=\"true\">&times;</span>\n\t\t\t\t</button>\n\t\t\t</div>\n\n\n\t\t\t<div class=\"reveal reveal-model\" id=\"wishlistRatedModal\" data-reveal data-reset-on-close=\"true\">\n\t\t\t\t<h4 class=\"heading\">Content Warning!</h4>\n\n\t\t\t\t<div class=\"body\">\n\t\t\t\t\t<!-- Content Ratings -->\n\t\t\t\t\t\t<p class=\"ratingContainer\">\n\t\t\t\t\t\t<span class=\"tooltips contentTooltip\" data-tooltip-content=\"#tooltip_content\">\n\t\t\t\t\t\t\t<svg height=\"52\" width=\"52\" class=\"contentSVG\">\n\t\t\t\t\t\t\t\t<polygon points=\"26,4 51,48 1,48\" class=\"contentTriangle\"/>\n\n\t\t\t\t\t\t\t\t<text x=\"26\" y=\"40\" class=\"triangleText\"  >\n\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ratings"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t</text>\n\n\t\t\t\t\t\t\t\tSorry, your browser does not support inline SVG.\n\t\t\t\t\t\t\t</svg>\n\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t<br />\n\t\t\t\t\t\t\t<span class=\"contentWarning\"><span class=\"warningMessage\">Content Warning!</span>\n\t\t\t\t\t\t</p>\n\t\t\t\t\t<!-- /Content Ratings -->\n\t\t\t\t\t<p>\n\t\t\t\t\t\tThis book contains rated content. Please confirm that you are aware of the\n\t\t\t\t\t\trating and would like to proceed with adding the product to your Wishlist.\n\t\t\t\t\t</p>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"buttons\">\n\t\t\t\t\t<button class=\"button small secondary\" data-close> Decline</button>\n\t\t\t\t\t<button class=\"button small success commitToWishlistBtn\" data-close> Confirm</button>\n\t\t\t\t</div>\n\n\t\t\t\t<button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n\t\t\t\t\t<span aria-hidden=\"true\">&times;</span>\n\t\t\t\t</button>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\t";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var cartArea_oldRoot = cartArea_obj.root;
cartArea_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	cartArea_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"product": new nunjucks.Template( {obj: product_obj, type: "code"}, env),	"price": new nunjucks.Template( {obj: price_obj, type: "code"}, env),	"cartArea": new nunjucks.Template( {obj: cartArea_obj, type: "code"}, env),};
