module.exports=[
	{
		"id": "+",
		"label": "SORT BY"		
	},
	{
		"id": "P.name+",
		"label": "SORT BY NAME (A TO Z)"		
	},
	{
		"id": "P.name-",
		"label": "SORT BY NAME (Z TO A)"		
	},
	{
		"id": "price+",
		"label": "SORT BY PRICE (LOW TO HIGH)"		
	},
	{
		"id": "price-",
		"label": "SORT BY PRICE (HIGH TO LOW)"		
	},
	{
		"id": "P.created-",
		"label": "SORT BY DATE ADDED (NEWEST FIRST)"		
	},
	{
		"id": "P.created+",
		"label": "SORT BY DATE ADDED (OLDEST FIRST)"		
	}
]