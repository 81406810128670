var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dependents")),"length") > 0) {
output += "\n\t";
var t_1;
t_1 = 1;
frame.set("count", t_1, true);
if(frame.topLevel) {
context.setVariable("count", t_1);
}
if(frame.topLevel) {
context.addExport("count", t_1);
}
output += "\n\t";
frame = frame.push();
var t_4 = runtime.contextOrFrameLookup(context, frame, "dependents");
if(t_4) {var t_3 = t_4.length;
for(var t_2=0; t_2 < t_4.length; t_2++) {
var t_5 = t_4[t_2];
frame.set("dependent", t_5);
frame.set("loop.index", t_2 + 1);
frame.set("loop.index0", t_2);
frame.set("loop.revindex", t_3 - t_2);
frame.set("loop.revindex0", t_3 - t_2 - 1);
frame.set("loop.first", t_2 === 0);
frame.set("loop.last", t_2 === t_3 - 1);
frame.set("loop.length", t_3);
output += "\n\t\t";
var t_6;
t_6 = runtime.contextOrFrameLookup(context, frame, "count") + 1;
frame.set("count", t_6, true);
if(frame.topLevel) {
context.setVariable("count", t_6);
}
if(frame.topLevel) {
context.addExport("count", t_6);
}
output += "\n\n\t\t";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dependents")),"length") == 1)) {
output += "\n\t\t\t<div class=\"depBlock\">\n\t\t";
;
}
else {
output += "\n\t\t\t<div class=\"depBlock\">\n\t\t";
;
}
output += "\n\t\t\t<div class=\"depHead\">\n\t\t\t\t<div class=\"circle user-";
output += runtime.suppressValue(runtime.memberLookup((t_5),"colour"), env.opts.autoescape);
output += "\"><div class=\"inner\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, (lineno = 11, colno = 94, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((t_5),"firstname")),"substr"), "dependent[\"firstname\"][\"substr\"]", context, [0,1]))), env.opts.autoescape);
output += runtime.suppressValue(env.getFilter("escape").call(context, (lineno = 11, colno = 134, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((t_5),"surname")),"substr"), "dependent[\"surname\"][\"substr\"]", context, [0,1]))), env.opts.autoescape);
output += "</div></div>\n\t\t\t\t<div class=\"fa fa-refresh refresh\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_5),"user_id"), env.opts.autoescape);
output += "\" title=\"Refresh user\"></div>\n\t\t\t</div>\n\t\t\t<p class=\"depName\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"firstname")), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"surname")), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"firstname")), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"surname")), env.opts.autoescape);
output += "</p>\n\t\t\t<p class=\"depInfo\" ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"school")), env.opts.autoescape);
output += "\">School/Institution:</p>\n\t\t\t<p class=\"depInfo\" title=\"School/Institution:\" ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"school")), env.opts.autoescape);
output += "\"><i>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"school")), env.opts.autoescape);
output += "</i></p>\n\n\t\t\t<p style=\"margin-bottom: 5px\"></p>\n\n\t\t\t<p class=\"depInfo\" title=\"miEbooks Username: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"schoolUsername")), env.opts.autoescape);
output += "\">miEbooks Username:</p>\n\t\t\t<p class=\"depInfo\" title=\"School username: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"schoolUsername")), env.opts.autoescape);
output += "\"><i>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"schoolUsername")), env.opts.autoescape);
output += "</i></p>\n\n\t\t\t<p class=\"depInfo hide\" title=\"Email: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"email")), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"email")), env.opts.autoescape);
output += "</p>\n\n\t\t\t<div class=\"buttons\">\n\t\t\t\t<button type=\"button\" class=\"button unlink-user alert small\" data-userId=\"";
output += runtime.suppressValue(runtime.memberLookup((t_5),"user_id"), env.opts.autoescape);
output += "\"><i class=\"fa fa-times fa-fw\"></i> <span class=\"btn-text\" style=\"width:70px\">Un-Link</span></button>\n\n\t\t\t\t<button type=\"button\" class=\"button buy-user small success\" data-userId=\"";
output += runtime.suppressValue(runtime.memberLookup((t_5),"user_id"), env.opts.autoescape);
output += "\">Start Buying</button>\n\t\t\t</div>\n\n\t\t</div>\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "count") == 10) {
output += "\n\t\t\t";
var t_7;
t_7 = 1;
frame.set("count", t_7, true);
if(frame.topLevel) {
context.setVariable("count", t_7);
}
if(frame.topLevel) {
context.addExport("count", t_7);
}
output += "\n\t\t";
;
}
output += "\n\t";
;
}
}
frame = frame.pop();
output += "\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
