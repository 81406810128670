module.exports={
	"publishers": {
		"name": "Publisher Users",
		"grant": "publishers"
	},
	"publisherAdmins": {
		"name": "Publisher Admins",
		"grant": "publisherAdmins"
	},
	"publisherGroups": {
		"name": "Publisher Groups",
		"grant": "publisherGroups"
	},
	"publisherGroupAdmins": {
		"name": "Publisher Group Admins",
		"grant": "publisherGroupAdmins"
	},
	"schools": {
		"name": "School Users",
		"grant": "schools"
	},
	"schoolAdmins": {
		"name": "School Admins",
		"grant": "schoolAdmins"
	},
	"schoolGroups": {
		"name": "School Groups",
		"grant": "schoolGroups"
	},
	"schoolGroupAdmins": {
		"name": "School Group Admins",
		"grant": "schoolGroupAdmins"
	}
}