module.exports={
	"*": {
		"name": "Default Role",
		"permissions": [
			"api.alchemies.remote.curro.auth.*",
			"api.features.config.get",
			"api.login.*",
			"api.users.get",
			"api.products.get",
			"api.products.products.get",
			"api.products.total.get",
			"api.products._.cover.get",
			"api.products.purchaseRequestProducts.get",
			"api.publishers.get",
			"api.dependents.get",
			"api.dependents.delete",
			"api.dependents.put",
			"api.dependents._.logo.get",
			"api.dependents.post",
			"api.schools.get",
			"api.schools.general.get",
			"api.schools.school.logo.get",
			"api.schools.fCProductListSchools.get",
			"api.cart.get",
			"api.cart.delete",
			"api.cart.post",
			"api.cart.bulkAdd.post",
			"api.cart.checkout.get",
			"api.cart.vouchers.get",
			"api.cart.move.post",
			"api.cart.clear.post",
			"api.cart.changeBuyForUser.post",
			"api.transactions.notify.payfast.post",
			"api.transactions.notify.paypal.get",
			"api.purchases.activatePurchase.post",
			"api.purchases.cancelPayment.post",
			"api.purchases.confirmPayment.post",
			"api.purchases.vouchers.post",
			"api.purchases.vouchers.delete",
			"api.purchases.purchasehistory.get",
			"api.purchases.delete",
			"api.purchases.paymentInstructions.get",
			"api.purchases.setStatus.put",
			"api.purchases.status.get",
			"api.purchases.getPaymentMethodUrl.get",
			"api.tokens.revoke.put",
			"api.tokens.revoke.bulk.put",
			"api.tokens.post",
			"api.tokens.transfer.put",
			"api.tokens.transfer.bulk.put",
			"api.tokens.revokeMulti.put",
			"api.tokens.info.get",
			"api.users.exists.username.get",
			"api.users.exists.email.get",
			"api.users._.get",
			"api.users.post",
			"api.users.put",
			"api.users.verify.account.get",
			"api.users._.resetPassword.*",
			"api.users._.unpersonate.get",
			"api.users.hideDependentLanding.get",
			"api.users.user.updateOffers.get",
			"api.users.canUserOwn.product.get",
			"api.users.consent.*",
			"api.filteredPublishers.get",
			"api.subjects.get",
			"api.filteredSubjects.get",
			"api.categories.bestSellingEbooks.get",
			"api.bookLists.user.get",
			"api.bookLists.cart.post",
			"api.bookLists.school.get",
			"api.bookLists.global.get",
			"api.email.*",
			"api.services.servicesAvailable.get",
			"api.wishList.get",
			"api.wishList.post",
			"api.wishList.delete",
			"api.wishList.*",
			"api.wallet.total.userId.get",
			"api.wallet.transactions.get",
			"api.wallet.transactions.currency.get",
			"api.wallet.types.get",
			"api.customStores.get",
			"api.customStores.adverts.get",
			"api.products.ownesProduct.get",
			"api.subjectCategories.*",
			"api.users.getUserGeoLocation.*",
			"api.collections.*"
		]
	},

	"admin": {
		"name": "Superuser",
		"permissions": [
			"api.features.*",
			"api.users._.impersonate.get",
			"api.purchases.lists.purchases.get",
			"api.purchases.lists.purchaseshelpdesk.get",
			"api.purchases.lists.purchasescategory.get",
			"api.purchases.pending.get",
			"api.reports.purchases.get",
			"api.reports.purchases.helpdesk.get",
			"api.reports.purchases.category.get",
			"api.purchases.updateStatus._.post",
			"api.impersonateUsers.users.get",
			"api.wishList.*",

			"grants.all.*",
			"acacia.logs.*",
			"acacia.data.*",
			"impersonate",
			"payments.tokens.revoke",
			"payments.tokens.transfer",
			"payments.tokens.changeStatus",
			"payments.forceActivate",
			"payments.setPaymentStatus",
			"payments.confirm",
			"payments.tokens.info",
			"users.passwordChangeOverride",
			"reports.purchases",
			"tokens.create",
			"invoice.bankdetails",
			"dependents.addCurro"
		]
	},

	"itsiSuper": {
		"name": "ITSI Manager",
		"permissions": [
			"api.logs.get",
			"api.users._.impersonate.get",
			"api.purchases.lists.purchases.get",
			"api.purchases.lists.purchaseshelpdesk.get",
			"api.purchases.lists.purchasescategory.get",
			"api.purchases.pending.get",
			"api.reports.purchases.get",
			"api.reports.purchases.helpdesk.get",
			"api.reports.purchases.category.get",
			"api.purchases.updateStatus._.post",
			"api.tokens.transfer.put",
			"api.impersonateUsers.users.get",

			"grants.all.*",
			"acacia.data.*",
			"impersonate",
			"payments.tokens.transfer",
			"payments.tokens.info",
			"users.passwordChangeOverride",
			"reports.purchases",
			"dependents.addCurro"
		]
	},

	"support": {
		"name": "ITSI Support",
		"permissions": [
			"api.purchases.lists.purchases.get",
			"api.purchases.lists.purchaseshelpdesk.get",
			"api.purchases.lists.purchasescategory.get",
			"api.reports.purchases.get",
			"api.reports.purchases.helpdesk.get",
			"api.reports.purchases.category.get",
			"api.users._.impersonate.get",
			"api.impersonateUsers.users.get",

			"grants.all.*",
			"impersonate",
			"users.passwordChangeOverride",
			"reports.purchases",
			"dependents.addCurro"
		]
	},

	"schoolAdmin": {
		"name": "School Admin",
		"permissions": [
			"api.reports.purchases.get",
			"api.reports.purchases.helpdesk.get",
			"api.reports.purchases.category.get",
			"api.purchases.lists.purchases.get",
			"api.purchases.lists.purchaseshelpdesk.get",
			"api.purchases.lists.purchasescategory.get",

			"reports.purchases",
			"dependents.addCurro"
		]
	},

	"helpdesk": {
		"name": "ITSI Helpdesk",
		"permissions": [
			"api.purchases.pending.get",
			"api.reports.purchases.get",
			"api.reports.purchases.helpdesk.get",
			"api.reports.purchases.category.get",
			"api.purchases.lists.purchases.get",
			"api.purchases.lists.purchaseshelpdesk.get",
			"api.purchases.lists.purchasescategory.get",
			"api.purchases.updateStatus._.post",
			"api.users._.impersonate.get",
			"api.impersonateUsers.users.get",

			"grants.all.*",
			"impersonate",
			"payments.tokens.transfer",
			"payments.tokens.info",
			"reports.purchases",
			"users.passwordChangeOverride",
			"invoice.bankdetails"
		]
	},

	"financeDepartment": {
		"name": "Helpdesk Finance",
		"permissions": [
			"api.purchases.pending.get",
			"api.reports.purchases.get",
			"api.reports.purchases.helpdesk.get",
			"api.reports.purchases.category.get",
			"api.purchases.lists.purchases.get",
			"api.purchases.lists.purchaseshelpdesk.get",
			"api.purchases.lists.purchasescategory.get",
			"api.purchases.updateStatus._.post",
			"api.users._.impersonate.get",
			"api.impersonateUsers.users.get",

			"impersonate",
			"reports.purchases"
		]
	},

	"billingFinance": {
		"name": "Billing Finance",
		"permissions": [
			"api.reports.*",
			"api.purchases.lists.*",

			"reports.purchases"
		]
	},

	"confirmer": {
		"name": "Payment Confirmer",
		"permissions": [
			"api.users._.impersonate.get",

			"impersonate",
			"payments.confirm"
		]
	},

	"revoker": {
		"name": "Token Revoker",
		"permissions": [
			"payments.tokens.revoke"
		]
	},

	"forceActivator": {
		"name": "Force Activator",
		"permissions": [
			"api.users._.impersonate.get",
			"api.impersonateUsers.users.get",

			"impersonate",
			"payments.forceActivate"
		]
	}
}