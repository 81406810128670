var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"book\">\n\t<div class=\"container\">\n\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "region"), env.opts.autoescape);
output += "/book/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "product_id"), env.opts.autoescape);
output += "\" data-replace>\n\t\t\t<img class=\"bookImg\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 2)) {
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "resourceType"), env.opts.autoescape);
output += "Img";
;
}
output += "\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 3)) {
output += "serviceImg";
;
}
output += "\"\n\t\t\tsrc=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "coverImgMedium"), env.opts.autoescape);
output += "\"\n\t\t\t/>\n\t\t</a>\n\n\t\t<div class=\"info\">\n\t\t\t<div class=\"titleContainer\"><h5 class=\"title bookTooltip\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "</h5></div>\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 1)) {
output += "<h6><b>eISBN:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "identifier")), env.opts.autoescape);
output += "</h6>";
;
}
output += "\n\t\t\t<h6><b>Publisher:</b> <span class=\"publisher link\" data-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "publisherId"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "publisherName")), env.opts.autoescape);
output += "</span></h6>\n\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "showGrades")) {
output += "\n\t\t\t\t<h6><b>Study year:</b> ";
output += runtime.suppressValue(env.getFilter("gradeMap").call(context, runtime.contextOrFrameLookup(context, frame, "grades"),true), env.opts.autoescape);
output += "</h6>\n\t\t\t";
;
}
output += "\n\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "showSubject")) {
output += "\n\t\t\t\t<h6><b>Genre:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "subject")), env.opts.autoescape);
output += "</h6>\n\t\t\t";
;
}
output += "\n\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") != 3)) {
output += "\n\t\t\t\t<h6><b>By:</b>\n\t\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "authors") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "authors")),"length") > 0)) {
output += "\n\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "authors"),", ")), env.opts.autoescape);
output += "\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\tN/A\n\t\t\t\t";
;
}
output += "\n\t\t\t\t</h6>\n\t\t\t";
;
}
output += "\n\t\t</div>\n\t\t<div class=\"cartdetail\">\n\t\t\t<label class=\"price-wrapper\">\n\t\t\t\t";
if(!runtime.contextOrFrameLookup(context, frame, "perpetual")) {
output += "<span class=\"duration gracePeriodTooltip\" title=\"Plus ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "formatted_grace_period"), env.opts.autoescape);
output += " grace period\">(";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "formatted_duration"), env.opts.autoescape);
output += ")</span>";
;
}
output += "\n\t\t\t\t<div>\n\t\t\t\t\t<span class=\"price ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discountPrice")?"discounted":""), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discountPrice")?"Was&nbsp;&nbsp;&nbsp;":""), env.opts.autoescape);
output += runtime.suppressValue(((runtime.contextOrFrameLookup(context, frame, "price") > 0)?(runtime.contextOrFrameLookup(context, frame, "symbol") + runtime.contextOrFrameLookup(context, frame, "price")):"Free"), env.opts.autoescape);
output += "</span><br/>\n\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "discountPrice")) {
output += "\n\t\t\t\t\t\t<span class=\"price ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discountPrice")?"discountedPrice":""), env.opts.autoescape);
output += "\">Now ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "symbol") + runtime.contextOrFrameLookup(context, frame, "discountPrice"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t</div>\n\t\t\t</label>\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "authenticated") && runtime.contextOrFrameLookup(context, frame, "owned") && !runtime.contextOrFrameLookup(context, frame, "inCart")) {
output += "\n\t\t\t\t<button class=\"button small success owned\"><span class=\"fa fa-check fa-fw\"/> <span class=\"btn-text\">Owned</span></button>\n\t\t\t";
;
}
else {
output += "\n\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "inCart")) {
output += "\n\t\t\t\t\t<button class=\"button small alert remove\"><i class=\"fa fa-times fa-fw\"></i> <span class=\"btn-text\">Remove</span></button>\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t<button class=\"button small primary add\"><i class=\"fa fa-shopping-cart fa-fw\"></i> <span class=\"btn-text\">Add to Cart</span></button>\n\t\t\t\t";
;
}
output += "\n\t\t\t";
;
}
output += "\n\t\t</div>\n\t\t";
if(!runtime.contextOrFrameLookup(context, frame, "inCart") && !runtime.contextOrFrameLookup(context, frame, "owned") && runtime.contextOrFrameLookup(context, frame, "authenticated") && runtime.contextOrFrameLookup(context, frame, "showAddWishList")) {
output += "\n\t\t\t<div class=\"book-subactions\">\n\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "inWishList")) {
output += "\n\t\t\t\t\t<a class=\"removeWishList\"><i class=\"fa fa-heart fa-fw in\"></i> Remove from wish list</a>\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t<a class=\"addWishList\"><i class=\"fa fa-heart-o fa-fw out\"></i> Add to wish list</a>\n\t\t\t\t";
;
}
output += "\n\t\t\t</div>\n\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "authenticated") && runtime.contextOrFrameLookup(context, frame, "owned") && !runtime.contextOrFrameLookup(context, frame, "inCart")) {
output += "\n\t\t\t<div class=\"book-subactions\">\n\t\t\t\t<a class=\"addGift\"><i class=\"fa fa-gift fa-fw\"></i> Buy as gift</a>\n\t\t\t</div>\n\t\t";
;
}
;
}
output += "\n\n\t</div>\n\t<div class=\"bookflag\n\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 2)) {
output += "resourceflag";
;
}
output += "\n\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 3)) {
output += "serviceflag";
;
}
output += "\">\n\t\t<div class=\"flag\"></div>\n\n\t\t<span class=\"text\">\n\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 1)) {
output += runtime.suppressValue(env.getFilter("upper").call(context, env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "format"))), env.opts.autoescape);
;
}
output += "\n\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 2)) {
output += runtime.suppressValue(env.getFilter("upper").call(context, env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "resourceType"))), env.opts.autoescape);
;
}
output += "\n\t\t";
if((runtime.contextOrFrameLookup(context, frame, "productTypeId") == 3)) {
output += "OTHER";
;
}
output += "\n\t\t</span>\n\t</div>\n\n\n\n\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "grades");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("item", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t";
if(t_4 == "TE") {
output += "\n\t\t\t<div class=\"teacher-ribbon\">\n\t\t\t\t<div class=\"txt\">\n\t\t\t\t\tTeacher's Guide\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\t";
;
}
}
frame = frame.pop();
output += "\n\n\t";
if((runtime.contextOrFrameLookup(context, frame, "content_rating"))) {
output += "\n\t\t";
if((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),"value") != "00") && (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),1) != "0")) {
output += "\n\t\t\t<div class=\"rated-ribbon\">\n\t\t\t\t<div class=\"txt\">\n\t\t\t\t\tRated Content\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\t";
;
}
output += "\n\n\t<!--";
if((runtime.contextOrFrameLookup(context, frame, "discontinued"))) {
output += "\n\t\t<div class=\"discontinued-ribbon\">\n\t\t\t\t<div class=\"txt\">\n\t\t\t\t\tDiscontinued\n\t\t\t\t</div>\n\t\t\t</div>\n\t";
;
}
output += "-->\n\n</div>\n\n";
if((runtime.contextOrFrameLookup(context, frame, "content_rating"))) {
output += "\n\t";
if((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),"value") != "00") && (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "content_rating")),0)),1) != "0")) {
output += "\n\t\t<div class=\"reveal reveal-model\" id=\"ratedModal\" data-reveal>\n\t\t\t<h4 class=\"heading\">Content Warning!</h4>\n\n\t\t\t<div class=\"body\">\n\t\t\t\t<!-- Content Ratings -->\n\t\t\t\t\t<p class=\"ratingContainer\">\n\t\t\t\t\t<span class=\"tooltips contentTooltip tooltips";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "product_id"), env.opts.autoescape);
output += "\" data-tooltip-content=\"#tooltip_content\">\n\t\t\t\t\t\t<svg height=\"52\" width=\"52\" class=\"contentSVG\">\n\t\t\t\t  \t\t\t<polygon points=\"26,4 51,48 1,48\" class=\"contentTriangle\"/>\n\n\t\t\t\t  \t\t\t<text x=\"26\" y=\"40\" class=\"triangleText\"  >\n\t\t\t\t  \t\t\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ratings"), env.opts.autoescape);
output += "\n\t\t\t\t  \t\t\t</text>\n\n\t\t\t\t  \t\t\tSorry, your browser does not support inline SVG.\n\t\t\t\t\t\t</svg>\n\t\t\t\t\t\t</span>\n\t\t\t\t\t\t<br />\n\t\t\t\t\t\t<span class=\"contentWarning\"><span class=\"warningMessage\">Content Warning!</span>\n\t\t\t\t\t</p>\n\n\t\t\t\t\t<div class=\"tooltip_templates\">\n\t\t\t   \t\t\t<span id=\"tooltip_content";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "product_id"), env.opts.autoescape);
output += "\">\n\t\t\t   \t\t\t\t<ul class=\"tooltipList\">\n\n\t\t\t   \t\t\t\t";
var t_5;
t_5 = 0;
frame.set("count", t_5, true);
if(frame.topLevel) {
context.setVariable("count", t_5);
}
if(frame.topLevel) {
context.addExport("count", t_5);
}
output += "\n\n\t\t\t  \t\t\t\t";
frame = frame.push();
var t_8 = runtime.contextOrFrameLookup(context, frame, "content_rating");
if(t_8) {var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("item", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\n\n\t\t\t  \t\t\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "count") >= 3)) {
output += "\n\t\t\t  \t\t\t\t\t\t<li><b>";
output += runtime.suppressValue(runtime.memberLookup((t_9),"symbol"), env.opts.autoescape);
output += " = ";
output += runtime.suppressValue(runtime.memberLookup((t_9),"shortDescription"), env.opts.autoescape);
output += " </b></li>\n\t\t\t  \t\t\t\t\t";
;
}
else {
output += "\n\t\t\t  \t\t\t\t\t\t<li>";
output += runtime.suppressValue(runtime.memberLookup((t_9),"symbol"), env.opts.autoescape);
output += " = ";
output += runtime.suppressValue(runtime.memberLookup((t_9),"shortDescription"), env.opts.autoescape);
output += " </li>\n\t\t\t  \t\t\t\t\t";
;
}
output += "\n\n\t\t\t  \t\t\t\t\t";
var t_10;
t_10 = runtime.contextOrFrameLookup(context, frame, "count") + 1;
frame.set("count", t_10, true);
if(frame.topLevel) {
context.setVariable("count", t_10);
}
if(frame.topLevel) {
context.addExport("count", t_10);
}
output += "\n\n\t\t\t       \t\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t\t       \t\t\t</ul>\n\t\t\t    \t\t</span>\n\t\t\t\t\t</div>\n\t\t\t\t<!-- /Content Ratings -->\n\t\t\t\t<p>\n\t\t\t\t\tThis book contains rated content. Please confirm that you are aware of the\n\t\t\t\t\trating and would like to proceed with your purchase.\n\t\t\t\t</p>\n\t\t\t</div>\n\n\t\t\t<div class=\"buttons\">\n\t\t\t\t<button class=\"button small secondary\" data-close> Decline</button>\n\t\t\t\t<button class=\"button small success commitToCartBtn";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "license_id"), env.opts.autoescape);
output += "\" data-close> Confirm</button>\n\t\t\t</div>\n\n\n\n\t\t\t<button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n\t\t    \t<span aria-hidden=\"true\">&times;</span>\n\t\t\t</button>\n\t\t</div>\n\n\t\t<div class=\"reveal reveal-model\" id=\"wishlistRatedModal\" data-reveal>\n\t\t\t<h4 class=\"heading\">Content Warning!</h4>\n\n\t\t\t<div class=\"body\">\n\t\t\t\t<!-- Content Ratings -->\n\t\t\t\t\t<p class=\"ratingContainer\">\n\t\t\t\t\t<span class=\"tooltips contentTooltip tooltips";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "product_id"), env.opts.autoescape);
output += "\" data-tooltip-content=\"#tooltip_content\">\n\t\t\t\t\t\t<svg height=\"52\" width=\"52\" class=\"contentSVG\">\n\t\t\t\t  \t\t\t<polygon points=\"26,4 51,48 1,48\" class=\"contentTriangle\"/>\n\n\t\t\t\t  \t\t\t<text x=\"26\" y=\"40\" class=\"triangleText\"  >\n\t\t\t\t  \t\t\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ratings"), env.opts.autoescape);
output += "\n\t\t\t\t  \t\t\t</text>\n\n\t\t\t\t  \t\t\tSorry, your browser does not support inline SVG.\n\t\t\t\t\t\t</svg>\n\t\t\t\t\t\t</span>\n\t\t\t\t\t\t<br />\n\t\t\t\t\t\t<span class=\"contentWarning\"><span class=\"warningMessage\">Content Warning!</span>\n\t\t\t\t\t</p>\n\n\t\t\t\t\t<div class=\"tooltip_templates\">\n\t\t\t   \t\t\t<span id=\"tooltip_content";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "product_id"), env.opts.autoescape);
output += "\">\n\t\t\t   \t\t\t\t<ul class=\"tooltipList\">\n\n\t\t\t   \t\t\t\t";
var t_11;
t_11 = 0;
frame.set("count", t_11, true);
if(frame.topLevel) {
context.setVariable("count", t_11);
}
if(frame.topLevel) {
context.addExport("count", t_11);
}
output += "\n\n\t\t\t  \t\t\t\t";
frame = frame.push();
var t_14 = runtime.contextOrFrameLookup(context, frame, "content_rating");
if(t_14) {var t_13 = t_14.length;
for(var t_12=0; t_12 < t_14.length; t_12++) {
var t_15 = t_14[t_12];
frame.set("item", t_15);
frame.set("loop.index", t_12 + 1);
frame.set("loop.index0", t_12);
frame.set("loop.revindex", t_13 - t_12);
frame.set("loop.revindex0", t_13 - t_12 - 1);
frame.set("loop.first", t_12 === 0);
frame.set("loop.last", t_12 === t_13 - 1);
frame.set("loop.length", t_13);
output += "\n\n\t\t\t  \t\t\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "count") >= 3)) {
output += "\n\t\t\t  \t\t\t\t\t\t<li><b>";
output += runtime.suppressValue(runtime.memberLookup((t_15),"symbol"), env.opts.autoescape);
output += " = ";
output += runtime.suppressValue(runtime.memberLookup((t_15),"shortDescription"), env.opts.autoescape);
output += " </b></li>\n\t\t\t  \t\t\t\t\t";
;
}
else {
output += "\n\t\t\t  \t\t\t\t\t\t<li>";
output += runtime.suppressValue(runtime.memberLookup((t_15),"symbol"), env.opts.autoescape);
output += " = ";
output += runtime.suppressValue(runtime.memberLookup((t_15),"shortDescription"), env.opts.autoescape);
output += " </li>\n\t\t\t  \t\t\t\t\t";
;
}
output += "\n\n\t\t\t  \t\t\t\t\t";
var t_16;
t_16 = runtime.contextOrFrameLookup(context, frame, "count") + 1;
frame.set("count", t_16, true);
if(frame.topLevel) {
context.setVariable("count", t_16);
}
if(frame.topLevel) {
context.addExport("count", t_16);
}
output += "\n\n\t\t\t       \t\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t\t       \t\t\t</ul>\n\t\t\t    \t\t</span>\n\t\t\t\t\t</div>\n\t\t\t\t<!-- /Content Ratings -->\n\t\t\t\t<p>\n\t\t\t\t\tThis book contains rated content. Please confirm that you are aware of the\n\t\t\t\t\trating and would like to proceed with adding the product to your Wishlist.\n\t\t\t\t</p>\n\t\t\t</div>\n\n\t\t\t<div class=\"buttons\">\n\t\t\t\t<button class=\"button small secondary\" data-close> Decline</button>\n\t\t\t\t<button class=\"button small success commitToWishlistBtn";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "license_id"), env.opts.autoescape);
output += "\" data-close> Confirm</button>\n\t\t\t</div>\n\n\n\n\t\t\t<button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n\t\t    \t<span aria-hidden=\"true\">&times;</span>\n\t\t\t</button>\n\t\t</div>\n\t";
;
}
output += "\n";
;
}
output += "\n\n";
if((runtime.contextOrFrameLookup(context, frame, "usedPurchaseRequestLink"))) {
output += "\n\t<div class=\"reveal reveal-model\" id=\"usedPurchaseRequestLinkModal\" data-reveal>\n\t\t<h4 class=\"heading\"><span class=\"revealHeading\" style=\"background-color: #2f67b1; color: #FFF; padding: 5px;\">Cart Notification</span></h4>\n\n\t\t<div class=\"body\">\n\t\t\t<div>\n\t\t\t\t<p>You are currently buying for ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "buyForUser"), env.opts.autoescape);
output += ", are you sure you want to add the item to your cart?</p>\n\t\t\t\t<br>\n\t\t\t\t<p><b>The item will be added for ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "buyForUser"), env.opts.autoescape);
output += ".</b></p>\n\t\t\t\t<br>\n\t\t\t\t<p>To complete buying for ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "buyForUser"), env.opts.autoescape);
output += ", please continue to checkout your cart or remove all items from your cart</p>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"buttons\">\n\t\t\t<button class=\"button small secondary\" data-close> Cancel</button>\n\t\t\t<button class=\"button small success usedPurchaseRequestLinkBtn";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "license_id"), env.opts.autoescape);
output += "\" data-close> Add Item</button>\n\t\t</div>\n\t</div>\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
