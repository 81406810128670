var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
if(runtime.contextOrFrameLookup(context, frame, "consent_type_id") == 2) {
output += "\n<div class=\"row\">\n\t<div class=\"large-12 columns\">\n\t\t<label>\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "active") && !runtime.contextOrFrameLookup(context, frame, "canUncheck")) {
output += "\n\t\t\t\tYou are already receiving new offers from ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "storeName"), env.opts.autoescape);
output += ", to change your preferences, go to <a class=\"myProfile\">My Profile</a>\n\t\t\t";
;
}
else {
output += "\n\n\t\t\t\t<input type=\"checkbox\" name=\"consent\" class=\"consent\" data-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "consentTypeId"), env.opts.autoescape);
output += "\" id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "consentTypeId"), env.opts.autoescape);
output += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "active")) {
output += "checked";
;
}
output += "/>\n\t\t\t\tYes, I agree that ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "storeName"), env.opts.autoescape);
output += " can send me occasional newsletters, offers, and other information. ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "marketingPolicyUrl")),"length") > 0) {
output += "(<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "marketingPolicyUrl"), env.opts.autoescape);
output += "\" target=\"_blank\">Direct marketing policy</a>)";
;
}
output += "\n\t\t\t\n\t\t\t";
;
}
output += "\n\t\t</label>\n\t</div>\n</div>\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
