module.exports={
	"Pre-primary": [
		{"name": "Grade 0", "value": "G0", "order": 1},
		{"name": "Grade R", "value": "GR", "order": 2},
		{"name": "Grade RR", "value": "GRR", "order": 3},
		{"name": "Grade RRR", "value": "GRRR", "order": 4}
	],

	"Primary": [
		{"name": "Grade 1", "value": "G1", "alt": 1, "lexical": "Grade 01", "order": 5},
		{"name": "Grade 2", "value": "G2", "alt": 2, "lexical": "Grade 02", "order": 6},
		{"name": "Grade 3", "value": "G3", "alt": 3, "lexical": "Grade 03", "order": 7},
		{"name": "Grade 4", "value": "G4", "alt": 4, "lexical": "Grade 04", "order": 8},
		{"name": "Grade 5", "value": "G5", "alt": 5, "lexical": "Grade 05", "order": 9},
		{"name": "Grade 6", "value": "G6", "alt": 6, "lexical": "Grade 06", "order": 10},
		{"name": "Grade 7", "value": "G7", "alt": 7, "lexical": "Grade 07", "order": 11}
	],

	"Secondary": [
		{"name": "Grade 8", "value": "G8", "alt": 8, "lexical": "Grade 08", "order": 12},
		{"name": "Grade 9", "value": "G9", "alt": 9, "lexical": "Grade 09", "order": 13},
		{"name": "Grade 10", "value": "G10", "alt": 10, "order": 14},
		{"name": "Grade 11", "value": "G11", "alt": 11, "order": 15},
		{"name": "Grade 12", "value": "G12", "alt": 12, "order": 16}
	],

	"Tertiary": [
		{"name": "1st Year", "value": "T1", "alt": 13, "order": 17},
		{"name": "2nd Year", "value": "T2", "alt": 14, "order": 18},
		{"name": "3rd Year", "value": "T3", "alt": 15, "order": 19},
		{"name": "4th Year", "value": "T4", "alt": 16, "order": 20},
		{"name": "5th Year", "value": "T5", "order": 21},
		{"name": "6th Year", "value": "T6", "order": 22},
		{"name": "Honours", "value": "T7", "order": 23},
		{"name": "Masters", "value": "T8", "order": 24},
		{"name": "Doctorate", "value": "T9", "order": 25},
		{"name": "General Tertiary", "value": "TX", "order": 26},
		{"name": "Skills Course", "value": "SK" },
		{"name": "Short Course", "value": "SC" }
	],

	"NQF": [
		{"name": "NQF1", "value": "NQF1", "lexical": "NQF01", "order": 27},
		{"name": "NQF2", "value": "NQF2", "lexical": "NQF02", "order": 28},
		{"name": "NQF3", "value": "NQF3", "lexical": "NQF03", "order": 29},
		{"name": "NQF4", "value": "NQF4", "lexical": "NQF04", "order": 30},
		{"name": "NQF5", "value": "NQF5", "lexical": "NQF05", "order": 31},
		{"name": "NQF6", "value": "NQF6", "lexical": "NQF06", "order": 32},
		{"name": "NQF7", "value": "NQF7", "lexical": "NQF07", "order": 33},
		{"name": "NQF8", "value": "NQF8", "lexical": "NQF08", "order": 31},
		{"name": "NQF9", "value": "NQF9", "lexical": "NQF09", "order": 35},
		{"name": "NQF10", "value": "NQF10", "order": 36}
	],

	"KS": [
		{"name": "KS1", "value": "KS1", "lexical": "KS01", "order": 37},
		{"name": "KS2", "value": "KS2", "lexical": "KS02", "order": 38},
		{"name": "KS3", "value": "KS3", "lexical": "KS03", "order": 39},
		{"name": "KS4", "value": "KS4", "lexical": "KS04", "order": 40},
		{"name": "KS5", "value": "KS5", "lexical": "KS05", "order": 41}
	],

	"Years": [
		{"name": "Year 1", "value": "Y1", "lexical": "Y01", "order": 42},
		{"name": "Year 2", "value": "Y2", "lexical": "Y02", "order": 43},
		{"name": "Year 3", "value": "Y3", "lexical": "Y03", "order": 44},
		{"name": "Year 4", "value": "Y4", "lexical": "Y04", "order": 45},
		{"name": "Year 5", "value": "Y5", "lexical": "Y05", "order": 46},
		{"name": "Year 6", "value": "Y6", "lexical": "Y06", "order": 47},
		{"name": "Year 7", "value": "Y7", "lexical": "Y07", "order": 48},
		{"name": "Year 8", "value": "Y8", "lexical": "Y08", "order": 49},
		{"name": "Year 9", "value": "Y9", "lexical": "Y09", "order": 50},
		{"name": "Year 10", "value": "Y10", "order": 51},
		{"name": "Year 11", "value": "Y11", "order": 52},
		{"name": "Year 12", "value": "Y12", "order": 53},
		{"name": "Year 13", "value": "Y13", "order": 54}
	],

	"NCV": [
		{"name": "Level 2", "value": "NCV2", "order": 55},
		{"name": "Level 3", "value": "NCV3", "order": 56},
		{"name": "Level 4", "value": "NCV4", "order": 57}
	],

	"Nated": [
		{"name": "N1", "value": "N1", "order": 58},
		{"name": "N2", "value": "N2", "order": 59},
		{"name": "N3", "value": "N3", "order": 60},
		{"name": "N4", "value": "N4", "order": 61},
		{"name": "N5", "value": "N5", "order": 62},
		{"name": "N6", "value": "N6", "order": 63}
	],

	"Other": [
		{"name": "All Grades", "value": "ALL", "order": 64},
		{"name": "Teacher/Educator/Lecturer", "value": "TE", "alt": 0, "order": 65},
		{"name": "Training", "value": "TRN", "order": 66}
	]
}