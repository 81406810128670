var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\t<div class=\"viewBook\">\n\t\t<div class=\"container\">\n\t\t\t<div style=\"background-image: url('";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "coverImgMedium"), env.opts.autoescape);
output += "'), url('../images/no_cover.png')\" class=\"bookImg preview\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "\"></div>\n\t\t</div>\n\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "ebook_study_year");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("grade", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t";
if((t_4 == "TE")) {
output += "\n\t\t\t<div class=\"ribbon\">\n\t\t\t\t<div class=\"txt\">\n\t\t\t\t\tTeacher's Guide\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t";
;
}
}
frame = frame.pop();
output += "\n\t</div>\n\n\t<div class=\"info\">\n\t\t<div class=\"titleContainer\"><h5 class=\"title bookTooltip preview\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "ebook_name")), env.opts.autoescape);
output += "</h5></div>\n\t\t<h6><b>ISBN:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "ebook_isbn")), env.opts.autoescape);
output += "</h6>\n\t\t<h6><b>Publisher:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "ebook_publisher_name")), env.opts.autoescape);
output += "</h6>\n\t\t<h6><b>Genre:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "ebook_subject")), env.opts.autoescape);
output += "</h6>\n\t\t<h6><b>Study year:</b> ";
output += runtime.suppressValue(env.getFilter("gradeMap").call(context, runtime.contextOrFrameLookup(context, frame, "ebook_study_year"),true), env.opts.autoescape);
output += "</h6>\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "invalidError")) {
output += "\n\t\t\t<h6 class=\"invalidMessage\"><b>Error: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "invalidError")), env.opts.autoescape);
output += "</b></h6>\n\t\t";
;
}
output += "\n\t</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
