module.exports=[
	{
		"name": "Free",
		"lower": 0,
		"upper": 0
	},
	{
		"name": "Under {C}100",
		"lower": 0,
		"upper": 100		
	},
	{
		"name": "{C}100 to {C}200",
		"lower": 100,
		"upper": 200		
	},
	{
		"name": "{C}200 to {C}400",
		"lower": 200,
		"upper": 400		
	},
	{
		"name": "{C}400 to {C}600",
		"lower": 400,
		"upper": 600		
	},
	{
		"name": "{C}600 to {C}800",
		"lower": 600,
		"upper": 800		
	},
	{
		"name": "{C}800 to {C}1000",
		"lower": 800,
		"upper": 1000		
	},
	{
		"name": "more than {C}1000",
		"lower": 1000,
		"upper": 10000000		
	}
]