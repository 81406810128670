var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<form class=\"large-12 columns\">\n\t<div class=\"row\">\n\t\t<div class=\"large-6 columns\">\n\t\t\t<label>First Name\n\t\t\t\t<input type=\"text\" name=\"firstname\" placeholder=\"Your real name, or a nickname. We'll use this to be more welcoming on the site, and in emails\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "firstname")), env.opts.autoescape);
output += "\"/>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-6 columns\">\n\t\t\t<label>Surname\n\t\t\t\t<input type=\"text\" name=\"surname\" placeholder=\"Your real name, or a nickname. We'll use this to be more welcoming on the site, and in emails\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "surname")), env.opts.autoescape);
output += "\"/>\n\t\t\t</label>\n\t\t</div>\n\t</div>\n\n\t<div class=\"row\">\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>E-mail Address\n\t\t\t\t<input type=\"text\" name=\"email\" placeholder=\"Please use a valid e-mail, because we will email you important information about your purchases\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "email")), env.opts.autoescape);
output += "\"/>\n\t\t\t</label>\n\t\t</div>\n\t</div>\n\n\t<div class=\"row\">\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>Contact Number\n\t\t\t\t<input type=\"tel\" name=\"contactNumber\" placeholder=\"Contact Number\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "contact_number")), env.opts.autoescape);
output += "\"/>\n\t\t\t</label>\n\t\t</div>\n\t</div>\n\n\t<div class=\"row\">\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>VAT Number\n\t\t\t\t<input type=\"text\" name=\"vat\" placeholder=\"If you have a VAT number, enter it here to have it reflected on your invoices\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "vat_number")), env.opts.autoescape);
output += "\"/>\n\t\t\t</label>\n\t\t</div>\n\t</div>\n\n\t<div class=\"row\">\n\t\t<div class=\"large-12 small-12 columns\">\n\t\t\t<label>\n\t\t\t\tTimezone\n\t\t\t\t<select name=\"timezone\" data-placeholder=\"Timezone\">\n\t\t\t\t\t<option value=\"\"></option>\n\t\t\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "timezoneList");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("zone", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"name"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.memberLookup((t_4),"name") == runtime.contextOrFrameLookup(context, frame, "timezone")?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"name"), env.opts.autoescape);
output += " (";
output += runtime.suppressValue(runtime.memberLookup((t_4),"offset"), env.opts.autoescape);
output += ", ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"location"), env.opts.autoescape);
output += ")</option>\n\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t</select>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 small-12 columns\" style=\"text-align:right\">\n\t\t\t<label class=\"changePassword\"><input type=\"checkbox\" name=\"changePassword\" /> Change Password</label>\n\t\t</div>\n\n\t\t<div id=\"passwordReset\" class=\"large-12 columns\">\n\t\t\t<div class=\"row\">\n\t\t\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue((!(env.getFilter("impersonateAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"users")),"passwordChangeOverride")))?"":"hide"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<label>Current Password\n\t\t\t\t\t\t<input type=\"password\" name=\"oldpassword\"/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"row\">\n\t\t\t\t<div class=\"large-12 small-12 columns\">\n\t\t\t\t\t<label>New Password\n\t\t\t\t\t\t<input type=\"password\" name=\"password\"/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"row\">\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>Confirm New Password\n\t\t\t\t\t\t<input type=\"password\" name=\"password2\"/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"row\">\n\t\t\t\t<div class=\"large-12 small-12 columns ";
output += runtime.suppressValue((!(env.getFilter("impersonateAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"users")),"passwordChangeOverride")))?"hide":""), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<label><input type=\"checkbox\" name=\"forceChange\"/> Require this user to change their password after their next successful login</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"large-12 small-12 colunns\">\n\t\t\t<hr />\n\t\t</div>\n\n\t\t<div class=\"large-12 small-12 columns\">\n\t\t\t<h4>Additional Information</h4>\n\t\t\t<p>If you are enrolled at an educational institution, please provide us with more information in order for us to provide you with a better user experience.</p>\n\t\t</div>\n\n\t\t<div class=\"large-12 small-12 columns\">\n\t\t\t<div class=\"row\">\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tInstitution Type\n\t\t\t\t\t\t<select name=\"institutionType\" data-placeholder=\"Institution Type\">\t\t\n\t\t\t\t\t\t\t<option value=\"\"></option>\t\t\n\t\t\t\t\t\t\t<option value=\"Primary\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "institution_type") == "Primary"?"selected":""), env.opts.autoescape);
output += ">Primary</option>\n\t\t\t\t\t\t\t<option value=\"Secondary\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "institution_type") == "Secondary"?"selected":""), env.opts.autoescape);
output += ">Secondary</option>\n\t\t\t\t\t\t\t<option value=\"Tertiary\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "institution_type") == "Tertiary"?"selected":""), env.opts.autoescape);
output += ">Tertiary</option>\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tInstitution Name <span id=\"institutionNameLoader\" class=\"spinner-icon spinner-small hide spinner-details-working\"></span>\n\t\t\t\t\t\t<select name=\"institutionName\" data-placeholder=\"Institution Name\">\t\t\t\t\t\n\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tLevel\n\t\t\t\t\t\t<select name=\"level\" data-placeholder=\"Level\">\n\t\t\t\t\t\t\t<option value=\"\"></option>\t\t\t\t\t\n\t\t\t\t\t\t\t";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "grades");
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("grade", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t\t\t<optgroup label=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"label"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_11 = runtime.memberLookup((t_8),"items");
if(t_11) {var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("item", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n\t\t\t\t\t\t\t\t<option ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "institution_level") == runtime.memberLookup((t_12),"label")?"selected":""), env.opts.autoescape);
output += " value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"label"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_12),"label"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t</optgroup>\n\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<br />\n\n\t\t\t<div id=\"consent\"></div>\n\t\t\t<br />\n\t\t</div>\n\n\t\t<div class=\"large-12 right columns\" style=\"text-align:right\">\n\t\t\t<button type=\"button\" class=\"button small saveDetail success\">Update Details</button>\n\t\t\t<span class=\"spinner-icon spinner-small hide spinner-details-working\"></span> <span class=\"details-message hide\"></span>\n\t\t</div>\n\t</div>\n</form>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
