module.exports={
	"Afrikaans": [
		"Ander",
		"Rekeningkunde",
		"Landboubestuurspraktyk",
		"Landbouwetenskappe",
		"Landboutegnologie",
		"Kuns en Kultuur",
		"Besigheidstudies",
		"Besigheidstudies",
		"Siviele Tegnologie",
		"Handel",
		"Rekenaartoepassingstegnologie",
		"Rekenaarvaardigheid",
		"Verbruikerstudies",
		"Skeppende Kunste",
		"Dansstudies",
		"Ontwerp",
		"Dramatiese Kunste",
		"Ekonomiese en Bestuurswetenskappe",
		"Ekonomie",
		"Opvoedkunde",
		"Elektriese Tegnologie",
		"Ingenieurswese",
		"Ingenieursgrafika en Ontwerp",
		"Taalonderrig: Engels",
		"Perdestudies",
		"Akademie Algemeen",
		"Algemene Belang",
		"Algemene Wetenskappe",
		"Geografie",
		"Gesondheidswetenskappe",
		"Geskiedenis",
		"Gasvryheid",
		"Menslike- en Sosiale Wetenskappe",
		"Geesteswetenskappe",
		"Inligtingstegnologie",
		"Tale: Afrikaans Eerste Addisionele Taal",
		"Tale: Afrikaans Huistaal",
		"Tale: Afrikaans Tweede Addisionele Taal",
		"Tale: Engels Eerste Addisionele Taal",
		"Tale: Engels Huistaal",
		"Tale: Engels Tweede Addisionele Taal",
		"Tale: Frans Eerste Addisionele Taal",
		"Tale: Frans Huistaal",
		"Tale: Frans Tweede Addisionele Taal",
		"Tale: Duits Eerste Addisionele Taal",
		"Tale: Duits Huistaal",
		"Tale: Duits Tweede Addisionele Taal",
		"Tale: IsiNdebele Eerste Addisionele Taal",
		"Tale: IsiNdebele Huistaal",
		"Tale: IsiNdebele Tweede Addisionele Taal",
		"Tale: IsiXhosa Eerste Addisionele Taal",
		"Tale: IsiXhosa Huistaal",
		"Tale: IsiXhosa Tweede Addisionele Taal",
		"Tale: IsiZulu Eerste Addisionele Taal",
		"Tale: IsiZulu Huistaal",
		"Tale: IsiZulu Tweede Addisionele Taal",
		"Tale: Latyns Eerste Addisionele Taal",
		"Tale: Latyns Huistaal",
		"Tale: Latyns Tweede Addisionele Taal",
		"Tale: Sepedi Eerste Addisionele Taal",
		"Tale: Sepedi Huistaal",
		"Tale: Sepedi Tweede Addisionele Taal",
		"Tale: Sesotho Eerste Addisionele Taal",
		"Tale: Sesotho Huistaal",
		"Tale: Sesotho Tweede Addisionele Taal",
		"Tale: Setswana Eerste Addisionele Taal",
		"Tale: Setswana Huistaal",
		"Tale: Setswana Tweede Addisionele Taal",
		"Tale: Siswati Eerste Addisionele Taal",
		"Tale: Siswati Huistaal",
		"Tale: Siswati Tweede Addisionele Taal",
		"Tale: Tshivenda Eerste Addisionele Taal",
		"Tale: Tshivenda Huistaal",
		"Tale: Tshivenda Tweede Addisionele Taal",
		"Tale: Xitsonga Eerste Addisionele Taal",
		"Tale: Xitsonga Huistaal",
		"Tale: Xitsonga Tweede Addisionele Taal",
		"Tale: Spaanse Huistaal",
		"Tale: Spaanse Eerste Addisionele Taal",
		"Tale: Spaanse Tweede Addisionele Taal",
		"Tale: Italiaanse Huistaal",
		"Tale: Italiaanse Eerste Addisionele Taal",
		"Tale: Italiaanse Tweede Addisionele Taal",
		"Tale: Chinese Huistaal",
		"Tale: Chinese Eerste Addisionele Taal",
		"Tale: Chinese Tweede Addisionele Taal",
		"Tale: Mandaryns Huistaal",
		"Tale: Mandaryns Eerste Addisionele Taal",
		"Tale: Mandaryns Tweede Addisionele Taal",
		"Tale: Grieks Huistaal",
		"Tale: Grieks Eerste Addisionele Taal",
		"Tale: Grieks Tweede Addisionele Taal",
		"Regsgeleerdheid",
		"Lewensoriëntering",
		"Lewensvaardighede",
		"Lewenswetenskappe",
		"Letterkunde: Afrikaans Eerste Addisionele Taal",
		"Letterkunde: Afrikaans Huistaal",
		"Letterkunde: Afrikaans Tweede Addisionele Taal",
		"Letterkunde: Engels Eerste Addisionele Taal",
		"Letterkunde: Engels Huistaal",
		"Letterkunde: Engels Tweede Addisionele Taal",
		"Letterkunde: Frans Eerste Addisionele Taal",
		"Letterkunde: Frans Huistaal",
		"Letterkunde: Frans Tweede Addisionele Taal",
		"Letterkunde: Duits Eerste Addisionele Taal",
		"Letterkunde: Duits Huistaal",
		"Letterkunde: Duits Tweede Addisionele Taal",
		"Letterkunde: IsiNdebele Eerste Addisionele Taal",
		"Letterkunde: IsiNdebele Huistaal",
		"Letterkunde: IsiNdebele Tweede Addisionele Taal",
		"Letterkunde: IsiXhosa Eerste Addisionele Taal",
		"Letterkunde: IsiXhosa Huistaal",
		"Letterkunde: IsiXhosa Tweede Addisionele Taal",
		"Letterkunde: IsiZulu Eerste Addisionele Taal",
		"Letterkunde: IsiZulu Huistaal",
		"Letterkunde: IsiZulu Tweede Addisionele Taal",
		"Letterkunde: Latyns Eerste Addisionele Taal",
		"Letterkunde: Latyns Huistaal",
		"Letterkunde: Latyns Tweede Addisionele Taal",
		"Letterkunde: Sepedi Eerste Addisionele Taal",
		"Letterkunde: Sepedi Huistaal",
		"Letterkunde: Sepedi Tweede Addisionele Taal",
		"Letterkunde: Sesotho Eerste Addisionele Taal",
		"Letterkunde: Sesotho Huistaal",
		"Letterkunde: Sesotho Tweede Addisionele Taal",
		"Letterkunde: Setswana Eerste Addisionele Taal",
		"Letterkunde: Setswana Huistaal",
		"Letterkunde: Setswana Tweede Addisionele Taal",
		"Letterkunde: Siswati Eerste Addisionele Taal",
		"Letterkunde: Siswati Huistaal",
		"Letterkunde: Siswati Tweede Addisionele Taal",
		"Letterkunde: Tshivenda Eerste Addisionele Taal",
		"Letterkunde: Tshivenda Huistaal",
		"Letterkunde: Tshivenda Tweede Addisionele Taal",
		"Letterkunde: Xitsonga Eerste Addisionele Taal",
		"Letterkunde: Xitsonga Huistaal",
		"Letterkunde: Xitsonga Tweede Addisionele Taal",
		"Letterkunde: Spaanse Eerste Addisionele Taal",
		"Letterkunde: Spaanse Tweede Addisionele Taal",
		"Letterkunde: Spaanse Huistaal",
		"Letterkunde: Italiaanse Eerste Addisionele Taal",
		"Letterkunde: Italiaanse Tweede Addisionele Taal",
		"Letterkunde: Italiaanse Huistaal",
		"Letterkunde: Chinese Eerste Addisionele Taal",
		"Letterkunde: Chinese Tweede Addisionele Taal",
		"Letterkunde: Chinese Huistaal",
		"Letterkunde: Mandaryns Eerste Addisionele Taal",
		"Letterkunde: Mandaryns Tweede Addisionele Taal",
		"Letterkunde: Mandaryns Huistaal",
		"Letterkunde: Grieks Eerste Addisionele Taal",
		"Letterkunde: Grieks Tweede Addisionele Taal",
		"Letterkunde: Grieks Huistaal",
		"Maritieme Ekonomie",
		"Wiskundige Geletterdheid",
		"Wiskunde",
		"Meganiese Tegnologie",
		"Musiek",
		"Natuur- en Landbouwetenskappe",
		"Natuurwetenskappe",
		"Natuurwetenskappe en Tegnologie",
		"Seevaartkunde",
		"Fisiese Wetenskap",
		"Openbare- en Ontwikkelingsbestuur",
		"Religiestudies",
		"Navorsing, Verwysing en Studente-ondersteuning",
		"Sosiale Wetenskappe",
		"Sport en Oefenkunde",
		"Tegniese Wiskunde",
		"Tegniese Wetenskappe",
		"Tegnologie",
		"Toerisme",
		"Visuele Kunste"
	]
}