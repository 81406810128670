var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "users");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("user", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n<div class=\"userTooltipDiv_";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dependentId"), env.opts.autoescape);
output += "\" style=\"cursor:help;\">\n\t<div class=\"depend user_";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dependentId"), env.opts.autoescape);
output += "\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dependentId"), env.opts.autoescape);
output += "\" data-close>\t\t\n\t\t<div class=\"depend-wrapper depSelected\">\t\n\t\t\t<div class=\"circle user-";
output += runtime.suppressValue((lineno = 4, colno = 42, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "dependentNumber"), "dependentNumber", context, [runtime.memberLookup((t_4),"dependentId")])), env.opts.autoescape);
output += "\"><div class=\"inner\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, (lineno = 4, colno = 124, runtime.callWrap(runtime.memberLookup((runtime.memberLookup(((lineno = 4, colno = 106, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((t_4),"dependentName")),"split"), "user[\"dependentN\"][\"split\"]", context, [" "]))),0)),"substring"), "the return value of (user[\"dependentN\"][\"split\"])[\"0\"][\"substring\"]", context, [0,1]))), env.opts.autoescape);
output += runtime.suppressValue(env.getFilter("escape").call(context, (lineno = 4, colno = 182, runtime.callWrap(runtime.memberLookup((runtime.memberLookup(((lineno = 4, colno = 164, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((t_4),"dependentName")),"split"), "user[\"dependentN\"][\"split\"]", context, [" "]))),1)),"substring"), "the return value of (user[\"dependentN\"][\"split\"])[\"1\"][\"substring\"]", context, [0,1]))), env.opts.autoescape);
output += "</div></div>\n\t\t\t\n\t\t\t<span class=\"name\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_4),"dependentName")), env.opts.autoescape);
output += "</span>\n\n\t\t\t<span class=\"depSelect\">\n\t\t\t\t";
if(runtime.memberLookup((t_4),"hasBook")) {
output += "\n\t\t\t\t\t<input type=\"radio\" name=\"user\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dependentId"), env.opts.autoescape);
output += "\" checked id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dependentId"), env.opts.autoescape);
output += "\">\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t<input type=\"radio\" name=\"user\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dependentId"), env.opts.autoescape);
output += "\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dependentId"), env.opts.autoescape);
output += "\">\n\t\t\t\t";
;
}
output += "\n\t\t\t\t<label for=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dependentId"), env.opts.autoescape);
output += "\"><span></span></label>\n\t\t\t</span>\n\n\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_4),"schoolName")),"length") > 0) {
output += "\n\t\t\t\t<span class=\"school\">(";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_4),"schoolName")), env.opts.autoescape);
output += ")</span>\n\t\t\t";
;
}
output += "\n\t\t</div>\n\t</div>\n\t</div>\n\n\t<div class=\"tooltip_templates\">\n\t\t<div class=\"userTooltip_";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dependentId"), env.opts.autoescape);
output += " tooltips\" style=\"max-width:300px\">\n\t\t\t<span id=\"tooltipMessage_";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dependentId"), env.opts.autoescape);
output += "\"></span>\n\t\t</div>\n\t</div>\n";
;
}
}
frame = frame.pop();
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
