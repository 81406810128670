var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"large-12\">\n\t<p>Welcome to the ITSI Store. We noticed that you do not have any dependants linked to your account. Linking dependants allows you to purchase e-books for someone else, such as a learner in a school that is using the ITSI platform. To continue, please choose an option below:</p>\n\n\t<div class=\"row align-center\">\n\t\t<div class=\"column small-6 large-2 icon dependents\">\n\t\t\t<button>\n\t\t\t\t<span class=\"fa fa-users\"></span>\n\t\t\t\t<br />\n\t\t\t\t<span>Add a dependent</span>\n\t\t\t</button>\n\t\t</div>\n\t\t<div class=\"column small-6 large-2 icon self\">\n\t\t\t<button>\n\t\t\t\t<span class=\"fa fa-user\"></span>\n\t\t\t\t<br />\n\t\t\t\t<span>Purchase for myself</span>\n\t\t\t</button>\n\t\t</div>\n\t</div>\n\n\t<p>If you need to change the dependant you are purchasing e-books for, select the following drop-down menu near the top of the page:</p>\n\n\t<div class=\"dependents-btn\">\n\t\t<span class=\"info\">Buying&nbsp;e‑books&nbsp;for:</span>\n\t\t<a href=\"#\" tabindex=\"0\">\n\t\t\t<span class=\"fa fa-user identity child\"> <span>DEP</span></span>\n\t\t\t\n\t\t\t<span class=\"name\">John Doe</span>\n\t\t</a>\n\t</div>\n\n\t<p><input type=\"checkbox\" id=\"hide\"> Don't show this again</input></p>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
