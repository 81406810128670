var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var initial_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n<span class=\"spinner-icon spinner-small\"/> Loading features...\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var initial_oldRoot = initial_obj.root;
initial_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	initial_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n";
var macro_t_1 = runtime.makeMacro(
["key", "feat"], 
["subkey"], 
function (l_key, l_feat, kwargs) {
frame = frame.push();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("key", l_key);
frame.set("feat", l_feat);
frame.set("subkey", kwargs.hasOwnProperty("subkey") ? kwargs["subkey"] : "");
var t_2 = "";t_2 += "\n\t<div class=\"grid-x\">\n\t";
if(runtime.memberLookup((l_feat),"type") == "object") {
t_2 += "\n\n\t\t<div class=\"large-3 cell\">\n\t\t\t<label class=\"inlined prefix\">";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "</label>\n\t\t</div>\n\t\t<div class=\"large-9 cell\">\n\t\t\t";
frame = frame.push();
var t_5 = runtime.memberLookup((l_feat),"def");
if(t_5) {var t_3;
if(runtime.isArray(t_5)) {
var t_4 = t_5.length;
for(t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3][0]
frame.set("key2", t_5[t_3][0]);
var t_7 = t_5[t_3][1]
frame.set("feat2", t_5[t_3][1]);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += "\n\t\t\t\t";
t_2 += runtime.suppressValue((lineno = 10, colno = 11, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "render"), "render", context, [t_6,t_7,l_key + "."])), env.opts.autoescape);
t_2 += "\n\t\t\t";
;
}
} else {
t_3 = -1;
var t_4 = runtime.keys(t_5).length;
for(var t_8 in t_5) {
t_3++;
var t_9 = t_5[t_8];
frame.set("key2", t_8);
frame.set("feat2", t_9);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += "\n\t\t\t\t";
t_2 += runtime.suppressValue((lineno = 10, colno = 11, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "render"), "render", context, [t_8,t_9,l_key + "."])), env.opts.autoescape);
t_2 += "\n\t\t\t";
;
}
}
}
frame = frame.pop();
t_2 += "\n\t\t</div>\n\t";
;
}
else {
if(runtime.memberLookup((l_feat),"type") == "display-array") {
t_2 += "\n\n\t\t<div class=\"large-3 cell\">\n\t\t\t<label class=\"inlined prefix\">";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "</label>\n\t\t</div>\n\t\t<div class=\"large-9 cell\">\n\t\t\t<label for=\"_";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\">";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"desc"), env.opts.autoescape);
t_2 += "</label>\n\t\t\t<div style=\"margin-bottom: 10px\">";
t_2 += runtime.suppressValue((lineno = 20, colno = 50, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((l_feat),"val")),"join"), "feat[\"val\"][\"join\"]", context, [", "])), env.opts.autoescape);
t_2 += "</div>\n\t\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((l_feat),"type") == "display") {
t_2 += "\n\n\t\t<div class=\"large-3 cell\">\n\t\t\t<label class=\"inlined prefix\">";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "</label>\n\t\t</div>\n\t\t<div class=\"large-9 cell\">\n\t\t\t<label for=\"_";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\">";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"desc"), env.opts.autoescape);
t_2 += "</label>\n\t\t\t<div style=\"margin-bottom: 10px\">";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"val"), env.opts.autoescape);
t_2 += "</div>\n\t\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((l_feat),"type") == "set-number") {
t_2 += "\n\n\t\t<div class=\"large-3 cell\">\n\t\t\t<label class=\"inlined prefix\">";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "</label>\n\t\t</div>\n\t\t<div class=\"large-9 cell\">\n\t\t\t<label for=\"_";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\">";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"desc"), env.opts.autoescape);
t_2 += "</label>\n\t\t\t<select name=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\" id=\"_";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\" class=\"set-number\" ";
t_2 += runtime.suppressValue((runtime.memberLookup((l_feat),"multiple")?"multiple":""), env.opts.autoescape);
t_2 += ">\n\t\t\t";
frame = frame.push();
var t_12 = runtime.memberLookup((l_feat),"values");
if(t_12) {var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("v", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
t_2 += "\n\t\t\t\t<option value=\"";
t_2 += runtime.suppressValue(t_13, env.opts.autoescape);
t_2 += "\" ";
t_2 += runtime.suppressValue(((env.getFilter("contains").call(context, runtime.memberLookup((l_feat),"val"),t_13))?"selected":""), env.opts.autoescape);
t_2 += ">";
t_2 += runtime.suppressValue(t_13, env.opts.autoescape);
t_2 += "</option>\n\t\t\t";
;
}
}
frame = frame.pop();
t_2 += "\n\t\t\t</select>\n\t\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((l_feat),"type") == "set-string") {
t_2 += "\n\n\t\t<div class=\"large-3 cell\">\n\t\t\t<label class=\"inlined prefix\">";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "</label>\n\t\t</div>\n\t\t<div class=\"large-9 cell\">\n\t\t\t<label for=\"_";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\">";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"desc"), env.opts.autoescape);
t_2 += "</label>\n\t\t\t<select name=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\" id=\"_";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\" class=\"set-string\" ";
t_2 += runtime.suppressValue((runtime.memberLookup((l_feat),"multiple")?"multiple":""), env.opts.autoescape);
t_2 += ">\n\t\t\t";
frame = frame.push();
var t_16 = runtime.memberLookup((l_feat),"values");
if(t_16) {var t_15 = t_16.length;
for(var t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14];
frame.set("v", t_17);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
t_2 += "\n\t\t\t\t<option value=\"";
t_2 += runtime.suppressValue(t_17, env.opts.autoescape);
t_2 += "\" ";
t_2 += runtime.suppressValue(((env.getFilter("contains").call(context, runtime.memberLookup((l_feat),"val"),t_17))?"selected":""), env.opts.autoescape);
t_2 += ">";
t_2 += runtime.suppressValue(t_17, env.opts.autoescape);
t_2 += "</option>\n\t\t\t";
;
}
}
frame = frame.pop();
t_2 += "\n\t\t\t</select>\n\t\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((l_feat),"type") == "boolean") {
t_2 += "\n\n\t\t<div class=\"large-3 cell\">\n\t\t\t<label class=\"inlined prefix\">";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "</label>\n\t\t</div>\n\t\t<div class=\"large-9 cell\">\n\t\t\t<label for=\"_";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\">";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"desc"), env.opts.autoescape);
t_2 += "</label>\n\t\t\t<input type=\"checkbox\" name=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\" id=\"_";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\" ";
t_2 += runtime.suppressValue((runtime.memberLookup((l_feat),"val")?"checked":""), env.opts.autoescape);
t_2 += " value=\"1\"/>\n\t\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((l_feat),"type") == "string" || runtime.memberLookup((l_feat),"type") == "number") {
t_2 += "\n\n\t\t<div class=\"large-3 cell\">\n\t\t\t<label class=\"inlined prefix\">";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "</label>\n\t\t</div>\n\t\t<div class=\"large-9 cell\">\n\t\t\t<label>";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"desc"), env.opts.autoescape);
t_2 += "\n\t\t\t\t<input type=\"text\" name=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\" class=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"type"), env.opts.autoescape);
t_2 += "\" value=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"val"), env.opts.autoescape);
t_2 += "\" placeholder=\"";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\"/>\n\t\t\t</label>\n\t\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((l_feat),"type") == "array") {
t_2 += "\n\n\t\t<div class=\"large-3 cell\">\n\t\t\t<label class=\"inlined prefix\">";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "</label>\n\t\t</div>\n\t\t<div class=\"large-9 cell\">\n\t\t\t<label>";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"desc"), env.opts.autoescape);
t_2 += "\n\t\t\t\t<textarea name=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\" value=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"val"), env.opts.autoescape);
t_2 += "\" placeholder=\"";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\" rows=\"5\">";
t_2 += runtime.suppressValue(env.getFilter("join").call(context, runtime.memberLookup((l_feat),"val"),"\n"), env.opts.autoescape);
t_2 += "</textarea>\n\t\t\t</label>\n\t\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((l_feat),"type") == "date") {
t_2 += "\n\n\t\t<div class=\"large-3 cell\">\n\t\t\t<label class=\"inlined prefix\">";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "</label>\n\t\t</div>\n\t\t<div class=\"large-9 cell\">\n\t\t\t<label>";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"desc"), env.opts.autoescape);
t_2 += "\n\t\t\t\t<input type=\"text\" readonly name=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\" class=\"date\" value=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"val"), env.opts.autoescape);
t_2 += "\" placeholder=\"";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "\" data-value=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"val"), env.opts.autoescape);
t_2 += "\"/>\n\t\t\t</label>\n\t\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((l_feat),"type") == "timerange") {
t_2 += "\n\n\t\t<div class=\"large-3 cell\">\n\t\t\t<label class=\"inlined prefix\">";
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "</label>\n\t\t</div>\n\t\t<div class=\"large-9 cell\">\n\t\t\t<div class=\"grid-x\">\n\t\t\t\t<div class=\"large-12 cell\">\n\t\t\t\t\t<label>";
t_2 += runtime.suppressValue(runtime.memberLookup((l_feat),"desc"), env.opts.autoescape);
t_2 += "</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"row collapse\">\n\t\t\t\t<div class=\"large-4 cell\">\n\t\t\t\t\t<label>from\n\t\t\t\t\t\t<input type=\"text\" size=\"6\" name=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "-1\" class=\"time\" data-value=\"";
t_2 += runtime.suppressValue(env.getFilter("first").call(context, env.getFilter("split").call(context, runtime.memberLookup((l_feat),"val"),"-")), env.opts.autoescape);
t_2 += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-4 cell\">\n\t\t\t\t\t<label>until\n\t\t\t\t\t\t<input type=\"text\" size=\"6\" name=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subkey"), env.opts.autoescape);
t_2 += runtime.suppressValue(l_key, env.opts.autoescape);
t_2 += "-2\" class=\"time\" data-value=\"";
t_2 += runtime.suppressValue(env.getFilter("last").call(context, env.getFilter("split").call(context, runtime.memberLookup((l_feat),"val"),"-")), env.opts.autoescape);
t_2 += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-4 cell\"></div>\n\t\t\t</div>\n\t\t</div>\n\n\t";
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
t_2 += "\n\t</div>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("render");
context.setVariable("render", macro_t_1);
output += "\n\n<h3><span class=\"fa fa-cogs\"></span> Feature Control</h3>\n\n";
frame = frame.push();
var t_20 = runtime.contextOrFrameLookup(context, frame, "features");
if(t_20) {var t_18;
if(runtime.isArray(t_20)) {
var t_19 = t_20.length;
for(t_18=0; t_18 < t_20.length; t_18++) {
var t_21 = t_20[t_18][0]
frame.set("key", t_20[t_18][0]);
var t_22 = t_20[t_18][1]
frame.set("feat", t_20[t_18][1]);
frame.set("loop.index", t_18 + 1);
frame.set("loop.index0", t_18);
frame.set("loop.revindex", t_19 - t_18);
frame.set("loop.revindex0", t_19 - t_18 - 1);
frame.set("loop.first", t_18 === 0);
frame.set("loop.last", t_18 === t_19 - 1);
frame.set("loop.length", t_19);
output += "\n\t";
output += runtime.suppressValue((lineno = 137, colno = 8, runtime.callWrap(macro_t_1, "render", context, [t_21,t_22])), env.opts.autoescape);
output += "\n";
;
}
} else {
t_18 = -1;
var t_19 = runtime.keys(t_20).length;
for(var t_23 in t_20) {
t_18++;
var t_24 = t_20[t_23];
frame.set("key", t_23);
frame.set("feat", t_24);
frame.set("loop.index", t_18 + 1);
frame.set("loop.index0", t_18);
frame.set("loop.revindex", t_19 - t_18);
frame.set("loop.revindex0", t_19 - t_18 - 1);
frame.set("loop.first", t_18 === 0);
frame.set("loop.last", t_18 === t_19 - 1);
frame.set("loop.length", t_19);
output += "\n\t";
output += runtime.suppressValue((lineno = 137, colno = 8, runtime.callWrap(macro_t_1, "render", context, [t_23,t_24])), env.opts.autoescape);
output += "\n";
;
}
}
}
frame = frame.pop();
output += "\n\n<div class=\"grid-x\">\n\t<div class=\"large-12 cell\">\n\t\t<button class=\"save button\">Save Changes</button>\n\t</div>\n</div>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"initial": new nunjucks.Template( {obj: initial_obj, type: "code"}, env),	"main": new nunjucks.Template( {obj: main_obj, type: "code"}, env),};
