var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n<div id=\"flag\"></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var flag_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\n\t<div class=\"bookListflag\">\n\n\t\t<div class=\"flag flag-main\" data-open=\"selectGradeModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\">\n\n\t\t<span class=\"listText\">\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "schoolId") == 0) {
output += "\n\t\t\t\t<div>YOU HAVE BOOK LISTS AVAILABLE </div>\n\t\t\t";
;
}
else {
output += "\n\t\t\t\t<div>THERE ARE PRESCRIBED BOOK LISTS FOR </div>\n\t\t\t\t<div class=\"schoolName\">\n\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("replace").call(context, env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "schoolName")),".store",""), env.opts.autoescape);
output += "\n\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t</span>\n\t\t<span class=\"viewList\">View</span>\n\t\t<span class=\"mobileViewList\">VIEW BOOK LISTS <i class=\"fa fa-caret-down fa-fw\" aria-hidden=\"true\"></i></span>\n\t\t</div>\n\t</div>\n\n\n\n\t<div class=\"reveal reveal-model bookListReveal\" id=\"selectGradeModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\" data-reveal>\n\t\t<h4 class=\"heading\"><span class=\"revealHeading\" style=\"background-color: #2f67b1; color: #FFF; padding: 5px;\">Year: ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "year"), env.opts.autoescape);
if(runtime.contextOrFrameLookup(context, frame, "chooseSchool") == true) {
output += "<select name=\"schools\">";
;
}
output += "</select></span></h4>\n\t\t<div class=\"body\">\n\n\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "grades");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("grade", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t<div class=\"listName gradeName\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_4),"name")), env.opts.autoescape);
output += "\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\"> <span class=\"name\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_4),"name")), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t<span class=\"listSelect\">\n\t\t\t\t\t\t<input type=\"radio\" name=\"grade\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\" checked=\"\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\">\n\n\t\t\t\t\t\t<label for=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\"><span id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\"></span></label>\n\t\t\t\t\t</span>\n\t\t\t\t</div>\n\n\n\t\t\t\t<div class=\"hide\" id=\"gradeList";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t";
frame = frame.push();
var t_7 = t_4;
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("group", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t\t";
frame = frame.push();
var t_11 = t_8;
if(t_11) {var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("list", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n\t\t\t\t\t\t\t<div class=\"listName bookListName\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_12),"name")), env.opts.autoescape);
output += "\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"book_list_id"), env.opts.autoescape);
output += "\" data-close\"><span class=\"name\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"book_list_id"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_12),"name")), env.opts.autoescape);
output += "</span>\n\n\t\t\t\t\t\t\t\t<span class=\"listSelect\">\n\t\t\t\t\t\t\t\t\t<input type=\"radio\" name=\"grade\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\" checked=\"\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\">\n\n\t\t\t\t\t\t\t\t\t<label for=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\"><span id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"book_list_id"), env.opts.autoescape);
output += "\"></span></label>\n\t\t\t\t\t\t\t\t</span>\n\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t</div>\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t\t<button class=\"button small backToGradesBtn minimizedButton\" id=\"backToGradesBtn\"><i class=\"fa fa-arrow-left fa-fw\" aria-hidden=\"true\" ></i> Back to grades</button>\n\n\t\t</div>\n\n\t\t<button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n\t\t\t<span aria-hidden=\"true\">&times;</span>\n\t\t</button>\n\t</div>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var flag_oldRoot = flag_obj.root;
flag_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	flag_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var heading_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\n\t<button class=\"button small minimizedButton\" id=\"backBtn\"><i class=\"fa fa-arrow-left fa-fw\" aria-hidden=\"true\"></i> Back to all books</button>\n\t<div class=\"info\">\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "schoolId") == 0) {
output += "\n\t\t\t<div class=\"description\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "bookList")),"name")), env.opts.autoescape);
output += "</div>\n\t\t";
;
}
else {
output += "\n\t\t\t<img class=\"schoolLogo\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "schoolLogo"), env.opts.autoescape);
output += "\" onerror=\"this.style.display='none'\"/>\n\t\t\t<div class=\"description\">Prescribed titles for ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "schoolName")), env.opts.autoescape);
output += " - ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "bookList")),"name")), env.opts.autoescape);
output += "</div>\n\t\t";
;
}
output += "\n\t</div>\n\t<button class=\"button small\" id=\"addAllBtn\"><i class=\"fa fa-shopping-cart fa-fw\" aria-hidden=\"true\"></i> Add all to cart</button>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var heading_oldRoot = heading_obj.root;
heading_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	heading_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"main": new nunjucks.Template( {obj: main_obj, type: "code"}, env),	"flag": new nunjucks.Template( {obj: flag_obj, type: "code"}, env),	"heading": new nunjucks.Template( {obj: heading_obj, type: "code"}, env),};
