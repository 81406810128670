var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"container\">\n  <div class=\"row\">\n    <div class=\"col-sm-12 steps\">    \n      <ol class=\"list-inline text-center step-indicator\">\n        <li class=\"complete\">\n          <div class=\"step\"><span class=\"fa fa-check\"></span></div>\n          <div class=\"caption hidden-xs hidden-sm\">Review</div>\n        </li>\n        <li class=\"active\">\n          <div class=\"step\">2</div>\n          <div class=\"caption hidden-xs hidden-sm\">Checkout</div>\n        </li>\n        <li class=\"inactive\">\n          <div class=\"step\">3</div>\n          <div class=\"caption hidden-xs hidden-sm\">Payment</div>\n        </li>       \n      </ol>\n    </div>\n  </div>\n</div>\n\n\n<h5><b><i class=\"fa fa-shopping-cart fa-fw\"></i> Review my order</b> <span>(";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "count"), env.opts.autoescape);
output += " items, ";
output += runtime.suppressValue(((runtime.contextOrFrameLookup(context, frame, "total") > 0)?runtime.contextOrFrameLookup(context, frame, "formattedAmount"):"Free"), env.opts.autoescape);
output += ")</span></h5>\n\n<div class=\"checkoutMain\">\n\t<div class=\"buttons\">\n\t\t<button class=\"button small back\"><i class=\"fa fa-arrow-left fa-fw\"></i> Back to Review</button> \n\t\t<button class=\"button small shop\"><i class=\"fa fa-arrow-left fa-fw\"></i> Continue Shopping</button> \n\t</div>\n\t<div class=\"details\">\n\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "cartItems");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("cart", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t";
var t_5;
t_5 = (lineno = 31, colno = 40, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((t_4),"dependentName")),"split"), "cart[\"dependentN\"][\"split\"]", context, [" "]));
frame.set("names", t_5, true);
if(frame.topLevel) {
context.setVariable("names", t_5);
}
if(frame.topLevel) {
context.addExport("names", t_5);
}
output += "\n\t\t\t<div class=\"userBlock\">\n\t\t\t\t<div class=\"dependent\">\t\t\n\t\t\t\t\t<div class=\"circle user-";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dependentNumber"), env.opts.autoescape);
output += "\"><div class=\"inner\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("first").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "names")),0))), env.opts.autoescape);
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "names")),"length") > 1 && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "names")),1)),"length") > 0) {
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("first").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "names")),1))), env.opts.autoescape);
;
}
output += "</div></div>\n\t\t\t\t\t<span class=\"name\">&nbsp;";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_4),"dependentName")), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t<span class=\"extra\">(";
output += runtime.suppressValue(runtime.memberLookup((t_4),"schoolName") || env.getFilter("escape").call(context, "Independent Buyer"), env.opts.autoescape);
output += ")</span>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"books\">\n\t\t\t\t\t";
frame = frame.push();
var t_8 = runtime.memberLookup((t_4),"cartItems");
if(t_8) {var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("item", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\t\t\t\t\t\n\t\t\t\t\t\t<div class=\"bookDiv\">\n\t\t\t\t\t\t\t<div class=name>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"ebook_name")), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t\t\t<div class=\"price\">";
output += runtime.suppressValue(((runtime.memberLookup((t_9),"price") > 0)?(runtime.memberLookup((t_9),"symbol") + runtime.memberLookup((t_9),"price")):"Free"), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t\t\t<i class=\"remove\" aria-hidden=\"true\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_9),"id"), env.opts.autoescape);
output += "\">Remove</i>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t</div>\n\t\t\t\t\n\t\t\t</div>\n\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t<div class=\"total\">Total: ";
output += runtime.suppressValue(((runtime.contextOrFrameLookup(context, frame, "total") > 0)?runtime.contextOrFrameLookup(context, frame, "formattedAmount"):"Free"), env.opts.autoescape);
output += "</div>\n\n\t\t<div class=\"info\">\n\t\t\t<span><i class=\"fa fa-info-circle fa-fw\" aria-hidden=\"true\"></i> You can add your voucher code when you make your payment.</span>\n\t\t</div>\n\n\t\t<div class=\"pay\">\n\t\t\t<button type=\"button\" class=\"button small paymentBtn\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "invalid")?"disabled":""), env.opts.autoescape);
output += " id=\"payBtn\">\n\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "invalid")) {
output += "\n\t\t\t\t\tError(s) preventing checkout\n\t\t\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "total") > 0) {
output += "\n\t\t\t\t\t<span class=\"fa fa-credit-card\"></span> Make Payment\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\tProcess free purchase\n\t\t\t\t";
;
}
;
}
output += "\n\t\t\t</button>\n\t\t\t\t\n\t\t</div>\n\t\t\n\t</div>\n</div>\n\n<!--\n<div class=\"row\">\n\t<div class=\"columns reviewOrder\">\n\t\t<h4>Review my order</h4>\n\t\t<table>\n\t\t\t<thead>\n\t\t\t\t<tr>\n\t\t\t\t\t<th>E-book</th>\n\t\t\t\t\t";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "disableDependents")?"<th width='100'>Buying for</th>":""), env.opts.autoescape);
output += "\n\t\t\t\t\t<th width='100'>";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "disableDependents")?"School":""), env.opts.autoescape);
output += "</th>\n\t\t\t\t\t<th width=\"50\">Price</th>\n\t\t\t\t</tr>\n\t\t\t</thead>\n\t\t\t<tbody>\n\t\t\t\t";
frame = frame.push();
var t_12 = runtime.contextOrFrameLookup(context, frame, "cartItems");
if(t_12) {var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("cart", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
output += "\n\t\t\t\t";
frame = frame.push();
var t_16 = runtime.memberLookup((t_13),"cartItems");
if(t_16) {var t_15 = t_16.length;
for(var t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14];
frame.set("item", t_17);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
output += "\n\t\t\t\t<tr>\n\t\t\t\t\t<td>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_17),"ebook_name")), env.opts.autoescape);
output += "</td>\n\t\t\t\t\t<td>";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "disableDependents")?(env.getFilter("escape").call(context, runtime.memberLookup((t_17),"dependent_name"))):""), env.opts.autoescape);
output += "</td>\n\n\t\t\t\t\t";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "disableDependents")?((runtime.memberLookup((t_17),"school_name")?("<td style='text-align: center;' class='school'><span>" + runtime.memberLookup((t_17),"school_name") + "</span></td>"):("<td style='text-align: center;' class='school'><span class='fa fa-times-circle' style='font-size: 2rem; color: red' title='This book is not being bought for a school'/> <span>None</span></td>"))):""), env.opts.autoescape);
output += "\n\n\t\t\t\t\t<td>";
output += runtime.suppressValue(((runtime.memberLookup((t_17),"price") > 0)?(runtime.memberLookup((t_17),"symbol") + runtime.memberLookup((t_17),"price")):"Free"), env.opts.autoescape);
output += "</td>\n\t\t\t\t</tr>\n\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t<tr>\n\t\t\t\t\t<td colspan='";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "disableDependents")?"2":"1"), env.opts.autoescape);
output += "'>\n\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "discountPercentage") > 0) {
output += "\n\t\t\t\t\t\t<strong>Discount of ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "discountPercentage"), env.opts.autoescape);
output += "% (";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "currency") + env.getFilter("round").call(context, (runtime.contextOrFrameLookup(context, frame, "total") * (runtime.contextOrFrameLookup(context, frame, "discountPercentage") / 100)),2), env.opts.autoescape);
output += ") applied</strong>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t</td>\n\t\t\t\t\t<td class=\"text-right\"><strong>Total</strong></td>\n\t\t\t\t\t<td><strong>";
output += runtime.suppressValue(((runtime.contextOrFrameLookup(context, frame, "total") > 0)?runtime.contextOrFrameLookup(context, frame, "formattedAmount"):"Free"), env.opts.autoescape);
output += "</strong></td>\n\t\t\t\t</tr>\n\t\t\t</tbody>\n\t\t</table>\n\t</div>\n</div>\n\n-->";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
