var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var buyForUserInput_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t";
if((lineno = 1, colno = 22, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "hasFcListCartItems"), "hasFcListCartItems", context, []))) {
output += "\n\t\t<div class=\"input-group\">\n\t\t\t<span class=\"input-group-label\"><span class=\"fa fa-user\"></span></span>\n\t\t\t<input type=\"email\" name=\"email\" id=\"userEmail\" placeholder=\"Buy for User email\">\n\t\t\t<div class=\"input-group-button\">\n\t\t\t\t<button type=\"submit\" class=\"button searchForUser\" id=\"searchForUser\"><span class=\"fa fa-search\"></span></button>\n\t\t\t</div>\n\t\t</div>\n\t";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var buyForUserInput_oldRoot = buyForUserInput_obj.root;
buyForUserInput_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	buyForUserInput_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"buyForUserInput": new nunjucks.Template( {obj: buyForUserInput_obj, type: "code"}, env),};
