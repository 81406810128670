var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var dependents_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dependents")),"length") > 1) {
output += "\n\t\t";
var t_1;
t_1 = 1;
frame.set("count", t_1, true);
if(frame.topLevel) {
context.setVariable("count", t_1);
}
if(frame.topLevel) {
context.addExport("count", t_1);
}
output += "\n\t\t";
frame = frame.push();
var t_4 = runtime.contextOrFrameLookup(context, frame, "dependents");
if(t_4) {var t_3 = t_4.length;
for(var t_2=0; t_2 < t_4.length; t_2++) {
var t_5 = t_4[t_2];
frame.set("dependent", t_5);
frame.set("loop.index", t_2 + 1);
frame.set("loop.index0", t_2);
frame.set("loop.revindex", t_3 - t_2);
frame.set("loop.revindex0", t_3 - t_2 - 1);
frame.set("loop.first", t_2 === 0);
frame.set("loop.last", t_2 === t_3 - 1);
frame.set("loop.length", t_3);
output += "\n\t\t\t";
var t_6;
t_6 = runtime.contextOrFrameLookup(context, frame, "count") + 1;
frame.set("count", t_6, true);
if(frame.topLevel) {
context.setVariable("count", t_6);
}
if(frame.topLevel) {
context.addExport("count", t_6);
}
output += "\n\t\t\t\t";
if(runtime.memberLookup((t_5),"user_id") == runtime.contextOrFrameLookup(context, frame, "selectedDependent")) {
output += "\n\n\t\t\t\t\t<div class=\"dependentflag\">\n\n\t\t\t\t\t";
if(runtime.memberLookup((t_5),"parent")) {
output += "\n\t\t\t\t\t\t<div class=\"flag top flag-main\" data-open=\"switchDependentModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "userIds"), env.opts.autoescape);
output += "_";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t";
var t_7;
t_7 = runtime.contextOrFrameLookup(context, frame, "count") - 1;
frame.set("count", t_7, true);
if(frame.topLevel) {
context.setVariable("count", t_7);
}
if(frame.topLevel) {
context.addExport("count", t_7);
}
output += "\n\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t<div class=\"flag top flag-";
output += runtime.suppressValue(runtime.memberLookup((t_5),"colour"), env.opts.autoescape);
output += "\" data-open=\"switchDependentModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "userIds"), env.opts.autoescape);
output += "_";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t<span class=\"depIcon\"><i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i></span>\n\n\t\t\t\t\t<span class=\"depText\">\n\t\t\t\t\t\tYOU ARE BUYING FOR:\n\t\t\t\t\t\t<div class=\"depName\">\n\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"firstname")), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"surname")), env.opts.autoescape);
output += " <i class=\"fa fa-caret-down fa-fw\" aria-hidden=\"true\"></i>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</span>\n\t\t\t\t\t<span class=\"depSwitch\">Buy for someone else</span>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t";
;
}
output += "\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "count") == 10) {
output += "\n\t\t\t\t";
var t_8;
t_8 = 1;
frame.set("count", t_8, true);
if(frame.topLevel) {
context.setVariable("count", t_8);
}
if(frame.topLevel) {
context.addExport("count", t_8);
}
output += "\n\t\t\t";
;
}
output += "\n\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t<div class=\"reveal reveal-model switch-dependent-modal\" id=\"switchDependentModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "userIds"), env.opts.autoescape);
output += "_";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\" data-reveal data-reset-on-close=\"true\">\n\t\t\t<h4 class=\"heading\">Start buying for:</h4>\n\t\t\t<div>\n\n\t\t\t\t";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dependents")),"length") > 3)) {
output += "\n\t\t\t\t<div class=\"input-group\">\n\t\t\t\t\t<span class=\"input-group-label\"><i class=\"fa fa-search\" aria-hidden=\"true\"></i></span>\n\t\t\t\t\t<input type=\"text\" name=\"dependentFilter\" id=\"dependentFilter\" class=\"input-group-field\" placeholder=\"Search for user\" />\n\t\t\t\t</div>\n\t\t\t\t";
;
}
output += "\n\n\t\t\t\t";
var t_9;
t_9 = 1;
frame.set("count", t_9, true);
if(frame.topLevel) {
context.setVariable("count", t_9);
}
if(frame.topLevel) {
context.addExport("count", t_9);
}
output += "\n\t\t\t\t";
frame = frame.push();
var t_12 = runtime.contextOrFrameLookup(context, frame, "dependents");
if(t_12) {var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("dependent", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
output += "\n\t\t\t\t\t";
var t_14;
t_14 = runtime.contextOrFrameLookup(context, frame, "count") + 1;
frame.set("count", t_14, true);
if(frame.topLevel) {
context.setVariable("count", t_14);
}
if(frame.topLevel) {
context.addExport("count", t_14);
}
output += "\n\n\t\t\t\t\t<div class=\"depend\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"user_id"), env.opts.autoescape);
output += "\" id=\"user";
output += runtime.suppressValue(runtime.memberLookup((t_13),"user_id"), env.opts.autoescape);
output += "\" data-close>\n\t\t\t\t\t\t<div class=\"depend-wrapper depSelected\">\n\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_13),"parent")) {
output += "\n\t\t\t\t\t\t\t\t<div class=\"circle user-main\"><div class=\"inner\">";
output += runtime.suppressValue(env.getFilter("first").call(context, env.getFilter("list").call(context, env.getFilter("trim").call(context, runtime.memberLookup((t_13),"firstname")))), env.opts.autoescape);
output += runtime.suppressValue(env.getFilter("first").call(context, env.getFilter("list").call(context, env.getFilter("trim").call(context, runtime.memberLookup((t_13),"surname")))), env.opts.autoescape);
output += "</div></div>\n\n\t\t\t\t\t\t\t\t";
var t_15;
t_15 = runtime.contextOrFrameLookup(context, frame, "count") - 1;
frame.set("count", t_15, true);
if(frame.topLevel) {
context.setVariable("count", t_15);
}
if(frame.topLevel) {
context.addExport("count", t_15);
}
output += "\n\n\t\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t\t<div class=\"circle user-";
output += runtime.suppressValue(runtime.memberLookup((t_13),"colour"), env.opts.autoescape);
output += "\"><div class=\"inner\">";
output += runtime.suppressValue(env.getFilter("first").call(context, env.getFilter("list").call(context, env.getFilter("trim").call(context, runtime.memberLookup((t_13),"firstname")))), env.opts.autoescape);
output += runtime.suppressValue(env.getFilter("first").call(context, env.getFilter("list").call(context, env.getFilter("trim").call(context, runtime.memberLookup((t_13),"surname")))), env.opts.autoescape);
output += "</div></div>\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t\t<span class=\"name\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_13),"firstname")), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_13),"surname")), env.opts.autoescape);
output += "\n\n\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_13),"parent")) {
output += "\n\t\t\t\t\t\t\t\t\t<b> (YOU)</b>\n\t\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t\t</span>\n\n\t\t\t\t\t\t\t\t<span class=\"depSelect\">\n\t\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_13),"user_id") == runtime.contextOrFrameLookup(context, frame, "selectedDependent")) {
output += "\n\t\t\t\t\t\t\t\t\t\t<input type=\"radio\" name=\"user\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"user_id"), env.opts.autoescape);
output += "\" checked id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"user_id"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t\t\t\t<input type=\"radio\" name=\"user\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"user_id"), env.opts.autoescape);
output += "\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"user_id"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t\t\t<label for=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"user_id"), env.opts.autoescape);
output += "\"><span></span></label>\n\t\t\t\t\t\t\t\t</span>\n\n\t\t\t\t\t\t\t\t";
if(!runtime.memberLookup((t_13),"parent")) {
output += "\n\t\t\t\t\t\t\t\t\t<span class=\"school\">(";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_13),"school")), env.opts.autoescape);
output += ")</span>\n\t\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "count") == 10) {
output += "\n\t\t\t\t\t\t";
var t_16;
t_16 = 1;
frame.set("count", t_16, true);
if(frame.topLevel) {
context.setVariable("count", t_16);
}
if(frame.topLevel) {
context.addExport("count", t_16);
}
output += "\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t</div>\n\n\t\t\t<button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n\t\t    \t<span aria-hidden=\"true\">&times;</span>\n\t\t\t</button>\n\t\t</div>\n\t";
;
}
output += "\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var dependents_oldRoot = dependents_obj.root;
dependents_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	dependents_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"dependents": new nunjucks.Template( {obj: dependents_obj, type: "code"}, env),};
