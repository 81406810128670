var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"container\">\n\t<h4 class=\"heading\">Do you or your child attend one of the following schools/institutions?</h4>\n\n\t<span>\n\t\t<label>School/Institution</label>\n\t\t<select class=\"chosen schoolSelector\" name=\"schools\" data-placeholder=\"Choose a School/Institution...\">\n\t\t<option></option>\n\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "schools");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("school", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t<option ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "institutionId") == runtime.memberLookup((t_4),"school_id")?"selected":""), env.opts.autoescape);
output += "  value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"school_id"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"fullname"), env.opts.autoescape);
output += "</option>\n\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t</select>\n\n\t\t<div class=\"error\">\n\t\t\t<i class=\"fa fa-info-circle fa-fw\"></i> Please select a school/institution before proceeding.\n\t\t</div>\n\t</span>\n\n\t\n\n\t<div class=\"buttons\">\n\t\t<button type=\"button\" class=\"button no\">No, Continue With Personal Account</button>\n\n\t\t<button type=\"button\" class=\"button yes success\">Link School/Institution Account</button>\n\t</div>\n\t\n</div>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
