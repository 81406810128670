var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"overlay\"></div>\n\n<div id=\"newDependentArea\"><span class=\"spinner-icon spinner-small\"/> Loading dependants...</div>\n\n<div id=\"collectionArea\"></div>\n\n<div id=\"bookListArea\"></div>\n\n<div id=\"searchFilter\"></div>\n\n<div class=\"counter\"></div>\n\n<div class=\"topArea\">\n\n\t<button type=\"button\" class=\"button small hide-for-large filterBtn\" data-toggle=\"offCanvasFilter\"><i class=\"fa fa-filter\" aria-hidden=\"true\"></i></button>\n\n\t<div class=\"pagination\"></div>\n\n\t<div class=\"showing\"></div>\n\n</div>\n\n<div class=\"flexHorizontal\">\n\t<div class=\"off-canvas-wrapper\">\n\t\t<div class=\"off-canvas-wrapper-inner\" data-off-canvas-wrapper style=\"z-index: 1;\">\n\t\t    <div class=\"off-canvas position-left off-canvas-absolute reveal-for-large noOverflow\" id=\"offCanvasFilter\" data-off-canvas>\n\n\t\t    \t<a class=\"close-button hide-for-large\" aria-label=\"Close menu\" type=\"button\" data-close style=\"top:9px;right: 15px;color:white;height:0px;\">\n\t\t\t    \t<span aria-hidden=\"true\">&times;</span>\n\t\t\t    </a>\n\n\t\t\t    <div id=\"refineArea\"></div>\n\n\t\t\t\t<div class=\"leftFilter\"></div>\n\t\t\t\t<div class=\"leftFilter2 hide\"></div>\n\n\t\t\t</div>\n\n\t\t\t<div class=\"off-canvas-content\" data-off-canvas-content>\n\t\t\t\t<div id=\"searchResults\"></div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>\n\n<div class=\"pagination bottom\"></div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
