var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"purchase-state-overlay ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "state"), env.opts.autoescape);
output += "\">\n\t";
if(runtime.contextOrFrameLookup(context, frame, "state") == "success") {
output += "\n\t\t<div class=\"icon success\">\n\t\t\t<span class=\"fa fa-check\"/>\n\t\t</div>\n\n\t\t<div class=\"status-text success\">Purchase successful!</div>\n\n\t\t<div class=\"body\">\n\t\t\t<p>Thank you for shopping with us!</p>\n\t\t\t<p>To access your purchased e-books, download the miEbooks app on your computer, tablet or phone.</p>\n\t\t\t<p>For any other products, please refer to your e-mail for further instructions.</p>\n\t\t\t<p class=\"center\"><button class=\"button\">OK</button></p>\n\t\t</div>\n\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "state") == "cancelled") {
output += "\n\t\t<div class=\"icon cancel\">\n\t\t\t<span class=\"fa fa-times\"/>\n\t\t</div>\n\n\t\t<div class=\"status-text cancel\">Purchase cancelled!</div>\n\n\t\t<div class=\"body\">\n\t\t\t<p>You cancelled the purchase. No money has been debited from your account. You can attempt to make a payment again at any time via the \"Pay Online\" button on your purchase history record.</p>\n\t\t\t<p class=\"center\"><button class=\"button\">OK</button></p>\n\t\t</div>\n\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "state") == "error") {
output += "\n\t\t<div class=\"icon error\">\n\t\t\t<span class=\"fa fa-exclamation-triangle\"/>\n\t\t</div>\n\n\t\t<div class=\"status-text cancel\">Purchase failed!</div>\n\n\t\t<div class=\"body\">\n\t\t\t<p>Your purchase has failed, please contact support at <a href=\"mailto: helpdesk@it.si\">helpdesk@it.si</a> if you need further assistance.</p>\n\t\t\t<p class=\"center\"><button class=\"button\">OK</button></p>\n\t\t</div>\n\t";
;
}
;
}
;
}
output += "\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
