module.exports=[
	"Other",
	"Accounting",
	"Agricultural Management Practices",
	"Agricultural Science",
	"Agricultural Technology",
	"Arts and Culture",
	"Business",
	"Business Studies",
	"Civil Technology",
	"Commerce",
	"Computer Applications Technology",
	"Computer Literacy",
	"Consumer Studies",
	"Creative Arts",
	"Dance Studies",
	"Design Studies",
	"Dramatic Arts",
	"Economic and Management Sciences",
	"Economics",
	"Education",
	"Electrical Technology",
	"Engineering",
	"Engineering Graphic and Design",
	"English Language Teaching",
	"Equine Studies",
	"General Academic",
	"General Interest",
	"General Science",
	"Geography",
	"Health Sciences",
	"History",
	"Hospitality Studies",
	"Human and Social Sciences",
	"Humanities",
	"Information Technology",
	"Languages: Afrikaans First Additional Language",
	"Languages: Afrikaans Home Language",
	"Languages: Afrikaans Second Additional Language",
	"Languages: English First Additional Language",
	"Languages: English Home Language",
	"Languages: English Second Additional Language",
	"Languages: French First Additional Language",
	"Languages: French Home Language",
	"Languages: French Second Additional Language",
	"Languages: German First Additional Language",
	"Languages: German Home Language",
	"Languages: German Second Additional Language",
	"Languages: IsiNdebele First Additional Language",
	"Languages: IsiNdebele Home Language",
	"Languages: IsiNdebele Second Additional Language",
	"Languages: IsiXhosa First Additional Language",
	"Languages: IsiXhosa Home Language",
	"Languages: IsiXhosa Second Additional Language",
	"Languages: IsiZulu First Additional Language",
	"Languages: IsiZulu Home Language",
	"Languages: IsiZulu Second Additional Language",
	"Languages: Latin First Additional Language",
	"Languages: Latin Home Language",
	"Languages: Latin Second Additional Language",
	"Languages: Sepedi First Additional Language",
	"Languages: Sepedi Home Language",
	"Languages: Sepedi Second Additional Language",
	"Languages: Sesotho First Additional Language",
	"Languages: Sesotho Home Language",
	"Languages: Sesotho Second Additional Language",
	"Languages: Setswana First Additional Language",
	"Languages: Setswana Home Language",
	"Languages: Setswana Second Additional Language",
	"Languages: Siswati First Additional Language",
	"Languages: Siswati Home Language",
	"Languages: Siswati Second Additional Language",
	"Languages: Tshivenda First Additional Language",
	"Languages: Tshivenda Home Language",
	"Languages: Tshivenda Second Additional Language",
	"Languages: Xitsonga First Additional Language",
	"Languages: Xitsonga Home Language",
	"Languages: Xitsonga Second Additional Language",
	"Languages: Spanish Home Language",
	"Languages: Spanish First Additional Language",
	"Languages: Spanish Second Additional Language",
	"Languages: Italian Home Language",
	"Languages: Italian First Additional Language",
	"Languages: Italian Second Additional Language",
	"Languages: Chinese Home Language",
	"Languages: Chinese First Additional Language",
	"Languages: Chinese Second Additional Language",
	"Languages: Mandarin Home Language",
	"Languages: Mandarin First Additional Language",
	"Languages: Mandarin Second Additional Language",
	"Languages: Greek Home Language",
	"Languages: Greek First Additional Language",
	"Languages: Greek Second Additional Language",
	"Law",
	"Life Orientation",
	"Life Skills",
	"Life Sciences",
	"Literature: Afrikaans First Additional Language",
	"Literature: Afrikaans Home Language",
	"Literature: Afrikaans Second Additional Language",
	"Literature: English First Additional Language",
	"Literature: English Home Language",
	"Literature: English Second Additional Language",
	"Literature: French First Additional Language",
	"Literature: French Home Language",
	"Literature: French Second Additional Language",
	"Literature: German First Additional Language",
	"Literature: German Home Language",
	"Literature: German Second Additional Language",
	"Literature: IsiNdebele First Additional Language",
	"Literature: IsiNdebele Home Language",
	"Literature: IsiNdebele Second Additional Language",
	"Literature: IsiXhosa First Additional Language",
	"Literature: IsiXhosa Home Language",
	"Literature: IsiXhosa Second Additional Language",
	"Literature: IsiZulu First Additional Language",
	"Literature: IsiZulu Home Language",
	"Literature: IsiZulu Second Additional Language",
	"Literature: Latin First Additional Language",
	"Literature: Latin Home Language",
	"Literature: Latin Second Additional Language",
	"Literature: Sepedi First Additional Language",
	"Literature: Sepedi Home Language",
	"Literature: Sepedi Second Additional Language",
	"Literature: Sesotho First Additional Language",
	"Literature: Sesotho Home Language",
	"Literature: Sesotho Second Additional Language",
	"Literature: Setswana First Additional Language",
	"Literature: Setswana Home Language",
	"Literature: Setswana Second Additional Language",
	"Literature: Siswati First Additional Language",
	"Literature: Siswati Home Language",
	"Literature: Siswati Second Additional Language",
	"Literature: Tshivenda First Additional Language",
	"Literature: Tshivenda Home Language",
	"Literature: Tshivenda Second Additional Language",
	"Literature: Xitsonga First Additional Language",
	"Literature: Xitsonga Home Language",
	"Literature: Xitsonga Second Additional Language",
	"Literature: Spanish First Additional Language",
	"Literature: Spanish Second Additional Language",
	"Literature: Spanish Home Language",
	"Literature: Italian First Additional Language",
	"Literature: Italian Second Additional Language",
	"Literature: Italian Home Language",
	"Literature: Chinese First Additional Language",
	"Literature: Chinese Second Additional Language",
	"Literature: Chinese Home Language",
	"Literature: Mandarin First Additional Language",
	"Literature: Mandarin Second Additional Language",
	"Literature: Mandarin Home Language",
	"Literature: Greek First Additional Language",
	"Literature: Greek Second Additional Language",
	"Literature: Greek Home Language",
	"Maritime Economics",
	"Mathematical Literacy",
	"Mathematics",
	"Mechanical Technology",
	"Music",
	"Natural and Agricultural Sciences",
	"Natural Sciences",
	"Natural Sciences and Technology",
	"Nautical Science",
	"Physical Sciences",
	"Public and Development Management",
	"Religion Studies",
	"Research, Reference and Student Support",
	"Social Sciences",
	"Sport and Exercise Science",
	"Technical Mathematics",
	"Technical Science",
	"Technology",
	"Tourism",
	"Visual Arts"
]