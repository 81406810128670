module.exports=[
    {"name":"Afrikaans", "local":"Afrikaans", "1":"af", "2":"afr", "3":"afr"},
    {"name":"Arabic", "local":"Arabic", "1":"ar", "2":"ara", "3":"ara"},
    {"name":"English", "local":"English", "1":"en", "2":"eng", "3":"eng"},
    {"name":"English-Afrikaans", "local":"English-Afrikaans", "1":"en-af", "2":"eng-afr", "3":"eng-afr"},
    {"name":"Southern Ndebele", "local":"IsiNdebele", "1":"nr", "2":"nbl", "3":"nbl"},
    {"name":"Northern Sotho", "local":"Sepedi", "1":"", "2":"nso", "3":"nso"},
    {"name":"Southern Sotho", "local":"Sesotho", "1":"st", "2":"sot", "3":"sot"},
    {"name":"Swati", "local":"Siswati", "1":"ss", "2":"ssw", "3":"ssw"},
    {"name":"Tswana", "local":"Setswana", "1":"tn", "2":"tsn", "3":"tsn"},
    {"name":"Tsonga", "local":"Xitsonga", "1":"ts", "2":"tso", "3":"tso"},
    {"name":"Venda", "local":"Tshivenda", "1":"ve", "2":"ven", "3":"ven"},
    {"name":"Xhosa", "local":"IsiXhosa", "1":"xh", "2":"xho", "3":"xho"},
    {"name":"Zulu", "local":"IsiZulu", "1":"zu", "2":"zul", "3":"zul"}
]