var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<a href=\"#\" id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "key"), env.opts.autoescape);
output += "\" class=\"lisItem\">\n\t";
if(runtime.contextOrFrameLookup(context, frame, "toggled")) {
output += "\n\t\t<i class=\"fa fa-check-square\"></i>\n\t";
;
}
else {
output += "\n\t\t<i class=\"fa fa-square-o\"></i>\n\t";
;
}
output += "\n\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "value"), env.opts.autoescape);
output += "\n</a>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
