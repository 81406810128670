module.exports=[
	{
		"value": "00",
		"description": "Unrated",
		"notes": "",
		"symbol": "F",
		"shortDescription": "Unrated"
	},
	{
		"value": "01",
		"description": "Any adult audience",
		"notes": "The publisher states that the product is suitable for any adult audience.",
		"symbol": "16",
		"shortDescription": "Adult content"
	},
	{
		"value": "02",
		"description": "Content warning",
		"notes": "The publisher warns the content may offend parts of the adult audience (for any reason).",
		"symbol": "!",
		"shortDescription": "Content Warning"
	},
	{
		"value": "03",
		"description": "Content warning (sex)",
		"notes": "The publisher warns the product includes content of an explicit sexual nature.",
		"symbol": "S",
		"shortDescription": "Sex"
	},
	{
		"value": "04",
		"description": "Content warning (violence)",
		"notes": "The publisher warns the product includes content of a violent nature.",
		"symbol": "V",
		"shortDescription": "Violence"
	},
	{
		"value": "05",
		"description": "Content warning (drug-taking)",
		"notes": "The publisher warns the product includes content involving misuse of drugs.",
		"symbol": "D",
		"shortDescription": "Drug-taking"
	},
	{
		"value": "06",
		"description": "Content warning (language)",
		"notes": "The publisher warns the product includes extreme / offensive / explicit language.",
		"symbol": "L",
		"shortDescription": "Language"
	},
	{
		"value": "07",
		"description": "Content warning (intolerance)",
		"notes": "The publisher warns the product includes content involving intolerance of particular groups (eg religious, ethnic, racial, social).",
		"symbol": "I",
		"shortDescription": "Intolerance"
	}
]