var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"orbit\" role=\"region\" data-orbit data-use-m-u-i=\"false\">\n\t<div class=\"orbit-wrapper\">\n\t\t";
if((runtime.memberLookup(((lineno = 2, colno = 19, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "bannerImages"), "bannerImages", context, []))),"length") > 1 && (lineno = 2, colno = 48, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "customStore"), "customStore", context, [])) == true) || runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "adverts")),"advertImages")),"length") > 1) {
output += "\n\t\t\t<div class=\"orbit-controls\">\n\t\t\t\t<button class=\"orbit-previous\"><span class=\"show-for-sr\">Previous Slide</span>&#9664;&#xFE0E;</button>\n\t\t\t\t<button class=\"orbit-next\"><span class=\"show-for-sr\">Next Slide</span>&#9654;&#xFE0E;</button>\n\t\t    </div>\n\t    ";
;
}
output += "\n\t\t<ul class=\"orbit-container\">\n\n\n\t\t\t<!--Advertisement images-->\n\t\t\t";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "adverts")),"advertImages");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("image", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t<li class=\"orbit-slide\">\n\t\t\t        <figure class=\"orbit-figure\">\n\t\t\t        \t<div class=\"image-hover-wrapper\">\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_4),"link_url")),"length") > 0) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"link_url"), env.opts.autoescape);
output += "\" ";
if(runtime.memberLookup((t_4),"new_tab")) {
output += "target=\"_blank\"";
;
}
output += " class=\"advertismentLink\">";
;
}
output += "\n\t\t\t\t\t\t\t\t<img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "adverts")),"advertisementImageBaseUrl"), env.opts.autoescape);
output += runtime.suppressValue(runtime.memberLookup((t_4),"image"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_4),"link_url")),"length") > 0) {
output += "</a>";
;
}
output += "\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</figure>\n\t\t\t  \t</li>\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\n\t\t<!--";
if((lineno = 25, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "customStore"), "customStore", context, [])) == false) {
output += "\n\t\t\t<li class=\"orbit-slide\">\n\t\t        <figure class=\"orbit-figure\">\n\t\t\t        <div class=\"image-hover-wrapper\">  \n\t\t\t\t\t\t<a href=\"https://goo.gl/HYTVpR\" target=\"_blank\">\n\t\t\t\t\t\t\t<img src=\"../images/ITSI_Banner.png\"/>\t\t\t\t\t\t\t\n\t\t\t\t\t\t</a>\n\t\t\t\t\t</div>\n\t\t        </figure>\n\t\t  \t</li>\n\t\t  \t<li class=\"orbit-slide\">\n\t\t        <figure class=\"orbit-figure\">\n\t\t\t        <div class=\"image-hover-wrapper\">  \n\t\t\t\t\t\t<a href=\"http://www.viaafrika.com/academy\" target=\"_blank\">\n\t\t\t\t\t\t\t<img src=\"../images/VADEA_Website_Banner.png\"/>\n\t\t\t\t\t\t</a>\n\t\t\t\t\t</div>\n\t\t        </figure>\n\t\t  \t</li>\n\t\t";
;
}
else {
if(runtime.memberLookup(((lineno = 44, colno = 20, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "bannerImages"), "bannerImages", context, []))),"length") > 0) {
output += "\n\t\t\t";
frame = frame.push();
var t_7 = (lineno = 45, colno = 30, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "bannerImages"), "bannerImages", context, []));
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("banner", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t<li class=\"orbit-slide\">\n\t\t\t        <figure class=\"orbit-figure\">\n\t\t\t\t          <img class=\"orbit-image\" src=\"";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "\" alt=\"Space\">\n\t\t\t        </figure>\n\t\t\t  \t</li>\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t";
;
}
;
}
output += "-->\n\t\t\t\n\n\t\t</ul>\n\n\n\t</div>\n\n\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "adverts")),"advertImages")),"length") > 1) {
output += "\n\t\t<nav class=\"orbit-bullets\">\n\t\t\t";
var t_9;
t_9 = 0;
frame.set("orbitCount", t_9, true);
if(frame.topLevel) {
context.setVariable("orbitCount", t_9);
}
if(frame.topLevel) {
context.addExport("orbitCount", t_9);
}
output += "\n\t\t\t";
frame = frame.push();
var t_12 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "adverts")),"advertImages");
if(t_12) {var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("image", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
output += "\n\t\t\t\t<button data-slide=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "orbitCount"), env.opts.autoescape);
output += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "orbitCount") == 0) {
output += "class=\"is-active\"";
;
}
output += "></button>\n\t\t\t\t";
var t_14;
t_14 = runtime.contextOrFrameLookup(context, frame, "orbitCount") + 1;
frame.set("orbitCount", t_14, true);
if(frame.topLevel) {
context.setVariable("orbitCount", t_14);
}
if(frame.topLevel) {
context.addExport("orbitCount", t_14);
}
output += "\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t  \t</nav>\n\t";
;
}
output += "\n</div>\n\n\t\t\n\n<div class=\"row\">\n\t<div class=\"large-12 columns category\">\n\t\t<div class=\"heading\">\n\t\t\t<!--<a href=\"#\" class=\"viewAll\">View All</a>-->\n\t\t\t<h1 style=\"margin-left:10px\">Popular Titles</h1>\n\t\t</div>\t\t\n\n\t\t<div class=\"bestSellers\"></div>\n\n\t</div>\n</div>\n\n<div class=\"reveal reveal-model\" id=\"homepageModal\" data-reveal data-reset-on-close=\"true\" style=\"width:45%\">\n\t<h4 class=\"heading\">How to Buy E-books</h4>\n\n\t<div class=\"body\" id=\"homepageModalBody\">\n\n\t\t\n\t\t\n\t</div>\t\n\n\t<button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n    \t<span aria-hidden=\"true\">&times;</span>\n\t</button>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
