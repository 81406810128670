var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["item"], 
[], 
function (l_item, kwargs) {
frame = frame.push();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("item", l_item);
var t_2 = "";t_2 += "\n\t";
if(runtime.memberLookup((runtime.memberLookup((l_item),"links")),"length") > 0) {
t_2 += "\n\t\t<li class=\"";
if(runtime.memberLookup((l_item),"active") == true) {
t_2 += "active";
;
}
t_2 += "\"><a href=\"";
t_2 += runtime.suppressValue((runtime.memberLookup((l_item),"newWindow") == true?"":runtime.contextOrFrameLookup(context, frame, "urlRoot")), env.opts.autoescape);
t_2 += runtime.suppressValue(runtime.memberLookup((l_item),"url"), env.opts.autoescape);
t_2 += "\" data-replace=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_item),"replace"), env.opts.autoescape);
t_2 += "\" ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "link")),"newWindow")) {
t_2 += "data-new=\"true\"";
;
}
t_2 += ">";
t_2 += runtime.suppressValue(runtime.memberLookup((l_item),"name"), env.opts.autoescape);
t_2 += "</a>\n\t\t\t<ul class=\"menu vertical\">\n\t\t\t\t";
frame = frame.push();
var t_5 = runtime.memberLookup((l_item),"links");
if(t_5) {var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("link", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += "\n\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_6),"links")),"length") > 0) {
t_2 += "\n\t\t\t\t\t\t";
t_2 += runtime.suppressValue((lineno = 6, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "subset"), "subset", context, [t_6])), env.opts.autoescape);
t_2 += "\n\t\t\t\t\t";
;
}
else {
t_2 += "\n\t\t\t\t\t\t<li ";
if(runtime.memberLookup((t_6),"active") == true) {
t_2 += "class=\"active\"";
;
}
t_2 += "><a href=\"";
t_2 += runtime.suppressValue((runtime.memberLookup((t_6),"newWindow") == true?"":runtime.contextOrFrameLookup(context, frame, "urlRoot")), env.opts.autoescape);
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"url"), env.opts.autoescape);
t_2 += "\" data-replace=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"replace"), env.opts.autoescape);
t_2 += "\" ";
if(runtime.memberLookup((t_6),"newWindow")) {
t_2 += "data-new=\"true\"";
;
}
t_2 += ">";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"name"), env.opts.autoescape);
t_2 += "</a></li>\n\t\t\t\t\t";
;
}
t_2 += "\n\t\t\t\t";
;
}
}
frame = frame.pop();
t_2 += "\n\t\t\t</ul>\n\t";
;
}
else {
t_2 += "\n\t\t<li ";
if(runtime.memberLookup((l_item),"active") == true) {
t_2 += "class=\"active\"";
;
}
t_2 += "><a class=\"";
if(runtime.memberLookup((l_item),"isButton") == true) {
t_2 += "button small primary navigationbtn ";
;
}
t_2 += " ";
if(runtime.memberLookup((l_item),"name") == "CREATE ACCOUNT") {
t_2 += " registerbtn ";
;
}
if(runtime.memberLookup((l_item),"name") == "| SIGN IN") {
t_2 += " signInbtn ";
;
}
t_2 += "\" href=\"";
t_2 += runtime.suppressValue((runtime.memberLookup((l_item),"newWindow") == true?"":runtime.contextOrFrameLookup(context, frame, "urlRoot")), env.opts.autoescape);
t_2 += runtime.suppressValue(runtime.memberLookup((l_item),"url"), env.opts.autoescape);
t_2 += "\" data-replace=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_item),"replace"), env.opts.autoescape);
t_2 += "\" ";
if(runtime.memberLookup((l_item),"newWindow")) {
t_2 += "data-new=\"true\"";
;
}
t_2 += ">";
t_2 += runtime.suppressValue(runtime.memberLookup((l_item),"name"), env.opts.autoescape);
t_2 += "</a>\n\t";
;
}
t_2 += "\n\t</li>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("subset");
context.setVariable("subset", macro_t_1);
output += "\n\n";
var macro_t_7 = runtime.makeMacro(
["main"], 
[], 
function (l_main, kwargs) {
frame = frame.push();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("main", l_main);
var t_8 = "";t_8 += "\n\t";
frame = frame.push();
var t_11 = l_main;
if(t_11) {var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("item", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
t_8 += "\n\t\t";
t_8 += runtime.suppressValue((lineno = 20, colno = 9, runtime.callWrap(macro_t_1, "subset", context, [t_12])), env.opts.autoescape);
t_8 += "\n\t";
;
}
}
frame = frame.pop();
t_8 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_8);
});
context.addExport("render");
context.setVariable("render", macro_t_7);
output += "\n\n<nav class=\"level1\">\n\t<div class=\"title-bar hide-for-large\">\n\t\t<div class=\"title-bar-left\">\n\t\t\t<button class=\"menu-icon\" type=\"button\" data-toggle=\"mobile-nav\"><i class=\"fa fa-bars\"></i></button>\n\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "\">\n\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "customStore")) {
output += "\n\t\t\t\t\t<div class=\"logo\" style=\"display: inline-block;height: 39px; max-width: 250px;\"></div>\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t<img height=\"40px\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/store.svg\" />\n\t\t\t\t";
;
}
output += "\n\t\t\t</a>\n\t\t\t";
if(!runtime.contextOrFrameLookup(context, frame, "customStore")) {
output += "\n\t\t\t\t<!--<p class=\"mobileNavFindsYou\">Your content finds you.&#8482</p>-->\n\t\t\t";
;
}
output += "\n\t\t</div>\n\t\t<div class=\"title-bar-right\">\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "authenticated")) {
output += "\n\t\t\t\t<div class=\"wishList show-for-large\">\n\t\t\t        <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/wishList\">\n\t\t\t            <i aria-hidden=\"true\" class=\"fa fa-heart\"></i>\n\t\t\t            <span class=\"wishListCount ng-binding\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "wishListItemCount"), env.opts.autoescape);
output += "</span>\n\t\t\t        </a>\n\t\t\t    </div>\n\t\t    ";
;
}
output += "\n\t\t\t<div class=\"cart show-for-large\">\n\t\t        <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/cart\">\n\t\t            <img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/Shopping_Cart.png\" alt=\"\" class=\"cartImg\">\n\t\t            <span class=\"cartCount ng-binding\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cartItemCount"), env.opts.autoescape);
output += "</span>\n\t\t        </a>\n\t\t    </div>\n\t\t</div>\n\t</div>\n\n\t<div class=\"spacer hide-for-large\"></div>\n\n\t<div id=\"nav-bar\" class=\"show-for-large\">\n\t\t<div class=\"top-bar main-bar\">\n\t\t\t<div class=\"top-bar-left\">\n\t\t\t\t<ul class=\"menu horizontal small-vertical\">\n\t\t\t\t\t";
output += runtime.suppressValue((lineno = 63, colno = 12, runtime.callWrap(macro_t_7, "render", context, [runtime.contextOrFrameLookup(context, frame, "leftContent")])), env.opts.autoescape);
output += "\n\t\t\t\t</ul>\n\t\t\t\t";
if(!runtime.contextOrFrameLookup(context, frame, "customStore")) {
output += "\n\t\t\t\t\t<!--<p class=\"navFindsYou\">Your content finds you.&#8482</p>-->\n\t\t\t\t";
;
}
output += "\n\t\t\t</div>\n\t\t\t<div class=\"top-bar-right\">\n\t\t\t\t<ul class=\"dropdown menu align-right\" data-dropdown-menu>\n\t\t\t\t\t<!--<li>\n\t\t\t\t\t\t<ul class=\"dropdown menu regions\" data-dropdown-menu>\n\t\t\t\t\t\t\t<li class=\"is-dropdown-submenu-parent opens-left\">-->\n\t\t\t\t\t\t\t<li class=\"is-dropdown-submenu-parent opens-left regions flag-icon-";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "currentRegion")),"region")), env.opts.autoescape);
output += "\" role=\"menuitem\" aria-haspopup=\"true\" aria-expanded=\"false\" aria-label=\"Region\" data-is-click=\"false\">\n\t\t\t\t\t\t\t\t<a class=\"flag-icon flag-icon-";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "currentRegion")),"region")), env.opts.autoescape);
output += " flag-icon-squared\" href=\"#\"></a>\n\t\t\t\t\t\t\t\t<!--<ul class=\"menu vertical small-horizontal\" title=\"Changing your region is not allowed\">\n\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_15 = runtime.contextOrFrameLookup(context, frame, "regions");
if(t_15) {var t_14 = t_15.length;
for(var t_13=0; t_13 < t_15.length; t_13++) {
var t_16 = t_15[t_13];
frame.set("region", t_16);
frame.set("loop.index", t_13 + 1);
frame.set("loop.index0", t_13);
frame.set("loop.revindex", t_14 - t_13);
frame.set("loop.revindex0", t_14 - t_13 - 1);
frame.set("loop.first", t_13 === 0);
frame.set("loop.last", t_13 === t_14 - 1);
frame.set("loop.length", t_14);
output += "\n\t\t\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_16),"region") != runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "currentRegion")),"region")) {
output += "\n\t\t\t\t\t\t\t\t\t\t\t<li class=\"disabled\">\n\t\t\t\t\t\t\t\t\t\t\t\t<a href=\"#\" data-region=\"";
output += runtime.suppressValue(runtime.memberLookup((t_16),"region"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t\t\t\t<span class=\"flag-icon flag-icon-";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.memberLookup((t_16),"region")), env.opts.autoescape);
output += " flag-icon-squared\"></span>\n\t\t\t\t\t\t\t\t\t\t\t\t\t<span class=\"region-name\">";
output += runtime.suppressValue(runtime.memberLookup((t_16),"name"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t</ul>-->\n\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t<!--</li>\n\t\t\t\t\t\t</ul>\n\t\t\t\t\t</li>-->\n\t\t\t\t\t";
output += runtime.suppressValue((lineno = 92, colno = 12, runtime.callWrap(macro_t_7, "render", context, [runtime.contextOrFrameLookup(context, frame, "rightContent")])), env.opts.autoescape);
output += "\n\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "authenticated")) {
output += "\n\t\t\t\t\t\t<li class=\"book-subactions\">\n\t\t\t\t\t        <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/wishList\">\n\t\t\t\t\t            <i aria-hidden=\"true\" class=\"fa fa-heart\"></i>\n\t\t\t\t\t            <span class=\"wishListCount ng-binding\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "wishListItemCount"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t        </a>\n\t\t\t\t\t    </li>\n\t\t\t\t    ";
;
}
output += "\n\t\t\t\t    <li class=\"cart\">\n\t\t\t\t        <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/cart\">\n\t\t\t\t            <img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/Shopping_Cart.png\" alt=\"\" class=\"cartImg\">\n\t\t\t\t            <span class=\"cartCount ng-binding\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cartItemCount"), env.opts.autoescape);
output += "</span>\n\t\t\t\t        </a>\n\t\t\t\t    </li>\n\t\t\t\t</ul>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</nav>\n\n<nav class=\"level2 ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "hideSearch")?"onepx":""), env.opts.autoescape);
output += "\">\n\n\t<div class=\"fixed-search show-for-large\">\n\t\t<div class=\"search-box\">\n\t\t\t<input type=\"text\" class=\"search-input\" name=\"search-input\" placeholder=\"Search by title, eISBN, publisher, subject or keyword\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "value"), env.opts.autoescape);
output += "\" autocomplete=\"off\" id=\"webSearch\" />\n\t\t</div>\n\n\t\t<button id=\"search-btn\" type=\"button\" class=\"button small\"><span class=\"fa fa-search\"></span></button>\n\t</div>\n\n\t<div class=\"mobile-search hide-for-large\">\n\t\t<i class=\"fa fa-search mobile-search-icon\"></i>\n\n\t\t<div class=\"input-group mobile-search-input\">\n  \t\t\t<span class=\"input-group-label\"><i class=\"fa fa-search mobile-search-icon\"></i></span>\n  \t\t\t<input type=\"text\" class=\"search-input input-group-field mobile-search-input-field\" name=\"search-input\" placeholder=\"Search by title, eISBN, publisher, subject or keyword\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "value"), env.opts.autoescape);
output += "\" autocomplete=\"off\" autofocus id=\"mobileSearch\"/>\n  \t\t</div>\n\n\t</div>\n</nav>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
