var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"container\">\n  <div class=\"row\">\n    <div class=\"col-sm-12 steps\">    \n      <ol class=\"list-inline text-center step-indicator\">\n        <li class=\"complete\">\n          <div class=\"step\"><span class=\"fa fa-check\"></span></div>\n          <div class=\"caption hidden-xs hidden-sm\">Checkout</div>\n        </li>\n        <li class=\"complete\">\n          <div class=\"step\"><span class=\"fa fa-check\"></span></div>\n          <div class=\"caption hidden-xs hidden-sm\">Payment</div>\n        </li>\n        <li class=\"complete\">\n          <div class=\"step\"><span class=\"fa fa-check\"></span></div>\n          <div class=\"caption hidden-xs hidden-sm\">Confirmation</div>\n        </li>       \n      </ol>\n    </div>\n  </div>\n</div>\n\n<div class=\"mainArea\"></div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
