var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"mainArea\">\n\t<div class=\"leftArea\">\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-10 large-offset-1 columns\">\n\t\t\t\t<h4 class=\"heading\"><i class=\"fa fa-user fa-fw\"></i> My Profile</h4>\n\t\t\t\t<section aria-hidden=\"false\" class=\"content\" id=\"userDetails\">\n\n\t\t\t\t</section>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t";
if(runtime.contextOrFrameLookup(context, frame, "disableDependents") == runtime.contextOrFrameLookup(context, frame, "null") || runtime.contextOrFrameLookup(context, frame, "disableDependents") == false) {
output += "\n\t\t<div class=\"rightArea\">\n\t\t\t<div class=\"headerArea\">\n\t\t\t\t<h5><i class=\"fa fa-users fa-fw\"></i> MY DEPENDANTS</h5>\n\t\t\t\t<button class=\"button small linkNew\"><i class=\"fa fa-plus fa-fw\"></i> <span class=\"btn-text\">Link new Dependant</span></button>\n\t\t\t</div>\n\t\t\t<div class=\"dependentsArea\"></div>\n\t\t</div>\n\t";
;
}
output += "\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
