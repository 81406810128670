var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"regtangle\">\n\t<div class=\"miEbookstablet\">\n\t\t<img class=\"tablet\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/tablet.png\">\n\t\t<img class=\"app_icon\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/app_icon.png\">\n\t</div>\n\n\t<div class=\"header\">DOWNLOAD E-READER</div>\n\t<a href=\"https://itunes.apple.com/us/app/miebooks/id881060638?mt=8\" target=\"_blank\"><img class=\"apple\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/apple.png\"></a>\n\t<a href=\"https://itunes.apple.com/za/app/miebooks/id1222490514?mt=12\" target=\"_blank\"><img class=\"mac\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/mac.png\"></a>\n\t<a href=\"https://play.google.com/store/apps/details?id=za.co.itschools&hl=en\" target=\"_blank\"><img class=\"android\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/android.png\"></a>\n\t<a href=\"https://apps.microsoft.com/store/detail/9WZDNCRDLB2S\" target=\"_blank\"><img class=\"windows\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/Windows10.svg\"></a>\n\t<div class=\"text\" style=\"color:white !important;\">You can read your e-books on your desktop, tablet or smartphone when using the miEbooks app.</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
