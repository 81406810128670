var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var noWishListItems_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<h4>Nothing added to my wish list yet.</h4>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var noWishListItems_oldRoot = noWishListItems_obj.root;
noWishListItems_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	noWishListItems_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var wishListItem_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\n\t<div class=\"wishListBook ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "invalidError")?"error":""), env.opts.autoescape);
output += "\">\n\t\t<div class=\"viewBook\">\n\t\t\t<div class=\"container\">\n\t\t\t\t<div style=\"background-image: url('";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "coverImgMedium"), env.opts.autoescape);
output += "'), url('../images/no_cover.png')\" class=\"bookImg preview\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "ebook_name")), env.opts.autoescape);
output += "\"></div>\n\t\t\t</div>\n\n\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "ebook_study_year");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("grade", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t";
if((t_4 == "TE")) {
output += "\n\t\t\t\t<div class=\"ribbon\">\n\t\t\t\t\t<div class=\"txt\">\n\t\t\t\t\t\tTeacher's Guide\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t";
;
}
output += "\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "discontinued") || !runtime.contextOrFrameLookup(context, frame, "active"))) {
output += "\n\t\t\t\t<div class=\"discontinued-ribbon\">\n\t\t\t\t\t\t<div class=\"txt\">\n\t\t\t\t\t\t\tDiscontinued\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t</div>\n\n\t\t<div class=\"info\">\n\t\t\t<div class=\"titleContainer\"><h5 class=\"title bookTooltip preview\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name")), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name")), env.opts.autoescape);
output += "</h5></div>\n\t\t\t<h6><b>ISBN:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "identifier")), env.opts.autoescape);
output += "</h6>\n\t\t\t<h6><b>Publisher:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "publisher")), env.opts.autoescape);
output += "</h6>\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "invalidError")) {
output += "\n\t\t\t\t<h6 class=\"invalidMessage\"><b><i class=\"fa fa-exclamation-triangle fa-fw\" aria-hidden=\"true\"></i> Error: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "invalidError")), env.opts.autoescape);
output += "</b></h6>\n\t\t\t";
;
}
output += "\n\t\t</div>\n\n\t\t<div class=\"wishListdetail\">\n\t\t\t<span class=\"price ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discountPrice")?"discounted":""), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discountPrice")?"Was&nbsp;&nbsp;&nbsp;":""), env.opts.autoescape);
output += runtime.suppressValue(((runtime.contextOrFrameLookup(context, frame, "price") > 0)?(runtime.contextOrFrameLookup(context, frame, "symbol") + runtime.contextOrFrameLookup(context, frame, "price")):"Free"), env.opts.autoescape);
output += "</span><br/>\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "discountPrice")) {
output += "\n\t\t\t\t<span class=\"price ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discountPrice")?"discountedPrice":""), env.opts.autoescape);
output += "\">Now ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "symbol") + runtime.contextOrFrameLookup(context, frame, "discountPrice"), env.opts.autoescape);
output += "</span>\n\t\t\t";
;
}
output += "\n\n\n\t\t\t<div class=\"duration\">(";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "formatted_duration"), env.opts.autoescape);
output += ")</div>\n\n\t\t\t<button class=\"button small primary add\" data-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "wish_list_item_id"), env.opts.autoescape);
output += "\"><span data-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "wish_list_item_id"), env.opts.autoescape);
output += "\"><i class=\"fa fa-shopping-cart fa-fw\"></i> <span class=\"btn-text\">Add to Cart</span></span></button>\n\n\t\t\t<button class=\"button small alert remove\" data-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "wish_list_item_id"), env.opts.autoescape);
output += "\"><span data-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "wish_list_item_id"), env.opts.autoescape);
output += "\"><i class=\"fa fa-times\"></i> <span class=\"btn-text\">Remove</span></span></button>\n\t\t</div>\n\t</div>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var wishListItem_oldRoot = wishListItem_obj.root;
wishListItem_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	wishListItem_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"noWishListItems": new nunjucks.Template( {obj: noWishListItems_obj, type: "code"}, env),	"wishListItem": new nunjucks.Template( {obj: wishListItem_obj, type: "code"}, env),};
