var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var noCartItems_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<h4>Nothing added to your cart yet.</h4>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var noCartItems_oldRoot = noCartItems_obj.root;
noCartItems_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	noCartItems_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var cartItem_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cartItems")),"length") > 0) {
output += "\n<div class=\"dependent\">\n\t";
var t_1;
t_1 = (lineno = 3, colno = 33, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dependentName")),"split"), "dependentName[\"split\"]", context, [" "]));
frame.set("names", t_1, true);
if(frame.topLevel) {
context.setVariable("names", t_1);
}
if(frame.topLevel) {
context.addExport("names", t_1);
}
output += "\n\t<div class=\"circle user-";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "colour"), env.opts.autoescape);
output += "\"><div class=\"inner\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("first").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "names")),0))), env.opts.autoescape);
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "names")),"length") > 1 && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "names")),1)),"length") > 0) {
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("first").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "names")),1))), env.opts.autoescape);
;
}
output += "</div></div>\n\t<span>&nbsp;";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "dependentName")), env.opts.autoescape);
output += "</span>\n\t<span class=\"extra\">";
if(runtime.contextOrFrameLookup(context, frame, "schoolName")) {
output += "(";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "schoolName")), env.opts.autoescape);
output += ")";
;
}
output += "</span>\n\t<span id=\"buyForUser\"></span>\n</div>\n\n";
frame = frame.push();
var t_4 = runtime.contextOrFrameLookup(context, frame, "cartItems");
if(t_4) {var t_3 = t_4.length;
for(var t_2=0; t_2 < t_4.length; t_2++) {
var t_5 = t_4[t_2];
frame.set("item", t_5);
frame.set("loop.index", t_2 + 1);
frame.set("loop.index0", t_2);
frame.set("loop.revindex", t_3 - t_2);
frame.set("loop.revindex0", t_3 - t_2 - 1);
frame.set("loop.first", t_2 === 0);
frame.set("loop.last", t_2 === t_3 - 1);
frame.set("loop.length", t_3);
output += "\n\n\t<div class=\"cartBook ";
output += runtime.suppressValue((runtime.memberLookup((t_5),"invalidError") || runtime.memberLookup((t_5),"license_error")?"error":""), env.opts.autoescape);
output += runtime.suppressValue((runtime.memberLookup((t_5),"license_warning") && !runtime.memberLookup((t_5),"license_error")?"warning":""), env.opts.autoescape);
output += "\">\n\t\t<div class=\"viewBook\">\n\t\t\t<div class=\"container\">\n\t\t\t\t<div style=\"background-image: url('";
output += runtime.suppressValue(runtime.memberLookup((t_5),"coverImgMedium"), env.opts.autoescape);
output += "'), url('../images/";
if(runtime.memberLookup((t_5),"product_type") == 1) {
output += "no_cover.png";
;
}
if(runtime.memberLookup((t_5),"product_type") == 2) {
output += runtime.suppressValue(runtime.memberLookup((t_5),"resource_type"), env.opts.autoescape);
output += "_cover.png";
;
}
if(runtime.memberLookup((t_5),"product_type") == 3) {
output += "services_cover.png";
;
}
output += "')\" class=\"bookImg preview\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"ebook_name")), env.opts.autoescape);
output += "\"></div>\n\t\t\t</div>\n\t\t\t<!--<div class=\"bookflag\">\n\t\t\t\t<div class=\"flag\"></div>\n\t\t\t\t<span class=\"text\">";
output += runtime.suppressValue(runtime.memberLookup((t_5),"product_type"), env.opts.autoescape);
output += "</span>\n\t\t\t</div>\t-->\n\t\t\t";
frame = frame.push();
var t_8 = runtime.memberLookup((t_5),"ebook_study_year");
if(t_8) {var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("grade", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\n\t\t\t\t";
if((t_9 == "TE")) {
output += "\n\t\t\t\t<div class=\"ribbon\">\n\t\t\t\t\t<div class=\"txt\">\n\t\t\t\t\t\tTeacher's Guide\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t";
;
}
output += "\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t\t<!--";
if((runtime.memberLookup((t_5),"discontinued") || !runtime.memberLookup((t_5),"active"))) {
output += "\n\t\t\t\t<div class=\"discontinued-ribbon\">\n\t\t\t\t\t\t<div class=\"txt\">\n\t\t\t\t\t\t\tDiscontinued\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t";
;
}
output += "-->\n\n\t\t\t";
if((runtime.memberLookup((t_5),"content_rating"))) {
output += "\n\t\t\t\t";
if((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_5),"content_rating")),0)),"value") != "00") && (runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_5),"content_rating")),0)),1) != "0")) {
output += "\n\t\t\t\t\t<div class=\"rated-ribbon\">\n\t\t\t\t\t\t<div class=\"txt\">\n\t\t\t\t\t\t\tRated Content\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t";
;
}
output += "\n\t\t\t";
;
}
output += "\n\t\t</div>\n\n\t\t<div class=\"info\">\n\t\t\t<div class=\"titleContainer\"><h5 class=\"title bookTooltip preview\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"ebook_name")), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"ebook_name")), env.opts.autoescape);
output += "</h5></div>\n\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_5),"ebook_isbn")),"length") > 0) {
output += "<h6><b>eISBN:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"ebook_isbn")), env.opts.autoescape);
output += "</h6>";
;
}
output += "\n\n\t\t\t<h6><b>Publisher:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"ebook_publisher_name")), env.opts.autoescape);
output += "</h6>\n\n\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_5),"ebook_subject")),"length") > 0) {
output += "<h6><b>Subject:</b> ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"ebook_subject")), env.opts.autoescape);
output += "</h6>";
;
}
output += "\n\n\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_5),"ebook_study_year")),"length") > 0) {
output += "<h6><b>Study year:</b> ";
output += runtime.suppressValue(env.getFilter("gradeMap").call(context, runtime.memberLookup((t_5),"ebook_study_year"),true), env.opts.autoescape);
output += "</h6>";
;
}
output += "\n\n\t\t\t";
if(runtime.memberLookup((t_5),"invalidError")) {
output += "\n\t\t\t\t<h6 class=\"invalidMessage\"><b><i class=\"fa fa-exclamation-triangle fa-fw\" aria-hidden=\"true\"></i> Error: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"invalidError")), env.opts.autoescape);
output += "</b></h6>\n\t\t\t";
;
}
output += "\n\n\t\t\t";
if(runtime.memberLookup((t_5),"license_warning") && !runtime.memberLookup((t_5),"license_error")) {
output += "\n\t\t\t\t<h6 class=\"licenseWarning\"><b><i class=\"fa fa-exclamation-triangle fa-fw\" aria-hidden=\"true\"></i> Warning: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"license_warning")), env.opts.autoescape);
output += "</b></h6>\n\t\t\t";
;
}
output += "\n\n\t\t\t";
if(runtime.memberLookup((t_5),"license_error")) {
output += "\n\t\t\t\t<h6 class=\"invalidMessage\"><b><i class=\"fa fa-exclamation-triangle fa-fw\" aria-hidden=\"true\"></i> Error: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_5),"license_error")), env.opts.autoescape);
output += "</b></h6>\n\t\t\t";
;
}
output += "\n\t\t</div>\n\n\t\t<div class=\"cartdetail\">\n\t\t\t<div class=\"price\">";
output += runtime.suppressValue(((runtime.memberLookup((t_5),"price") > 0)?(runtime.memberLookup((t_5),"symbol") + runtime.memberLookup((t_5),"price")):"Free"), env.opts.autoescape);
output += "</div>\n\t\t\t<div class=\"duration\">(";
output += runtime.suppressValue(runtime.memberLookup((t_5),"formatted_duration"), env.opts.autoescape);
output += ")</div>\n\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "userCount") > 1 && !runtime.contextOrFrameLookup(context, frame, "disableDependents")) {
output += "\n\t\t\t\t\t<button class=\"button small change\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_5),"id"), env.opts.autoescape);
output += "\"><span data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_5),"id"), env.opts.autoescape);
output += "\">Wrong Person?</span></button>\n\t\t\t\t";
;
}
output += "\n\n\t\t\t\t<button class=\"button small alert remove\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_5),"id"), env.opts.autoescape);
output += "\"><span data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_5),"id"), env.opts.autoescape);
output += "\" style=\"pointer-events: none;\"><i class=\"fa fa-times fa-fw\" style=\"pointer-events: none;\"></i> <span class=\"btn-text\">Remove</span></span></button>\n\t\t</div>\n\t</div>\n";
;
}
}
frame = frame.pop();
output += "\n\n\n<div class=\"reveal reveal-model\" id=\"changeModal\" data-reveal>\n\t<h4 class=\"heading\">Wrong Person?</h4>\n\t<h5 calss=\"description\">Move the following product to:</h5>\n<div class=\"bookDetail\"></div>\n<div class=\"changeUser\"></div>\n\t<button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n\t\t<span aria-hidden=\"true\">&times;</span>\n\t</button>\n</div>\n\n";
;
}
output += "\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var cartItem_oldRoot = cartItem_obj.root;
cartItem_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	cartItem_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"noCartItems": new nunjucks.Template( {obj: noCartItems_obj, type: "code"}, env),	"cartItem": new nunjucks.Template( {obj: cartItem_obj, type: "code"}, env),};
