var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<h3 class=\"heading\">Manage dependants</h3>\n<div class=\"info\">\n\tTo purchase products for a school/institution user, you have to link the school/institution account first.\n</div>\n\n<div class=\"container\">\n\t<div class=\"newDependent\">\n\n\t\t<div class=\"add\">\n\t\t\t<h5><i class=\"fa fa-plus fa-fw\"></i> Link new Dependant</h5>\n\n\t\t\t<label>School/Institution</label>\n\t\t\t<select class=\"chosen schoolSelector\" name=\"schools\" data-placeholder=\"Choose a School/Institution...\">\n\t\t\t<option></option>\n\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "schools");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("school", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t<option ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "institutionId") == runtime.memberLookup((t_4),"school_id")?"selected":""), env.opts.autoescape);
output += "  value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"school_id"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"fullname"), env.opts.autoescape);
output += "</option>\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t</select>\n\n\t\t\t<input type=\"text\" style=\"display:none\">\n\t\t\t<input type=\"password\" style=\"display:none\">\n\t\t\t<div class=\"inputs hide\">\n\t\t\t\t<label class=\"username\">miEbooks Username: <i class=\"fa fa-question-circle fa-fw error errorClick\"></i><span class=\"error errorMessage\"> Provided by the school/institution</span>\n\t\t\t\t\t<input type=\"text\" name=\"schoolu\" id=\"uname\" autocomplete=\"off\" autofill=\"off\"/>\n\t\t\t\t</label>\n\n\t\t\t\t<label class=\"passwordLabel\">miEbooks Password:\n\t\t\t\t\t<div class=\"input-group\">\n\t\t\t\t\t\t<input type=\"password\" name=\"schoolp\" id=\"pword\" autocomplete=\"off\" autofill=\"off\" class=\"input-group-field\" />\n\t\t\t\t\t\t<span class=\"input-group-label showPassword\" title=\"Show/Hide Password\"><i class=\"fa fa-eye\" aria-hidden=\"true\"></i></span>\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\n\t\t\t\t<div class=\"buttons\">\n\t\t\t\t\t<button type=\"button\" id=\"link-add-user\" class=\"button\">Link & Add Another</button>\n\t\t\t\t\t<button type=\"button\" id=\"link-shop-user\" class=\"button success\">Link & Start Buying</button>\n\t\t\t\t\t<span class=\"spinner-icon hide\" style=\"margin-top: 5px; margin-left: 5px\"/>\n\t\t\t\t</div>\n\n\t\t\t\t</div>\n\n\t\t\t<div class=\"alert callout small errorCallout hide\">\n  \t\t\t\t<h5 id=\"errorHeading\"><i class=\"fa fa-exclamation-circle fa-fw\" aria-hidden=\"true\"></i> Error</h5>\n  \t\t\t\t<p id=\"errorText\"></p>\n\t\t\t</div>\n\n\t\t\t<div class=\"oauth noBottomBorder hide\">\t\n\n\t\t\t\t<span class=\"or\">OR</span>\t\t\t\n\n\t\t\t\t<a class=\"oauth-btn googleAuth hide\" href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/auth/google\">\n\t\t\t\t\t<div class=\"oauth-icon-wrapper\">\n\t\t\t\t\t\t<img class=\"oauth-icon-svg\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/GoogleLogo.svg\"/>\n\t\t\t\t\t</div>\n\t\t\t\t\t<p class=\"btn-text\">Continue with Google</p>\n\t\t\t\t</a>\n\n\t\t\t\t<a class=\"oauth-btn microsoft-btn microsoftAuth hide\" href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/auth/microsoft\">\n\t\t\t\t\t<div class=\"oauth-icon-wrapper\">\n\t\t\t\t\t\t<img class=\"oauth-icon-svg\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/MicrosoftLogo.png\"/>\n\t\t\t\t\t</div>\n\t\t\t\t\t<p class=\"btn-text\">Continue with Microsoft</p>\n\t\t\t\t</a>\n\n\t\t\t\t<a class=\"oauth-btn facebook-btn facebookAuth hide\" href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/auth/facebook\">\n\t\t\t\t\t<div class=\"oauth-icon-wrapper\">\n\t\t\t\t\t\t<i class=\"fa fa-facebook oauth-icon-svg\"></i>\n\t\t\t\t\t</div>\n\t\t\t\t\t<p class=\"btn-text\">Continue with Facebook</p>\n\t\t\t\t</a>\t\t\n\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\n\t<div class=\"excistingDependent\">\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dependents")),"length") > 0) {
output += "\n\t\t\t<h5 style=\"margin-top:5px;margin-left: 45px;\"><i class=\"fa fa-users fa-fw\"></i> MY DEPENDANTS</h5>\n\t\t\t";
var t_5;
t_5 = 1;
frame.set("count", t_5, true);
if(frame.topLevel) {
context.setVariable("count", t_5);
}
if(frame.topLevel) {
context.addExport("count", t_5);
}
output += "\n\t\t\t";
frame = frame.push();
var t_8 = runtime.contextOrFrameLookup(context, frame, "dependents");
if(t_8) {var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("dependent", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\n\t\t\t\t";
var t_10;
t_10 = runtime.contextOrFrameLookup(context, frame, "count") + 1;
frame.set("count", t_10, true);
if(frame.topLevel) {
context.setVariable("count", t_10);
}
if(frame.topLevel) {
context.addExport("count", t_10);
}
output += "\n\n\t\t\t\t";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dependents")),"length") == 1)) {
output += "\n\t\t\t\t\t<div class=\"depBlock\">\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t<div class=\"depBlock\">\n\t\t\t\t";
;
}
output += "\n\t\t\t\t\t<div class=\"depHead\">\n\t\t\t\t\t\t<div class=\"circle user-";
output += runtime.suppressValue(runtime.memberLookup((t_9),"colour"), env.opts.autoescape);
output += "\"><div class=\"inner\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, (lineno = 88, colno = 96, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((t_9),"firstname")),"substr"), "dependent[\"firstname\"][\"substr\"]", context, [0,1]))), env.opts.autoescape);
output += runtime.suppressValue(env.getFilter("escape").call(context, (lineno = 88, colno = 136, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((t_9),"surname")),"substr"), "dependent[\"surname\"][\"substr\"]", context, [0,1]))), env.opts.autoescape);
output += "</div></div>\n\t\t\t\t\t\t<div class=\"fa fa-refresh refresh\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_9),"user_id"), env.opts.autoescape);
output += "\" title=\"Refresh user\" data-school=\"";
output += runtime.suppressValue(runtime.memberLookup((t_9),"school"), env.opts.autoescape);
output += "\"></div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<p class=\"depName\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"firstname")), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"surname")), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"firstname")), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"surname")), env.opts.autoescape);
output += "</p>\n\n\t\t\t\t\t<p class=\"depInfo\" ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"school")), env.opts.autoescape);
output += "\">School/Institution:</p>\n\t\t\t\t\t<p class=\"depInfo\" title=\"School/Institution:\" ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"school")), env.opts.autoescape);
output += "\"><i>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"school")), env.opts.autoescape);
output += "</i></p>\n\n\t\t\t\t\t<p style=\"margin-bottom: 5px\"></p>\n\n\t\t\t\t\t<p class=\"depInfo\" title=\"miEbooks Username: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"schoolUsername")), env.opts.autoescape);
output += "\">miEbooks Username:</p>\n\t\t\t\t\t<p class=\"depInfo\" title=\"miEbooks Username: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"schoolUsername")), env.opts.autoescape);
output += "\"><i>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"schoolUsername")), env.opts.autoescape);
output += "</i></p>\n\n\t\t\t\t\t<p class=\"depInfo hide\" title=\"Email: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"email")), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_9),"email")), env.opts.autoescape);
output += "</p>\n\n\t\t\t\t\t<div class=\"buttons\">\n\t\t\t\t\t\t<button type=\"button\" class=\"button unlink-user alert small\" data-userId=\"";
output += runtime.suppressValue(runtime.memberLookup((t_9),"user_id"), env.opts.autoescape);
output += "\"><i class=\"fa fa-times fa-fw\"></i> <span class=\"btn-text\">Un-Link</span></button>\n\n\t\t\t\t\t\t<button type=\"button\" class=\"button buy-user small success\" data-userId=\"";
output += runtime.suppressValue(runtime.memberLookup((t_9),"user_id"), env.opts.autoescape);
output += "\">Start Buying</button>\n\t\t\t\t\t</div>\n\n\t\t\t\t</div>\n\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "count") == 10) {
output += "\n\t\t\t\t\t";
var t_11;
t_11 = 1;
frame.set("count", t_11, true);
if(frame.topLevel) {
context.setVariable("count", t_11);
}
if(frame.topLevel) {
context.addExport("count", t_11);
}
output += "\n\t\t\t\t";
;
}
output += "\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t";
;
}
output += "\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
