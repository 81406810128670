var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<h3>Thank you for shopping with us!</h3>\n\n<p>To access your purchased e-books, download the miEbooks app on your computer, tablet or phone.</p>\n\n<p>For any other products, please refer to your e-mail for further instructions.</p>\n\n\n<div class=\"downloadsContainer\">\n\n\t<img src=\"../../../images/app_icon_large.png\" class=\"icon\">\n\n\t<div class=\"downloads\">\n\n\t\t<h3>Download miEbooks</h3>\n\n\t\t<div class=\"apps\">\n\t\t\t<div>\n\t\t\t\t<h5>for iOS</h5>\n\t\t\t\t<a href=\"https://itunes.apple.com/us/app/miebooks/id881060638?mt=8\" target=\"_blank\"><img src=\"../../../images/apple_app.png\" class=\"appImg\"></a>\n\t\t\t</div>\n\n\t\t\t<div>\n\t\t\t\t<h5>for Android</h5>\n\t\t\t\t<a href=\"https://play.google.com/store/apps/details?id=za.co.itschools&hl=en\" target=\"_blank\"><img src=\"../../../images/google_app.png\" class=\"appImg\"></a>\n\t\t\t</div>\n\n\t\t\t<div>\n\t\t\t\t<h5>for Windows</h5>\n\t\t\t\t<a href=\"https://apps.microsoft.com/store/detail/9WZDNCRDLB2S\" target=\"_blank\"><img src=\"../../../images/Windows10.svg\" class=\"appImg\"></a>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<img src=\"../../../images/Laptop.png\" class=\"mainImg\">\n\n\t</div>\n</div>\n\n<div class=\"buttons\">\n\t<button class=\"button small shop\"><i class=\"fa fa-arrow-left fa-fw\" aria-hidden=\"true\"></i> Continue Shopping</button>\n\n\t<button class=\"button small history\">Go to Purchase History</button>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
