module.exports=[
	{
		"id": 1,
		"label": "E-books"		
	},
	/*{
		"id": 2,
		"label": "Resources"		
	},*/
	{
		"id": 3,
		"label": "Learning Tools"		
	},		
]