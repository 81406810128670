var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"container\">\n  <div class=\"row\">\n    <div class=\"col-sm-12 steps\">\n      <ol class=\"list-inline text-center step-indicator\">\n        <li class=\"complete\">\n          <div class=\"step\"><span class=\"fa fa-check\"></span></div>\n          <div class=\"caption hidden-xs hidden-sm\">Checkout</div>\n        </li>\n        <li class=\"active\">\n          <div class=\"step\">2</div>\n          <div class=\"caption hidden-xs hidden-sm\">Payment</div>\n        </li>\n        <li class=\"inactive\">\n          <div class=\"step\">3</div>\n          <div class=\"caption hidden-xs hidden-sm\">Confirmation</div>\n        </li>\n      </ol>\n    </div>\n  </div>\n</div>\n\n<div class=\"paymentPage\">\n\n\t<div class=\"paymentMain\">\n\t\t<h4><b><i class=\"fa fa-money fa-fw\"></i> Payment</b></h4>\n\n\t\t<div class=\"method\">\n\t\t\t<div id=\"payment-options\">\n\t\t\t\t<h5><b>Select payment method</b></h5>\n\t\t\t\t<form>\n\t\t\t\t\t<span class=\"radios\"><input type=\"radio\" name=\"paymentMethod\" value=\"online\" id=\"payfast\" class=\"radio-online\"><label for=\"payfast\">Pay online</label></span>\n\t\t\t\t\t<span class=\"radios\"><input type=\"radio\" name=\"paymentMethod\" value=\"manual\" id=\"manual\" class=\"radio-manual\"><label for=\"manual\">Make a cash deposit using SCode voucher</label><br /></span>\n\t\t\t\t\t<span class=\"radios\"><input type=\"radio\" name=\"paymentMethod\" value=\"invoice\" id=\"invoice\" class=\"radio-invoice\"><label for=\"invoice\">Download invoice and make payment later</label><br /></span>\n\t\t\t\t</form>\n\t\t\t</div>\n\n\t\t\t<div class=\"hide paymentMethod payment-online\">\n\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "payFast") == true) {
output += "\n\t\t\t\t<p>Pay online with Payfast using either your credit/cheque card, debit card, or by EFT.</p>\n\t\t\t\t<div>\n\t\t\t\t\t<img src=\"../../images/secure_logo.png \" />\n\t\t\t\t\t<br />\n\t\t\t\t\t<br />\n\t\t\t\t\t<br />\n\t\t\t\t</div>\n\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t<p>Pay online with PayPal using either your existing PayPal account or credit/cheque card</p>\n\t\t\t\t<div>\n\t\t\t\t\t<img src=\"../../images/paypal.jpg \" />\n\t\t\t\t\t<br />\n\t\t\t\t\t<br />\n\t\t\t\t\t<br />\n\t\t\t\t</div>\n\t\t\t\t";
;
}
output += "\n\t\t\t\t<div>\n\t\t\t\t\t<p>By completing this purchase, you agree to our <a href=\"https://www.it.si/privacy\" target=\"_blank\">terms and conditions.</a></p>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"hide\" id=\"payment-free\">\n\t\t\t\t<h4>Payment</h4>\n\t\t\t\t<div>\n\t\t\t\t\t<button class=\"button pay\" type=\"button\">Process free purchase</button><span class='spinner hide fa fa-refresh fa-spin fa-fw'></span>\n\t\t\t\t\t<p>By completing this purchase, you agree to our <a href=\"https://www.it.si/privacy\" target=\"_blank\">terms and conditions.</a></p>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"hide paymentMethod payment-manual\">\n\t\t\t\t<p>\n\t\t\t\t\tSCode Online enables customers to pay for their online purchases using Cash, Debit or Credit Card across a national network of over 6 000 retail payment outlets.\n\t\t\t\t</p>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"gift\">\n\t\t\t<div id=\"hasGift\">\n\t\t\t\t<h5><b>Buy as gift</b> <span class=\"giftExpand\">+</span></h5>\n\t\t\t\t<div id=\"giftToExpand\">\n\t\t\t\t\t<div class=\"inline\">\n\t\t\t\t\t\t<h5 class=\"black\">Enter email of Store account to gift:</h5>\n\t\t\t\t\t\t<input type=\"text\" name=\"giftEmail\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "giftEmail")?"disabled":""), env.opts.autoescape);
output += " value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "giftEmail"), env.opts.autoescape);
output += "\" placeholder=\"Email\" />\n\t\t\t\t\t\t<button class=\"button small apply-gift ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "giftEmail")?"hide":""), env.opts.autoescape);
output += "\" type=\"button\">Apply</button>\n\t\t\t\t\t\t<button class=\"button small reset-gift ";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "giftEmail")?"hide":""), env.opts.autoescape);
output += "\" type=\"button\">Reset</button>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"callout alert hide\" id=\"giftAlert\">\n\t\t\t\t\t  <p><b>Buy as gift</b> not applied.</p>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"voucher\">\n\t\t\t<div id=\"hasVoucher\">\n\t\t\t\t<h5><b>Redeem voucher</b> <span class=\"voucherExpand\">+</span></h5>\n\t\t\t\t<div id=\"voucherToExpand\">\n\t\t\t\t\t<div class=\"inline\">\n\t\t\t\t\t\t<h5 class=\"black\">Enter voucher code:</h5>\n\t\t\t\t\t\t<input type=\"text\" name=\"voucherCode\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "code"), env.opts.autoescape);
output += "\" placeholder=\"XXXXXXXX\" />\n\t\t\t\t\t\t<button class=\"button small redeem-voucher disabled\" type=\"button\">Apply</button>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"amounts\">\n\t\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t\t<span class=\"small-10 columns\">Subtotal: </span>\n\t\t\t\t\t\t\t<span class=\"small-2 columns\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "currency"), env.opts.autoescape);
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "total"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t\t<span class=\"small-10 columns\">Voucher applied: </span>\n\t\t\t\t\t\t\t<span class=\"small-2 columns\">";
if(runtime.contextOrFrameLookup(context, frame, "voucherDiscount") == runtime.contextOrFrameLookup(context, frame, "null")) {
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "currency"), env.opts.autoescape);
output += "00.00";
;
}
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "voucherDiscount"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"row total\">\n\t\t\t\t\t\t\t<span class=\"small-10 columns\">TOTAL: </span>\n\t\t\t\t\t\t\t<span class=\"small-2 columns\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "formattedAmount"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"credit hide\">\n\n\t\t\t<div id=\"hasCredit\">\n\t\t\t\t<h5><b>Apply credit</b></h5>\n\t\t\t\t<h7 class=\"black\"><b>Available credit: <span class=\"blue-text totalCredit\"></span></b></h7>\n\t\t\t\t<div class=\"inline\">\n\t\t\t\t\t<h5 class=\"black\">Apply amount:</h5>\n\t\t\t\t\t<input type=\"number\" min=\"0\" onkeypress=\"return event.charCode >= 46\" name=\"creditAmount\" placeholder=\"Enter amount\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "creditUsed"), env.opts.autoescape);
output += "\" />\n\t\t\t\t\t<button class=\"button small redeem-credit disabled\" type=\"button\">Apply</button>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"amounts\">\n\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t<span class=\"small-10 columns\">Subtotal: </span>\n\t\t\t\t\t\t<span class=\"small-2 columns\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "currency"), env.opts.autoescape);
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "amountAfterVoucher"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t<span class=\"small-10 columns\">Credit applied: </span>\n\t\t\t\t\t\t<span class=\"small-2 columns\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "currency"), env.opts.autoescape);
if(runtime.contextOrFrameLookup(context, frame, "creditUsed") == 0) {
output += "0";
;
}
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "creditUsed"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"row total blue-text\">\n\t\t\t\t\t\t<span class=\"small-10 columns\">TOTAL: </span>\n\t\t\t\t\t\t<span class=\"small-2 columns\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "currency"), env.opts.autoescape);
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "totalAfterCredit"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t</div>\n\n\t\t<div class=\"totalOutstanding\">\n\t\t\t<button class=\"button small success pay hide payment-invoice paymentMethod\" type=\"button\"><span class=\"btn-text\">Download Invoice</span></button>\n\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "payFast") == true) {
output += "\n\t\t\t\t<button class=\"button small success pay hide payment-online payment-manual paymentMethod\" type=\"button\"><span class=\"btn-text\">Proceed to PayFast</span></button>\n\t\t\t";
;
}
else {
output += "\n\t\t\t\t<button class=\"button small success pay hide payment-online paymentMethod\" type=\"button\"><span class=\"btn-text\">Proceed to PayPal</span></button>\n\t\t\t";
;
}
output += "\n\t\t\t<button class=\"button small success pay hide payment-free paymentMethod\" type=\"button\"><span class=\"btn-text\">Process free purchase</span></button>\n\t\t</div>\n\n\t</div>\n\n\n\t<div class=\"voucherDetail\">\n\t\t<h4><b><i class=\"fa fa-info-circle fa-fw\"></i> Voucher Detail</b></h4>\n\n\t\t<table>\n\t\t\t<thead>\n\t\t\t\t<tr><th>Product</th><th>Price</th><th>Voucher Discount</th><th>Discount Price</th></tr>\n\t\t\t</thead>\n\t\t\t<tbody>\n\t\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "voucherResult");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("result", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t";
if(runtime.memberLookup((t_4),"hasVoucherDiscount") == true) {
output += "\n\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t<td>";
output += runtime.suppressValue(runtime.memberLookup((t_4),"title"), env.opts.autoescape);
output += "</td>\n\t\t\t\t\t\t\t<td>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "currency"), env.opts.autoescape);
output += runtime.suppressValue(runtime.memberLookup((t_4),"price"), env.opts.autoescape);
output += "</td>\n\t\t\t\t\t\t\t<td>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "currency"), env.opts.autoescape);
if(runtime.memberLookup((t_4),"discount") == runtime.contextOrFrameLookup(context, frame, "null")) {
output += "00.00";
;
}
output += runtime.suppressValue(runtime.memberLookup((t_4),"discount"), env.opts.autoescape);
output += "</td>\n\t\t\t\t\t\t\t<td>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "currency"), env.opts.autoescape);
if(runtime.memberLookup((t_4),"discountPrice") == runtime.contextOrFrameLookup(context, frame, "null")) {
output += runtime.suppressValue(runtime.memberLookup((t_4),"price"), env.opts.autoescape);
;
}
output += runtime.suppressValue(runtime.memberLookup((t_4),"discountPrice"), env.opts.autoescape);
output += "</td>\n\t\t\t\t\t\t</tr>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t</tbody>\n\t\t</table>\n\t</div>\n\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
