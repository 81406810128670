var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"modal-title\"><span class='fa fa-exclamation-circle'/> ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "header"), env.opts.autoescape);
output += "</div>\n\n<div class=\"modal-body\">\n\t<div class=\"row\">\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "instructions")) {
output += "\n\t\t<div class=\"large-12 columns\"><strong>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "instructions"), env.opts.autoescape);
output += "</strong><br/><br/></div>\n\t\t";
;
}
output += "\n\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "action") == "transferToken" || runtime.contextOrFrameLookup(context, frame, "action") == "transferPurchase") {
output += "\n\t\t<div class=\"large-12 columns transfer-choose\">\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "isFcUser") != true) && (runtime.contextOrFrameLookup(context, frame, "isEmpathy") != true)) {
output += "\n\t\t\t\t<div class=\"inUse depA\">\n\t\t\t";
;
}
else {
output += "\n\t\t\t\t<div class=\"depA\">\n\t\t\t";
;
}
output += "\n\t\t\t\t<label>Select Dependant:<br/>\n\t\t\t\t\t<select class=\"chosen\" name=\"schools\">\n\t\t\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "dependents");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("dependent", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"user_id"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"firstname") + " " + runtime.memberLookup((t_4),"surname") + " (" + runtime.memberLookup((t_4),"school") + ")", env.opts.autoescape);
output += "</option>\n\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t\t<div>- OR -</div>\n\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "isFcUser") == true) || (runtime.contextOrFrameLookup(context, frame, "isEmpathy") == true)) {
output += "\n\t\t\t\t<div class=\"inUse\">\n\t\t\t";
;
}
else {
output += "\n\t\t\t\t<div>\n\t\t\t";
;
}
output += "\n\t\t\t\t<label>Transfer to: <b>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "transferToUser"), env.opts.autoescape);
output += "</b><br/>\n\t\t\t\t\t<div class=\"input-group\">\n\t\t\t\t\t\t<input type=\"email\" name=\"email\" id=\"userSearchEmail\" placeholder=\"Search by email\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "transferToUserEmail"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t<div class=\"input-group-button\">\n\t\t\t\t\t\t\t<button type=\"submit\" class=\"button searchForUser\" id=\"searchForUser\"><span class=\"fa fa-search\"></span></button>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"user-type\">\n\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "isFcUser") == true) {
output += "\n\t\t\t\t\t\t\t<i class=\"fa fa-info-circle\" aria-hidden=\"true\"> </i><span>FC User</span>\n\t\t\t\t\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "isEmpathy") == true) {
output += "\n\t\t\t\t\t\t\t<i class=\"fa fa-info-circle\" aria-hidden=\"true\"> </i><span>Store User</span>\n\t\t\t\t\t\t";
;
}
;
}
output += "\n\t\t\t\t\t</div>\n\t\t\t\t\t\n\t\t\t\t\t\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\t\t";
;
}
output += "\n\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "action") == "changeStatus") {
output += "\n\t\t\t<div class=\"large-12 columns\">Current status:&nbsp;<strong>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "statuses")),(lineno = 52, colno = 87, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "status")),"toString"), "status[\"toString\"]", context, []))), env.opts.autoescape);
output += "</strong><br/><br/></div>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Select new status:<br/>\n\t\t\t\t\t<select class=\"chosen\" name=\"status\">\n\t\t\t\t\t";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "transitions");
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("entry", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "statuses")),(lineno = 57, colno = 54, runtime.callWrap(runtime.memberLookup((t_8),"toString"), "entry[\"toString\"]", context, []))), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "action") == "addDiscount") {
output += "\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Voucher Code:\n\t\t\t\t\t<input type=\"text\" name=\"code\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "isImpersonating") == true) {
output += "\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "categories")) {
output += "\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<div style=\"margin-bottom: 5px\">Category:</div>\n\t\t\t\t\t";
frame = frame.push();
var t_11 = runtime.contextOrFrameLookup(context, frame, "categories");
if(t_11) {var t_9;
if(runtime.isArray(t_11)) {
var t_10 = t_11.length;
for(t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9][0]
frame.set("key", t_11[t_9][0]);
var t_13 = t_11[t_9][1]
frame.set("val", t_11[t_9][1]);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n\t\t\t\t\t\t<!--<div class=\"row\">-->\n\t\t\t\t\t\t<label><input type=\"radio\" name=\"category\" value=\"";
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += "\"> ";
output += runtime.suppressValue(t_13, env.opts.autoescape);
output += "</label>\n\t\t\t\t\t\t<!--</div>-->\n\t\t\t\t\t";
;
}
} else {
t_9 = -1;
var t_10 = runtime.keys(t_11).length;
for(var t_14 in t_11) {
t_9++;
var t_15 = t_11[t_14];
frame.set("key", t_14);
frame.set("val", t_15);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n\t\t\t\t\t\t<!--<div class=\"row\">-->\n\t\t\t\t\t\t<label><input type=\"radio\" name=\"category\" value=\"";
output += runtime.suppressValue(t_14, env.opts.autoescape);
output += "\"> ";
output += runtime.suppressValue(t_15, env.opts.autoescape);
output += "</label>\n\t\t\t\t\t\t<!--</div>-->\n\t\t\t\t\t";
;
}
}
}
frame = frame.pop();
output += "\n\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "actionDescription"), env.opts.autoescape);
output += ":\n\t\t\t\t\t<input type=\"text\" name=\"reason\" placeholder=\"Insert your name and the date here\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\t</div>\n\t<div class=\"modal-error hide\"></div>\n</div>\n\n<div class=\"modal-footer\">\n\t<a class=\"button small save success\">Go</a>&nbsp;&nbsp;&nbsp;\n\t<a class=\"button small secondary cancel\">Cancel</a>&nbsp;&nbsp;&nbsp;\n\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
