module.exports={
	"1": "Cancelled",
	"2": "Status Change",
	"3": "Cancel voucher discount",
	"4": "Token generated",

	"confirm": {
		"100": "Cash deposit",
		"101": "EFT",
		"102": "POS",
		"103": "Other"
	},
	"force": {
		"200": "Teacher e-book",
		"201": "Payment received",
		"203": "Version error",
		"204": "Wrong language bought",
		"202": "Other",
		"205": "Post Refund Duration"
	},
	"transfer": {
		"300": "Wrong user",
		"301": "Other"
	},
	"revoke": {
		"400": "Refund",
		"402": "Version error",
		"403": "Wrong language bought",
		"401": "Other",
		"404": "Post Refund Duration"
	}
}