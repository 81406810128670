var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"summaryItemHeading\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "label"), env.opts.autoescape);
output += "</div>\n\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "selectedItems")),"length") > 0) {
output += "\n\t<ul>\n\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "selected");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("item", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t<li style=\"padding-left:";
output += runtime.suppressValue((env.getFilter("wordcount").call(context, runtime.memberLookup((runtime.memberLookup((t_4),"attributes")),"path")) - 1) * 15, env.opts.autoescape);
output += "px\">\n\t\t\t\t<a href=\"#\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"attributes")),"key"), env.opts.autoescape);
output += "\" class=\"lisItem ";
if((env.getFilter("wordcount").call(context, runtime.memberLookup((runtime.memberLookup((t_4),"attributes")),"path"))) > 1) {
output += "subLevel";
;
}
output += "\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"attributes")),"value"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<div class=\"linkText\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"attributes")),"key"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"attributes")),"value"), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t<i class=\"fa fa-times fa-fw\" aria-hidden=\"true\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"attributes")),"key"), env.opts.autoescape);
output += "\"></i>\n\t\t\t\t</a>\n\n\t\t\t</li>\n\t\t";
;
}
}
frame = frame.pop();
output += "\n\t</ul>\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
